import React from 'react';
import styles from './index.module.scss';
import { Checkbox } from 'antd';
import { uniq } from 'lodash';

interface Options {
  label: string;
  value: number;
  type?: any;
}

interface Props {
  value?: any;
  disabled?: boolean;
  options?: Options[];
  onChange?: (val: any) => void;
}

const FcsCheckGroup: React.FC<Props> = ({ value, options, disabled, onChange }) => {
  const onGroupCheck = (val: any[]) => {
    onChange?.(uniq([...val]));
  };

  return (
    <Checkbox.Group
      options={options}
      disabled={disabled}
      className={styles.checkBox}
      value={value}
      onChange={onGroupCheck}
    />
  );
};

export default FcsCheckGroup;
