import { Node } from '@antv/x6';
import { FC, useMemo } from 'react';
import styles from './index.module.scss';

export interface AcBusBarProps {
  node: Node;
}

const AcBusBar: FC<AcBusBarProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  return (
    <div className={styles.acBusBarWrapper} style={{ width: data?.children === 2 ? 1100 : 760 }}>
      <div className={styles.generatrixEachNode} style={{ width: data?.children === 2 ? 1000 : 679 }}></div>
      {data?.children === 3 && <div className={styles.tline}></div>}
      {data?.children === 2 ? (
        <>
          <div className={styles.bline1}></div>
          <div className={styles.bline2}></div>
        </>
      ) : (
        <>
          <div className={styles.otherBline1}></div>
          <div className={styles.otherBline2}></div>
          <div className={styles.otherBline3}></div>
        </>
      )}

      <div className={styles.generatrixValueSty} style={{ left: data?.children === 2 ? 1010 : 690 }}>
        <div>交流母线</div>
        <div style={{ color: '#faad14' }}>{data?.children === 2 ? '10kV' : '400V'}</div>
      </div>
    </div>
  );
};

export default AcBusBar;
