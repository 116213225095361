import React, { useContext, useMemo } from 'react';
import styles from './index.module.scss';
import { AlarmConfType, AlarmConfTypeIsShow } from './type';

import { ShareDataContext } from '../..';

import DispatchPageList from './DispatchPageList';
import AlarmPageList from './AlarmPageList';

const CompsMap = {
  [AlarmConfType.TYPE_4]: DispatchPageList,
  [AlarmConfType.TYPE_5]: AlarmPageList,
};

const SideDraw = () => {
  const { cardListInfo } = useContext(ShareDataContext);
  // 按照配置项控制显示/隐藏, 权重顺序
  const nodeList = useMemo(() => {
    let totalShows = cardListInfo?.filter(item => item.display === AlarmConfTypeIsShow.Show).length;
    return cardListInfo
      ?.map(item => {
        let NodeTag = CompsMap[item.type! as keyof typeof CompsMap] ?? <></>;
        let isShow = item.display === AlarmConfTypeIsShow.Show;
        // 只有一个元素时, 沾满整列, 否则最大占1/length
        let initialH = totalShows && totalShows > 0 ? `${100 / totalShows}%` : 'auto';
        return {
          node: <NodeTag initialH={initialH} isShow={isShow} />,
          weight: item.weight,
          show: item.display === AlarmConfTypeIsShow.Show,
        };
      })
      .filter(item => item.show)
      .sort((a, b) => (b.weight ?? 0) - (a.weight ?? 0));
  }, [cardListInfo]);

  return <div className={styles.alarm_wrapper}>{nodeList?.map(item => item.node)}</div>;
};

export default SideDraw;
