import dayjs from 'dayjs';
import { NodeMockType } from '../type';
import { formatDate } from './util';

export enum BillingType {
  ProductionUnit1 = 'ProductionUnit1',
  ProductionUnit2 = 'ProductionUnit2',
  Compressor = 'Compressor',
  OfficeLoad = 'officeLoad',
  Pv = 'Pv',
}

export const start0 = dayjs(dayjs().format('YYYY-MM-DD 00:00:00'));
export const end2 = dayjs(dayjs().format('YYYY-MM-DD 01:59:59'));
export const start2 = dayjs(dayjs().format('YYYY-MM-DD 02:00:00'));
export const start6 = dayjs(dayjs().format('YYYY-MM-DD 06:00:00'));
export const end8 = dayjs(dayjs().format('YYYY-MM-DD 07:59:59'));
export const start8 = dayjs(dayjs().format('YYYY-MM-DD 08:00:00'));
export const end10 = dayjs(dayjs().format('YYYY-MM-DD 09:59:59'));
export const start10 = dayjs(dayjs().format('YYYY-MM-DD 10:00:00'));
export const end12 = dayjs(dayjs().format('YYYY-MM-DD 11:59:59'));
export const start12 = dayjs(dayjs().format('YYYY-MM-DD 12:00:00'));
export const end13 = dayjs(dayjs().format('YYYY-MM-DD 12:59:59'));
export const start13 = dayjs(dayjs().format('YYYY-MM-DD 13:00:00'));
export const end14 = dayjs(dayjs().format('YYYY-MM-DD 13:59:59'));
export const start14 = dayjs(dayjs().format('YYYY-MM-DD 14:00:00'));
export const end15 = dayjs(dayjs().format('YYYY-MM-DD 14:59:59'));
export const start15 = dayjs(dayjs().format('YYYY-MM-DD 15:00:00'));
export const end16 = dayjs(dayjs().format('YYYY-MM-DD 15:59:59'));
export const start16 = dayjs(dayjs().format('YYYY-MM-DD 16:00:00'));
export const end17 = dayjs(dayjs().format('YYYY-MM-DD 16:59:59'));
export const start17 = dayjs(dayjs().format('YYYY-MM-DD 17:00:00'));
export const end18 = dayjs(dayjs().format('YYYY-MM-DD 17:59:59'));
export const start18 = dayjs(dayjs().format('YYYY-MM-DD 18:00:00'));
export const end19 = dayjs(dayjs().format('YYYY-MM-DD 18:59:59'));
export const start19 = dayjs(dayjs().format('YYYY-MM-DD 19:00:00'));
export const end20 = dayjs(dayjs().format('YYYY-MM-DD 19:59:59'));
export const end0 = dayjs(dayjs().format('YYYY-MM-DD 23:59:59'));

export const mockAlarmData = [
  {
    id: 1,
    time: formatDate(),
    level: 'high',
    desc: '汇流箱过压',
  },
  {
    id: 2,
    time: formatDate(),
    level: 'highest',
    desc: 'PCS装置异常',
  },
  {
    id: 3,
    time: formatDate(),
    level: 'middle',
    desc: '光伏逆变器通信故障报警',
  },
  {
    id: 4,
    time: formatDate(),
    level: 'low',
    desc: '电压欠压（15%）',
  },
];

export const formatDemoData = (gridSize: number) => {
  // console.log('gridSize', gridSize);
  return [
    {
      x: gridSize / 2 - 80,
      y: 10,
      shape: NodeMockType.GRID_SIDE,
      data: {},
    },
    {
      x: gridSize / 2 - 80,
      y: 50,
      shape: NodeMockType.GRID_ACCESS_POINT,
      data: {},
    },
    {
      x: gridSize / 2,
      y: 150,
      shape: NodeMockType.MAIN_METER,
      data: {},
    },
    {
      x: gridSize / 2,
      y: 198,
      shape: NodeMockType.ANTI_BACKFLOW_DEVICE,
      data: {},
    },
    {
      x: gridSize / 2 - 80,
      y: 238,
      shape: NodeMockType.TRANSFORMER,
      data: {
        name: '进线变压器',
        highVoltageLevel: '35kV',
        lowerVoltageLevel: '10kV',
      },
    },
    {
      x: gridSize / 2 - 480,
      y: 352,
      shape: NodeMockType.AC_BUSBAR,
      data: { children: 2 },
    },
    {
      x: gridSize / 2 - 480,
      y: 374,
      shape: NodeMockType.TRANSFORMER,
      data: {
        name: 'A区1#变',
        highVoltageLevel: '10kV',
        lowerVoltageLevel: '400V',
      },
    },
    {
      x: gridSize / 2 - 480,
      y: 488,
      shape: NodeMockType.ACDC_CONVERTER,
      data: {
        voltage: 750,
        current: 70,
        power: 52.5,
      },
    },
    {
      x: gridSize / 2 - 850,
      y: 594,
      shape: NodeMockType.DC_BUSBAR,
      data: {},
    },
    {
      x: gridSize / 2 - 850,
      y: 624,
      shape: NodeMockType.DC_CONVERTER,
      data: {},
    },
    {
      x: gridSize / 2 - 770,
      y: 638,
      shape: NodeMockType.BILLING_POINT,
      data: {
        type: BillingType.OfficeLoad,
        voltage: 750,
        // current: 10,
      },
    },
    {
      x: gridSize / 2 - 850,
      y: 718,
      shape: NodeMockType.LOAD,
      data: {
        type: 'officeLoad',
        name: '办公楼负载',
      },
    },
    {
      x: gridSize / 2 - 480,
      y: 624,
      shape: NodeMockType.BSA,
      data: {
        voltage: 500,
        current: 100,
        power: 50,
        socPercent: 80,
      },
    },
    {
      x: gridSize / 2 - 280,
      y: 624,
      shape: NodeMockType.DC_CONVERTER,
      data: {},
    },
    {
      x: gridSize / 2 - 200,
      y: 638,
      shape: NodeMockType.BILLING_POINT,
      data: {
        type: BillingType.Pv,
        voltage: 750,
        power: 5,
        // current: 10,
      },
    },
    {
      x: gridSize / 2 - 280,
      y: 718,
      shape: NodeMockType.PVA,
      data: {},
    },
    {
      x: gridSize / 2 + 348,
      y: 374,
      shape: NodeMockType.TRANSFORMER,
      data: {
        name: 'B区1#变',
        highVoltageLevel: '10kV',
        lowerVoltageLevel: '400V',
      },
    },
    {
      x: gridSize / 2 + 150,
      y: 594,
      shape: NodeMockType.AC_BUSBAR,
      data: {
        children: 3,
      },
    },
    {
      x: gridSize / 2 + 230,
      y: 616,
      shape: NodeMockType.NODATA_BILLING_POINT,
      data: {},
    },
    {
      x: gridSize / 2 + 150,
      y: 656,
      shape: NodeMockType.LOAD,
      data: {
        name: '1车间生产机组',
        lineLength: 'small',
        type: BillingType.ProductionUnit1,
        voltage: 380,
        current: 1900,
      },
    },
    {
      x: gridSize / 2 + 428,
      y: 616,
      shape: NodeMockType.NODATA_BILLING_POINT,
      data: {},
    },
    {
      x: gridSize / 2 + 348,
      y: 656,
      shape: NodeMockType.LOAD,
      data: {
        lineLength: 'small',
        type: BillingType.ProductionUnit2,
        voltage: 380,
        current: 1700,
        name: '2车间生产机组',
      },
    },
    {
      x: gridSize / 2 + 626,
      y: 616,
      shape: NodeMockType.NODATA_BILLING_POINT,
      data: {},
    },
    {
      x: gridSize / 2 + 546,
      y: 656,
      shape: NodeMockType.LOAD,
      data: {
        lineLength: 'small',
        type: BillingType.Compressor,
        voltage: 380,
        current: 500,
        name: '空压机',
      },
    },
  ];
};
