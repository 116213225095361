import {
  Button,
  Checkbox,
  Form,
  FormTitle,
  Input,
  Radio,
  Select,
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { Col, Row, Space, Spin } from 'antd';
import { DeviceRowInfo } from './SelectDeviceModal';
import { useRequest } from 'ahooks';
import { ControlType, ControlTypeDisplay, DataPropertyType } from '../../type';
import SendValueDom from './SendValueDom';
import {
  apiV2DevicePropertyEdgePointPost,
  apiV2InstructionConfigCreatePost,
  apiV2InstructionConfigDetailPost,
  apiV2InstructionConfigUpdatePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import classNames from 'classnames/bind';
import SelectDeviceModal from './SelectDeviceModal';
const cx = classNames.bind(styles);

const ControlInstructionEdit = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectDeviceVisible, setSelectDeviceVisible] = useState<boolean>(false);

  // 选择的设备的信息
  const [currentRow, setCurrentRow] = useState<DeviceRowInfo>();

  const deviceId = Form.useWatch('deviceId', form);
  const targetValue = Form.useWatch('targetValue', form);
  const dataPropertyId = Form.useWatch('dataPropertyId', form);

  // 保存loading
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const routes = useMemo(() => {
    return [{ name: `${id ? '编辑' : '新建'}控制指令` }];
  }, [id]);

  // 查询详情
  const { data: detail, loading } = useRequest(
    () => {
      return apiV2InstructionConfigDetailPost({ id });
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  useEffect(() => {
    if (currentRow) {
      form.setFieldsValue({
        deviceId: currentRow.id,
        dataPropertyId: undefined,
        targetValue: undefined,
      });
    }
  }, [currentRow, form]);

  // 表单赋值
  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        deviceId: detail.deviceId,
        controlType: detail.controlType,
        dataPropertyId: detail.dataPropertyId,
        instructionName: detail.instructionName,
        buttonName: detail.buttonName,
        targetValue: detail.targetValue,
        canModify: detail.canModify,
      });
      // setCurrentRowKey(detail.deviceId);
    }
  }, [detail, form]);

  // 查询目标数据属性列表
  const { data: devicePropertyList } = useRequest(
    async () => {
      const res = await apiV2DevicePropertyEdgePointPost({ id: deviceId });
      if (res?.list) {
        return res.list;
      }
    },
    {
      ready: !!deviceId,
      refreshDeps: [deviceId],
    }
  );

  // 保存
  const onFinish = async () => {
    let requiredValid = await form
      .validateFields()
      .then(() => true)
      .catch(() => false);
    if (!requiredValid) return;
    let formRes = form.getFieldsValue();
    setSubmitLoading(true);
    if (id) {
      apiV2InstructionConfigUpdatePost({
        ...formRes,
        id: Number(id),
      })
        .then(res => {
          onCancel();
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    } else {
      apiV2InstructionConfigCreatePost(formRes)
        .then(() => {
          onCancel();
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };

  // 返回按钮
  const onCancel = () => {
    navigate('/microgrid/scheduling/instructionConfig');
  };

  const { k, b, dataPropertyType, enumValueMap, enumNameMap } = useMemo(() => {
    let data = devicePropertyList?.find(item => item.dataPropertyId === dataPropertyId) ?? {};
    let { k, b, dataPropertyType, enumValueMap, enumNameMap } = data;
    return { k, b, dataPropertyType, enumValueMap, enumNameMap };
  }, [dataPropertyId, devicePropertyList]);

  // 目标值下拉列表
  const targetValueList = useMemo(() => {
    return Object.entries(enumNameMap ?? {}).map(([key, value]) => ({
      label: `${value}-${key}`,
      value: +key,
    }));
  }, [enumValueMap, enumNameMap]);

  // 下发值 =（targetValue-b）/k
  const sendValue = useMemo(() => {
    let formateValueMap = enumValueMap as Record<string, any>;
    if (dataPropertyType === DataPropertyType.ENUM) {
      return formateValueMap?.[targetValue?.toFixed(1)]; // 0 ==> '0.0'
    }
    if (k && !isNaN(targetValue)) {
      if (!targetValue) return null; // 排除空字符串
      return (targetValue - (b ?? 0)) / k;
    }
    return null;
  }, [k, b, targetValue, enumValueMap, dataPropertyType]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrap}>
      <FormTitle title={id ? '编辑控制指令' : '新建控制指令'} />
      <SubContent>
        <Spin spinning={loading}>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="deviceId"
              label="目标设备"
              style={{ width: '50%' }}
              rules={[{ required: true, message: '请选择目标设备' }]}
            >
              <span
                className={cx('select_btn', id ? 'disable_btn' : '')}
                onClick={() => {
                  if (id) return;
                  setSelectDeviceVisible(true);
                }}
              >
                {currentRow?.name ?? detail?.deviceName ?? '选择'}
              </span>
            </Form.Item>

            <Form.Item
              label="控制类型"
              name="controlType"
              style={{ width: '50%' }}
              rules={[{ required: true }]}
              initialValue={ControlType.Platform}
            >
              <Radio.Group buttonStyle="solid">
                <Radio value={ControlType.Platform}>{ControlTypeDisplay[ControlType.Platform]}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label="目标数据属性" name="dataPropertyId" style={{ width: '50%' }} rules={[{ required: true }]}>
              <Select
                placeholder={form.getFieldValue('deviceId') ? '请选择' : '请先选择设备'}
                showSearch
                optionFilterProp="label"
                disabled={!deviceId}
                onChange={() => {
                  form.setFieldsValue({
                    targetValue: undefined,
                  });
                }}
                options={(devicePropertyList ?? []).map(item => ({
                  label: item.dataPropertyName,
                  value: item.dataPropertyId,
                }))}
              />
            </Form.Item>

            <Form.Item label="指令名称" name="instructionName" style={{ width: '50%' }} rules={[{ required: true }]}>
              <Input placeholder={'请输入指令名称'} maxLength={30} />
            </Form.Item>

            <Form.Item label="按钮名称" name="buttonName" style={{ width: '50%' }} rules={[{ required: true }]}>
              <Input placeholder={'请输入按钮名称'} maxLength={8} />
            </Form.Item>
            {dataPropertyType === DataPropertyType.YC ? (
              <Row>
                <Col span={9}>
                  <Form.Item
                    label="目标值"
                    name="targetValue"
                    // style={{ width: '70%', display: 'inline-flex' }}
                    rules={[
                      { required: true },
                      { pattern: /^(\-{0,1})[1-9][0-9]*([\.][0-9]{1,})?$/, message: '请输入数字' },
                    ]}
                  >
                    <Input
                      placeholder={dataPropertyId ? '请输入' : '请先选择目标数据属性'}
                      disabled={!dataPropertyId}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="canModify"
                    valuePropName="checked"
                    // style={{ width: '30%', display: 'inline-flex' }}
                    style={{ marginLeft: 10 }}
                    initialValue={true}
                  >
                    <Checkbox>下发时可修改</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Form.Item label="目标值" name="targetValue" style={{ width: '50%' }} rules={[{ required: true }]}>
                <Select
                  placeholder={dataPropertyId ? '请选择' : '请先选择目标数据属性'}
                  disabled={!dataPropertyId}
                  options={targetValueList}
                />
              </Form.Item>
            )}

            <div style={{ marginLeft: 136 }}>
              <SendValueDom sendValue={sendValue} dataPropertyType={dataPropertyType} k={k} b={b} />
            </div>

            <Space size={8} className="sticky-footer" style={{ zIndex: 99, paddingLeft: 0 }}>
              <Button type="primary" htmlType="submit" loading={submitLoading}>
                保存
              </Button>
              <Button className={styles.button} onClick={onCancel}>
                返回
              </Button>
            </Space>
          </Form>
        </Spin>
      </SubContent>
      {selectDeviceVisible && (
        <SelectDeviceModal
          currentDeviceId={currentRow?.id ?? detail?.deviceId}
          selectCurrentRow={currentRow}
          onCancel={() => setSelectDeviceVisible(false)}
          onConfirm={row => {
            setSelectDeviceVisible(false);
            setCurrentRow(row);
          }}
        />
      )}
    </Wrapper>
  );
};

export default ControlInstructionEdit;
