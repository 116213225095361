import React, { FC, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { CustomFilter, EllipsisSpan, Form, Input, Modal, Paging, Select, Table, usePaging } from '@maxtropy/components';
import Cascader, { DefaultOptionType } from 'antd/es/cascader';
import { useRequest } from 'ahooks';
import { DeviceTypeTree, DeviceTypes, formatOptionData, getChildNodesByParentIds } from '../../utils';
import {
  V2DevicePagePostResponse,
  apiV2DeviceDeviceTypeTreePost,
  apiV2DevicePagePost,
  apiV2PhysicalModelListPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isNil } from 'lodash-es';

interface SearchParams {
  nameOrCode?: string;
  deviceType?: Array<Array<number>>;
  physicalModelId?: string;
}

interface FilterParams {
  nameOrCode?: string;
  deviceType?: Array<number>;
  physicalModelId?: string;
}

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型',
    dataIndex: 'physicalModelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface ISelectDevice {
  onConfirm?: (row?: DeviceRowInfo) => void;
  onCancel?: () => void;
  currentDeviceId?: number;
  selectCurrentRow?: DeviceRowInfo;
}

export type DeviceRowInfo = Exclude<V2DevicePagePostResponse['list'], undefined>[number];

const SelectDevice: FC<ISelectDevice> = props => {
  const { onConfirm, onCancel, currentDeviceId, selectCurrentRow } = props;
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();
  const [filterParams, setFilterParams] = useState<FilterParams>();

  const [currentRow, setCurrentRow] = useState<DeviceRowInfo | undefined>(selectCurrentRow);
  const [currentRowKey, setCurrentRowKey] = useState<number | undefined>(currentDeviceId);

  const deviceType = Form.useWatch('deviceType', form);

  // 所属类目
  const { data: deviceTypeData } = useRequest(() => {
    return apiV2DeviceDeviceTypeTreePost();
  });

  // 表格
  const { data: list, loading } = useRequest(
    async () => {
      const res = await apiV2DevicePagePost({ ...filterParams, page: pageOffset, pageSize: pageSize });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, filterParams],
    }
  );

  // 物模型型号
  const { data: modelList } = useRequest(
    async () => {
      if (deviceType?.length === 0 || isNil(deviceType)) {
        return [];
      } else {
        const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree as DeviceTypes[]);
        const res = await apiV2PhysicalModelListPost({ deviceTypeIds });
        return res?.list;
      }
    },
    {
      refreshDeps: [deviceType],
    }
  );

  const onFinish = (val: SearchParams) => {
    const deviceTypeIds = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree as DeviceTypes[]);
    setPageOffset(1);
    setFilterParams({
      ...val,
      deviceType: deviceTypeIds,
    });
  };
  const onReset = () => {
    form.resetFields();
    setFilterParams({});
    setPageOffset(1);
  };

  const options = useMemo(() => {
    if (modelList && modelList.length) {
      return modelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [modelList]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter<FilterParams>
      form={form}
      colSpan={8}
      onFinish={val => onFinish(val as SearchParams)}
      onReset={onReset}
    >
      <Form.Item name="nameOrCode" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>

      <Form.Item name="deviceType" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData as DeviceTypeTree)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
          onChange={() => form.setFieldValue('physicalModelId', undefined)}
        />
      </Form.Item>
      <Form.Item name="physicalModelId" label="物模型">
        <Select
          allowClear
          optionFilterProp="label"
          showSearch
          options={options}
          disabled={!form.getFieldValue('deviceType') || form.getFieldValue('deviceType')?.length === 0}
          placeholder={
            !form.getFieldValue('deviceType') || form.getFieldValue('deviceType')?.length === 0
              ? '请先选择所属类目'
              : '请选择'
          }
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal title="设备选择" size="big" open={true} onOk={() => onConfirm?.(currentRow)} onCancel={() => onCancel?.()}>
      {filters}
      <div className={styles.action_line}>
        点击前往
        <span
          onClick={() => window.open(`${window.IOTPLATFORMORIGIN ?? ''}/device/manage/device/create`, '_blank')}
          className={styles.click_text}
        >
          添加设备
        </span>
      </div>

      <Table
        loading={loading}
        rowSelection={{
          type: 'radio',
          onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setCurrentRowKey(selectedRowKeys[0] as number);
            setCurrentRow(selectedRows[0] as DeviceRowInfo);
          },
          selectedRowKeys: currentRowKey ? [currentRowKey] : [],
        }}
        selectedCount={currentRowKey ? 1 : 0}
        showSelectedCount
        scroll={{ y: 300 }}
        rowKey="id"
        columns={columns}
        dataSource={list}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
};

export default SelectDevice;
