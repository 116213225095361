import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Node } from '@antv/x6';
import CustomTooltip from '../CustomTooltip';
import gateway from '../../imgs/gateway.gif';
import { TreeNode } from '../../type';
import styles from './index.module.scss';
import { judgeDrageOrClick } from '../../utils';

export interface GateWayProps {
  node: Node;
  allNodeDataRef?: TreeNode[];
  configId?: number;
}

const GateWay: FC<GateWayProps> = props => {
  const { node, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  return (
    <div className={styles.customGatewayReactNode}>
      <div className={styles.cardMeterSty_wrapper}>
        <div
          className={styles.cardMeterSty}
          ref={d => {
            if (d) {
              refBox.current = d;
            }
          }}
        >
          <div className={styles.card_content}>
            <img style={{ width: 24, height: 24, marginLeft: 12 }} src={gateway} alt="" />
            <div className={styles.gatewayNameSty}>
              <CustomTooltip
                title={
                  <>
                    <div>网关名称：{data.value.name}</div>
                    <div>网关编号：{data.value.serialNumber}</div>
                  </>
                }
              >
                <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
              </CustomTooltip>
            </div>
          </div>
          {/* <div className={styles.expand_content}>
            {data.isExpand ? (
              <>
                <div className={styles.dividerSty} />
                <div className={styles.device_prop_wrapper}>
                  {datas.map(item => (
                    <div className={classNames(styles.item_sty, styles.triangle)}>
                      <CustomTooltip title={'月数据'}>
                        <div className={styles.corner}></div>
                      </CustomTooltip>
                      <div className={styles.label_sty}>
                        <CustomTooltip title={item.name}>{item.name}</CustomTooltip>
                      </div>
                      <div className={styles.value_sty}>{item.value}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div> */}
        </div>
        {/* <div className={styles.expand_box} onClick={e => onExpand(e)}>
          {data.isExpand ? (
            <UpOutlined className={classNames(styles.nodeExpandIcon)} />
          ) : (
            <DownOutlined className={styles.nodeExpandIcon} />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default GateWay;
