import { FormTitle, Modal, SubContent, Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { TabsProps } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { RefProps, TabType, tabTypeDisplay, tabTypeMap } from '../const';
import styles from './index.module.scss';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
const routes = [{ name: '配置组态' }];
const ConfigTabs = () => {
  const routesContext = useBreadcrumbRoutes();
  const [currentTab, setCurrentTab] = useState('0');
  const refNode = useRef<RefProps>(null);
  const hsaConfigTarget = useHasPermission(PermissionsType.B_CONFIG_TARGET);
  const hsaConfigAlarm = useHasPermission(PermissionsType.B_CONFIG_ALARM);
  const hsaConfigElePoint = useHasPermission(PermissionsType.B_CONFIG_ELE_POINT);
  const hsaConfigHotspot = useHasPermission(PermissionsType.B_CONFIG_HOTSPOT);
  const hsaConfigEleCustom = useHasPermission(PermissionsType.B_CONFIG_ELE_CUSTOM);
  const persMap = useMemo(() => {
    return {
      [TabType.ALARM]: hsaConfigAlarm,
      [TabType.TARGET]: hsaConfigTarget,
      [TabType.CUSTOMPOINT]: hsaConfigEleCustom,
      [TabType.HOTSPOT]: hsaConfigHotspot,
      [TabType.LINKPOINT]: hsaConfigElePoint,
    };
  }, [hsaConfigTarget, hsaConfigAlarm, hsaConfigElePoint, hsaConfigHotspot, hsaConfigEleCustom]);

  const tabItems: TabsProps['items'] = useMemo(() => {
    return Object.entries(tabTypeDisplay)
      .map(([key, value]) => {
        let CurrentNode = tabTypeMap[+key as TabType];
        return {
          label: value,
          key: String(key),
          children: <CurrentNode ref={refNode} />,
          show: persMap[+key as TabType],
        };
      })
      .filter(item => item.show);
  }, [persMap]);

  const onTabChange = (v: string) => {
    if (!refNode.current?.isEditStatus) {
      setCurrentTab(v);

      return;
    }

    Modal.confirm({
      title: `是否保存该${tabTypeDisplay[+currentTab as TabType]}的配置?`,
      onOk() {
        refNode.current?.saveBtn?.(() => {
          setCurrentTab(v);
        });
      },
      onCancel() {
        setCurrentTab(currentTab);
      },
    });
  };
  return (
    <>
      <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title={routes[0].name} style={{ marginBottom: 18 }} />
        <SubContent>
          <Tabs className={styles.tab_box} items={tabItems} activeKey={currentTab} onChange={onTabChange} />
        </SubContent>
      </Wrapper>
    </>
  );
};

export default ConfigTabs;
