import { Button, EllipsisSpan, Filter, Paging, Table, usePaging } from '@maxtropy/components';
import { Col, Form, Input, Modal, TreeSelect } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import React, { Key, useEffect, useState } from 'react';

import styles from './addGatewayModal.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getOrganizationWithCodeWithCurrent } from '@/api/device';
import { apiV2NetworkFindGatewayV2Post } from '@maxtropy/intelligent-dispath-apis-v2';
import { List } from '@/pages/EnergyDispatchManagement/ElementCreationModal/Gateway/types';
import { NetworkRequest } from '@/api/netWorking';

export interface SearchParams extends Omit<NetworkRequest, 'page' | 'size' | ' netId'> {}

function formatTreeData(data: any[]) {
  return data.map(item => {
    const data = item?.data;
    const result: DefaultOptionType = {
      key: data?.mcid ?? '',
      value: data?.mcid ?? '',
      title: data?.name,
      children: formatTreeData(item.children),
      disabled: !data?.hasPermission,
    };
    return result;
  });
}

const modalColumns: ColumnsType<List> = [
  {
    title: '网关编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '网关名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'orgName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

interface Props {
  open: boolean;
  selectedRowKeys: Key[];
  onCancel: () => void;
  setSelectedRowKeys: (val: Key[]) => void;
  setSelectRows: (val: List[]) => void;
  dispatchUetStructId?: number;
}
const AddGatewayModal: React.FC<Props> = ({
  open,
  selectedRowKeys,
  setSelectedRowKeys,
  onCancel,
  setSelectRows,
  dispatchUetStructId,
}) => {
  const [form] = Form.useForm();

  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [gatewayDataSource, setGatewayDataSource] = useState<List[]>([]); // 所有网关

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const onOk = () => {
    const selectedRow = gatewayDataSource.filter(item => selectedRowKeys.includes(item.id as Key));
    setSelectRows(selectedRow);
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!open || !dispatchUetStructId) return;
    apiV2NetworkFindGatewayV2Post({
      ...searchParams,
      uetStructId: dispatchUetStructId,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total ?? 0);
          setGatewayDataSource(res.list ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open, pageOffset, pageSize, searchParams, setTotalCount, dispatchUetStructId]);

  const filters = (
    <Filter form={form} onFinish={onFinish} onReset={onReset}>
      <>
        <Col span={6}>
          <Form.Item name="gatewayNo" label="编号">
            <Input placeholder="请输入编号查询" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="gatewayName" label="名称">
            <Input placeholder="请输入名称查询" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="orgId" label="所属组织">
            <TreeSelect
              treeDefaultExpandAll
              treeData={treeData}
              placeholder="请选择"
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const rowSelection = {
    onChange: (rowKeys: Key[], _rows: List[]) => {
      setSelectedRowKeys(rowKeys);
    },
    selectedRowKeys,
  };

  return (
    <>
      <Modal width={1000} open={open} title="网关选择" onOk={onOk} onCancel={onCancel} destroyOnClose>
        {filters}
        <div style={{ float: 'right' }}>
          点击前往
          <Button
            type="link"
            onClick={() => window.open(`${window.IOTPLATFORMORIGIN}/device/config/gateway/create`, '_blank')}
          >
            添加网关
          </Button>
        </div>

        <div className={styles.tips}>
          <span>
            <InfoCircleOutlined style={{ color: 'var(--primary-color)', paddingRight: '2px' }} />
            仅zapdos版本在4.0以上，才支持组网，否则会下发失败。
          </span>
        </div>
        <Table
          loading={loading}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          scroll={{ y: 300 }}
          rowKey="id"
          columns={modalColumns}
          dataSource={gatewayDataSource}
        />
        <Paging pagingInfo={pagingInfo} />
        <p style={{ marginTop: '15px' }}>
          <InfoCircleOutlined style={{ color: 'var(--primary-color)', paddingRight: '2px' }} />
          提示：已作废的网关，不在此处展示
        </p>
      </Modal>
    </>
  );
};

export default AddGatewayModal;
