import React, { FC, useEffect, useState } from 'react';
import {
  BaseInfoContent,
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  Radio,
  SubContent,
  Table,
  Wrapper,
  InputNumber,
  message,
  useBreadcrumbRoutes,
  Tooltip,
  Select,
} from '@maxtropy/components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiV2BsaControlConfigPost,
  apiV2BsaDetailBsaV2Post,
  apiV2BsaEditControlConfigPost,
  apiV2BsaEmsDeviceListPost,
  apiV2BsaGetSealHeadPost,
  V2BsaControlConfigPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import { FormOutlined, InfoCircleOutlined, SettingOutlined, SyncOutlined } from '@ant-design/icons';
import TransformerCard from '@/pages/Bsa/ControlConfig/TransformerCard';
import { Affix, Col, Row, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { cloneDeep } from 'lodash-es';
import type { Rule } from 'rc-field-form/lib/interface';
import { isNil } from 'lodash';
import BaseInfo from '../RulesConfig/components/BaseInfo';
import { useRequest } from 'ahooks';
import AlarmRuleModal from './AlarmRuleModal';

interface Props {
  disabled?: boolean;
}

type PcsListItem = Exclude<V2BsaControlConfigPostResponse['pcsParameters'], undefined>[number];

enum ChargeDemandControlEnum {
  NONE = 0,
  STATIC = 1,
  MAX = 2,
}

const ChargeDemandControlLabel = {
  [ChargeDemandControlEnum.NONE]: '无',
  [ChargeDemandControlEnum.STATIC]: '固定需量',
  [ChargeDemandControlEnum.MAX]: '最大需量',
};

const otherConfigInputRules: Rule[] = [
  {
    required: true,
    type: 'integer',
    min: 1,
    max: 99999999,
    message: '请输入1~99999999范围内的整数',
  },
];

const SoftwareVersion = 'JSEMS-V1.0';

const ControlConfig: FC<Props> = props => {
  const { disabled = false } = props;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [bsaControlConfigData, setBsaControlConfigData] = useState<V2BsaControlConfigPostResponse>();
  const [form] = Form.useForm<V2BsaControlConfigPostResponse>();
  const pageName = disabled ? '查看控制配置' : '编辑控制配置';
  const [alarmRuleModalOpen, setAlarmRuleModalOpen] = useState(false);
  const [softwareVersion, setSoftwareVersion] = useState<string>();

  // 动环逻辑
  const { data: sealHeadList } = useRequest(async () => {
    const bsaData = await apiV2BsaDetailBsaV2Post({
      id: +id!,
    });
    setSoftwareVersion(bsaData.softwareVersion);
    const res = await apiV2BsaGetSealHeadPost({
      softwareVersion: bsaData.softwareVersion,
    });
    return res.list ?? [];
  });

  // 三方EMS
  const { data: emsDeviceList } = useRequest(async () => {
    const res = await apiV2BsaEmsDeviceListPost({
      id: +id!,
    });
    return res.list ?? [];
  });

  const transformerParameters: V2BsaControlConfigPostResponse['antiOverCapacityMeters'] = Form.useWatch(
    'antiOverCapacityMeters',
    form
  );

  const alarmRuleIdList = Form.useWatch('alarmRuleIdList', form);

  const chargeDemandControl: ChargeDemandControlEnum | undefined = Form.useWatch('chargeDemandControl', form);

  useEffect(() => {
    apiV2BsaControlConfigPost({ id: +id! }).then(res => {
      setBsaControlConfigData(res);
      form.setFieldsValue(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onRefresh = () => {
    apiV2BsaControlConfigPost({ id: +id! }).then(res => {
      form.setFieldsValue({ backflowThreshold: res.backflowThreshold, pcsParameters: res.pcsParameters });
      const oldPcsParameters: PcsListItem[] = form.getFieldValue('pcsParameters');
      const newPcsParameters: PcsListItem[] =
        res?.pcsParameters?.map(item => {
          const { socMax, socMin, ...rest } = item;
          return {
            ...rest,
            socMax: oldPcsParameters.find(oldItem => oldItem.pcsDeviceId === item.pcsDeviceId)?.socMax ?? socMax,
            socMin: oldPcsParameters.find(oldItem => oldItem.pcsDeviceId === item.pcsDeviceId)?.socMin ?? socMin,
          };
        }) ?? [];
      form.setFieldsValue({ pcsParameters: newPcsParameters });
    });
  };

  const onSave = async () => {
    if (!transformerParameters?.length) {
      message.warning('uedt未配置变压器');
      return;
    }

    if (transformerParameters.some(item => !item.id)) {
      message.warning('uedt未关联电压器电表');
      return;
    }

    const data = cloneDeep(await form.validateFields());

    apiV2BsaEditControlConfigPost({
      bsaId: +id!,
      chargeDemandControl: data?.chargeDemandControl,
      demandThreshold: data?.demandThreshold,
      chargeThreshold: data?.chargeThreshold,
      maxChargePower: data?.maxChargePower,
      maxDischargePower: data?.maxDischargePower,
      pcsParameters: data?.pcsParameters?.map(item => {
        return {
          pcsDeviceId: item.pcsDeviceId!,
          stackDeviceId: item.stackDeviceId!,
          socMax: item.socMax!,
          socMin: item.socMin!,
        };
      }),
      sealHeadVersion: data?.sealHeadVersion,
      alarmRuleIdList: data?.alarmRuleIdList,
      emsDeviceId: data?.emsDeviceId,
    }).then(() => {
      message.success('保存成功');
      navigate('/energy-storage/basic/bsa');
    });
  };

  const columns: ColumnsType<PcsListItem> = [
    {
      title: 'PCS名称',
      dataIndex: 'pcsName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: 'PCS额定功率',
      dataIndex: 'ratedPower',
      ellipsis: { showTitle: true },
      render: (v: number, record) =>
        isNil(v) ? (
          <Button
            type="link"
            onClick={() => {
              window.open(window.IOTPLATFORMORIGIN + `/device/manage/device/${record.pcsDeviceId}/attribute/edit`);
            }}
          >
            点击设置
          </Button>
        ) : (
          <EllipsisSpan value={v ?? '--' + 'kW'} />
        ),
    },
    {
      title: '电池堆名称',
      dataIndex: 'stackName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '电池堆最小SOC限制',
      key: 'socMin',
      ellipsis: { showTitle: true },
      render: (_, _record, index) => (
        <Form.Item
          name={['pcsParameters', index, 'socMin']}
          rules={[
            {
              required: true,
              type: 'integer',
              min: 0,
              max: 100,
              message: '请输入0~100范围内的整数',
            },
            {
              validator: async (_rule, value) => {
                if (value >= form.getFieldValue(['pcsParameters', index, 'socMax'])) {
                  throw new Error('需小于最大SOC限制');
                }
              },
            },
          ]}
          dependencies={[['pcsParameters', index, 'socMax']]}
          className={styles.mb0}
        >
          {!disabled ? <InputNumber></InputNumber> : <ShowInput></ShowInput>}
        </Form.Item>
      ),
    },
    {
      title: '电池堆最大SOC限制',
      key: 'socMax',
      ellipsis: { showTitle: true },
      render: (_, _record, index) => (
        <Form.Item
          name={['pcsParameters', index, 'socMax']}
          rules={[
            {
              required: true,
              type: 'integer',
              min: 0,
              max: 100,
              message: '请输入0~100范围内的整数',
            },
            {
              validator: async (_rule, value) => {
                if (value <= form.getFieldValue(['pcsParameters', index, 'socMin'])) {
                  throw new Error('需大于最小SOC限制');
                }
              },
            },
          ]}
          dependencies={[['pcsParameters', index, 'socMin']]}
          className={styles.mb0}
        >
          {!disabled ? <InputNumber></InputNumber> : <ShowInput></ShowInput>}
        </Form.Item>
      ),
    },
    {
      title: (
        <Space>
          上端变压器电表{' '}
          <Tooltip
            placement="top"
            title="来自UEDT中该储能站各个PCS所处节点，向上找到距离最近的变压器节点的防超容点中关联的电表"
          >
            <InfoCircleOutlined className={styles.infoIcon}></InfoCircleOutlined>
          </Tooltip>
        </Space>
      ),
      dataIndex: 'upstreamTransformerMeterName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: (
        <Space>
          并网点点表{' '}
          <Tooltip placement="top" title="来自UEDT中该储能站各个PCS所处节点所关联的并网点的电表">
            <InfoCircleOutlined className={styles.infoIcon}></InfoCircleOutlined>
          </Tooltip>
        </Space>
      ),
      dataIndex: 'gridPointMeterName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  // 不同软件版本渲染不同逻辑
  const renderSelect = () => {
    if (softwareVersion === SoftwareVersion) {
      return (
        <Form.Item label="动环逻辑" name="sealHeadVersion" required>
          <Select
            allowClear
            disabled={disabled}
            options={(sealHeadList ?? []).map(item => {
              return {
                label: item.sealHeadName,
                value: item.sealHeadVersion,
              };
            })}
          ></Select>
        </Form.Item>
      );
    } else {
      return (
        <Form.Item label="三方EMS" name="emsDeviceId" rules={[{ required: true, message: '请选择三方EMS' }]}>
          <Select
            placeholder="请选择"
            allowClear
            disabled={disabled}
            options={(emsDeviceList ?? []).map(item => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
          />
        </Form.Item>
      );
    }
  };

  return (
    <Wrapper className={styles.wrap} routes={[...(breadcrumbRoutes?.routes ?? []), { name: pageName }]}>
      <FormTitle
        className={styles.formTitle}
        title={pageName}
        extraContent={
          <div className={styles.titleExtraContent}>
            <Space size={6}>
              <Button
                onClick={() => {
                  navigate(`/energy-storage/basic/bsa/controlConfigDiff/${id}`);
                }}
              >
                查看修改日志
              </Button>
              {!disabled && (
                <Button onClick={onRefresh}>
                  <SyncOutlined />
                  刷新
                </Button>
              )}
            </Space>
          </div>
        }
      ></FormTitle>
      <SubContent>
        <BaseInfo hasBg />
      </SubContent>
      <Form form={form}>
        <SubContent className={styles.mb0}>
          <Row>
            <Col span={12}>{renderSelect()}</Col>
          </Row>
        </SubContent>

        <SubContent
          title={
            <>
              <span>防逆流保护</span>
              {!disabled && (
                <Space size={0}>
                  <Button
                    style={{ paddingRight: 8 }}
                    type="link"
                    onClick={() => {
                      window.open(
                        `/energy/scheduling/topology?dispatchingGroup=${bsaControlConfigData?.dispatchGroupId}&topologyId=${bsaControlConfigData?.dispatchUetStructId}`
                      );
                    }}
                  >
                    <SettingOutlined />
                    配置
                  </Button>
                  <Tooltip placement="right" title="来自UEDT中该储能站第一个PCS所处节点，向上找到距离最近的防逆流点">
                    <InfoCircleOutlined className={styles.infoIcon}></InfoCircleOutlined>
                  </Tooltip>
                </Space>
              )}
            </>
          }
        >
          <BaseInfoContent layout="horizontal">
            <Form.Item label="防逆流电表" name="backflowMeterName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="防逆流阈值" name="backflowThreshold">
              <ShowInput addonAfter="kW"></ShowInput>
            </Form.Item>
          </BaseInfoContent>
        </SubContent>

        <SubContent
          title={
            <>
              <span>防超容保护</span>
              {!disabled && (
                <Space size={0}>
                  <Button
                    style={{ paddingRight: 8 }}
                    type="link"
                    onClick={() => {
                      window.open(
                        `/energy/scheduling/topology?dispatchingGroup=${bsaControlConfigData?.dispatchGroupId}&topologyId=${bsaControlConfigData?.dispatchUetStructId}`
                      );
                    }}
                  >
                    <SettingOutlined />
                    配置
                  </Button>
                  <Tooltip
                    placement="right"
                    title="来自UEDT中该储能站各个PCS所处节点，向上找到距离最近的变压器节点和防超容点"
                  >
                    <InfoCircleOutlined className={styles.infoIcon}></InfoCircleOutlined>
                  </Tooltip>
                </Space>
              )}
            </>
          }
        >
          <BaseInfoContent layout="horizontal" colNum={1}>
            <Form.Item noStyle name="antiOverCapacityMeters"></Form.Item>
            <Form.Item label="变压器数量" style={{ marginBottom: transformerParameters?.length ? 9 : undefined }}>
              <div className={styles.flexAlignCenter}>
                <ShowInput value={transformerParameters?.length} style={{ marginRight: 8 }}></ShowInput>
                {transformerParameters?.length === 0 && (
                  <>
                    <InfoCircleOutlined className={styles.infoIcon}></InfoCircleOutlined>
                    <span>uedt未配置变压器</span>
                  </>
                )}
              </div>
            </Form.Item>
            {!!transformerParameters?.length && (
              <Form.Item>
                <Row gutter={[16, 16]}>
                  {transformerParameters?.map(item => (
                    <Col span={8} key={item.id}>
                      <TransformerCard {...item}></TransformerCard>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            )}
          </BaseInfoContent>
        </SubContent>

        <SubContent title="其他配置">
          <Row>
            <Col
              span={
                chargeDemandControl === ChargeDemandControlEnum.MAX ||
                chargeDemandControl == ChargeDemandControlEnum.STATIC
                  ? 12
                  : 24
              }
            >
              <Form.Item
                label="充电需量控制"
                name="chargeDemandControl"
                initialValue={ChargeDemandControlEnum.NONE}
                rules={[{ required: true, message: '请选择充电需量控制' }]}
              >
                <Radio.Group disabled={disabled}>
                  <Radio value={ChargeDemandControlEnum.MAX}>
                    {ChargeDemandControlLabel[ChargeDemandControlEnum.MAX]}
                  </Radio>
                  <Radio value={ChargeDemandControlEnum.STATIC}>
                    {ChargeDemandControlLabel[ChargeDemandControlEnum.STATIC]}
                  </Radio>
                  <Radio value={ChargeDemandControlEnum.NONE}>
                    {ChargeDemandControlLabel[ChargeDemandControlEnum.NONE]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {chargeDemandControl === ChargeDemandControlEnum.STATIC && (
              <Col span={12}>
                <Form.Item label="需量阈值" name="demandThreshold" rules={otherConfigInputRules}>
                  {!disabled ? <InputNumber addonAfter="kW"></InputNumber> : <ShowInput addonAfter="kW"></ShowInput>}
                </Form.Item>
              </Col>
            )}
            {chargeDemandControl === ChargeDemandControlEnum.MAX && (
              <Col span={12}>
                <Form.Item
                  label="允许充电功率"
                  name="chargeThreshold"
                  rules={otherConfigInputRules}
                  tooltip="在最大需量归零后允许充电的功率阈值"
                >
                  {!disabled ? <InputNumber addonAfter="kW" /> : <ShowInput addonAfter="kW" />}
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item label="最大充电功率" name="maxChargePower" rules={otherConfigInputRules}>
                {!disabled ? <InputNumber addonAfter="kW"></InputNumber> : <ShowInput addonAfter="kW"></ShowInput>}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="最大放电功率" name="maxDischargePower" rules={otherConfigInputRules}>
                {!disabled ? <InputNumber addonAfter="kW"></InputNumber> : <ShowInput addonAfter="kW"></ShowInput>}
              </Form.Item>
            </Col>
          </Row>
          {/* 如果软件版本是JS-V1则展示，否则去掉 */}
          {softwareVersion === SoftwareVersion ? (
            <>
              <Form.Item
                label={
                  <div>
                    PCS功率分配保护
                    <Tooltip title="若PCS触发了此处配置的报警规则，则不对该PCS分配功率。">
                      <InfoCircleOutlined style={{ color: 'var(--warning-color)', paddingLeft: 5 }} />
                    </Tooltip>
                  </div>
                }
                name="alarmRuleIdList"
                className={styles.longItem}
              >
                <>
                  已配置
                  <span
                    style={{
                      color: 'var(--error-color)',
                    }}
                  >
                    {alarmRuleIdList?.length ?? 0}
                  </span>
                  个报警规则
                  {!disabled && (
                    <Button type="link" icon={<FormOutlined />} onClick={() => setAlarmRuleModalOpen(true)}>
                      编辑
                    </Button>
                  )}
                </>
              </Form.Item>

              <Form.Item noStyle name="pcsParameters"></Form.Item>
              <Table
                style={{ marginTop: 8 }}
                rowKey="pcsDeviceId"
                columns={columns}
                dataSource={form.getFieldValue('pcsParameters')}
              ></Table>
            </>
          ) : null}
        </SubContent>
      </Form>

      <Affix offsetBottom={30}>
        <Space size={8} style={{ marginLeft: 32 }}>
          {!disabled && (
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
          )}
          <Button
            onClick={() => {
              navigate('/energy-storage/basic/bsa');
            }}
          >
            返回
          </Button>
        </Space>
      </Affix>

      {alarmRuleModalOpen && (
        <AlarmRuleModal
          bsaId={+id!}
          open={alarmRuleModalOpen}
          setOpen={setAlarmRuleModalOpen}
          alarmRuleIdList={form.getFieldValue('alarmRuleIdList') ?? []}
          setAlarmRuleIdList={val => {
            form.setFieldsValue({ alarmRuleIdList: val });
          }}
        ></AlarmRuleModal>
      )}
    </Wrapper>
  );
};

export default ControlConfig;
