import { Graph, Node } from '@antv/x6';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  RemoteControlEnum,
  SwitchStatus,
  TimeDimensionEnm,
  TimeDimensionEnmDisplay,
  TimeDimensionEnmDisplayParams,
  TreeNode,
} from '../../type';
import { NodeType, NodeTypeDisplay, SwitchSelectDeviceList } from '@/pages/SchedulingTopology/type';
import styles from './index.module.scss';
import icon from '../../imgs/universalSwitch.gif';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import {
  calcMaxLength,
  classificateNodeType,
  handleCommonExpand,
  handleDeviceChanged,
  judgeDrageOrClick,
} from '../../utils';
import classNames from 'classnames';
import { isNil } from 'lodash-es';
import {
  apiV2GreenMicrowebShowcaseInstructionConfigListPost,
  V2GreenMicrowebShowcaseDataPropertyDataPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import {
  dataPropertyConfigsItemProps,
  dataPropertyConfigsListProps,
  ITEM_HEIGHT,
  propertyItemProps,
  treeItemProps,
} from '../..';
import { keepTwoNull } from '@/pages/MicrogridConfOverview/utils';
import { MenuProps } from 'antd';
import { Dropdown, Select } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { useShowSendConfirmModal } from '../../../SideDraw/hooks';
import CustomTooltip from '../CustomTooltip';
import RenderCarousel from '../RenderCarousel';

export interface UniversalSwitchProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  switchSelectDeviceListRef?: SwitchSelectDeviceList[];
  configId?: number;
}

export type dataPropertyConfigItemProps = Exclude<treeItemProps['dataPropertyConfigs'], undefined>[number];
export type dataPropertyItemProps = Exclude<dataPropertyConfigItemProps['list'], undefined>[number];
type MergeData = dataPropertyConfigsItemProps & { value?: number; old?: boolean };
// 合闸/分闸数据属性
export const SwitchPropertyId = 10404;

const UniversalSwitch: FC<UniversalSwitchProps> = props => {
  const { node, graphRef, allNodeDataRef, switchSelectDeviceListRef, configId } = props;
  const refBox = useRef<HTMLDivElement>();
  const [propertyList, setPropertyList] = useState<MergeData[]>([]);
  const [switchStatusData, setSwitchStatusData] = useState<MergeData>();
  const [deviceSelectValue, setDeviceSelectValue] = useState<number>();
  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  // 获取数据点数据信息
  const dataPropertyDataList: V2GreenMicrowebShowcaseDataPropertyDataPostResponse['list'] = useMemo(() => {
    return data?.value?.deviceInfos;
  }, [data]);

  // 获取数据点信息
  const dataPropertyConfigsList: treeItemProps['dataPropertyConfigs'] = useMemo(() => {
    return data?.value?.dataPropertyConfigs ?? [];
  }, [data]);

  // 默认选择第一个设备
  useEffect(() => {
    if (dataPropertyConfigsList && dataPropertyConfigsList.length > 0) {
      const findNode = switchSelectDeviceListRef?.find(item => item.id === data?.value?.id);
      if (findNode) {
        setDeviceSelectValue(findNode.deviceId);
      } else {
        setDeviceSelectValue(dataPropertyConfigsList[0]?.deviceId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyConfigsList]);

  // 合并两组数据
  useEffect(() => {
    if (!isNil(dataPropertyDataList) && !isNil(deviceSelectValue) && !isNil(dataPropertyConfigsList)) {
      const dataList = dataPropertyDataList.find(
        (item: propertyItemProps) => item.deviceId === deviceSelectValue
      )?.list;
      const dataConfigList = dataPropertyConfigsList.find(
        (item: dataPropertyConfigsListProps) => item.deviceId === deviceSelectValue
      )?.list;
      const mergeArr = (dataConfigList ?? []).map((el: any) => ({
        ...el,
        value: dataList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.value,
        old: dataList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.old,
      })) as MergeData[];
      setSwitchStatusData(mergeArr.find(item => item.dataPropertyId === SwitchPropertyId));
      setPropertyList(mergeArr.filter(item => item.dataPropertyId !== SwitchPropertyId));
    }
  }, [deviceSelectValue, dataPropertyDataList, dataPropertyConfigsList]);

  // 数据过于陈旧展示
  const oldDataList = useMemo(() => {
    return (propertyList ?? [])
      .filter(item => item?.old)
      .map(i => i?.dataPropertyName)
      .join();
  }, [propertyList]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  // 展开收起
  const onExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    node.updateData({
      isExpand: !data.isExpand,
    });
    const height = !data.isExpand
      ? classificateNodeType(data?.element) + ITEM_HEIGHT * (propertyList ?? []).length
      : classificateNodeType(data?.element);
    const extraHeight = propertyList.length > 0 ? 96 : 0; // 下拉框与合闸操作
    handleCommonExpand(
      data?.value?.id,
      data?.element,
      node,
      height,
      !data.isExpand,
      propertyList.length,
      graphRef,
      allNodeDataRef,
      extraHeight
    );
  };
  // 控制指令列表
  const { data: orderList } = useRequest(
    () => apiV2GreenMicrowebShowcaseInstructionConfigListPost({ id: deviceSelectValue }),
    {
      refreshDeps: [deviceSelectValue],
      ready: !!deviceSelectValue,
    }
  );
  const { func: showSendConfirmModal } = useShowSendConfirmModal({ configId: configId! });
  const menuOnclick: MenuProps['onClick'] = ({ key }) => {
    let rowInfo = orderList?.list?.find(item => String(item.id) === key);

    if (rowInfo) {
      showSendConfirmModal(rowInfo);
    }
  };
  const menuItems: MenuProps['items'] = useMemo(() => {
    return (
      orderList?.list?.map(item => ({
        key: item.id?.toString()!,
        label: item.instructionName,
        disabled: item.dataPropertyId === RemoteControlEnum && item.targetValue === SwitchStatus.CLOSE,
      })) ?? [{ key: '000', label: '暂无指令', disabled: true }]
    );
  }, [orderList]);

  // 处理选择设备逻辑
  const handleDeviceChange = (deviceId: number) => {
    setDeviceSelectValue(deviceId);
    switchSelectDeviceListRef?.forEach(item => {
      if (item.id === data?.value?.id) {
        item.deviceId = deviceId;
      }
    });
    // 数据点列表
    const dataPropertyList = ((dataPropertyConfigsList as treeItemProps['dataPropertyConfigs']) ?? [])
      .find(item => item.deviceId === deviceId)
      ?.list?.filter(item => item?.dataPropertyId !== SwitchPropertyId);
    // 前后进行对比，改变高度与剩余节点位置
    // 处理选择设备后，不同设备具有不同数量的数据点
    let phaseValue;
    if (calcMaxLength((dataPropertyList ?? []).length) === calcMaxLength(propertyList.length)) {
      return;
    } else {
      phaseValue = calcMaxLength((dataPropertyList ?? []).length) - calcMaxLength(propertyList.length);
    }
    const height = classificateNodeType(data?.element) + ITEM_HEIGHT * calcMaxLength((dataPropertyList ?? []).length);
    const extraHeight = 96; // 下拉框与合闸操作
    handleDeviceChanged(
      data?.value?.id,
      data?.element,
      node,
      height,
      phaseValue,
      graphRef,
      allNodeDataRef,
      extraHeight
    );
  };

  return (
    <div className={styles.customUniversalSwitchNode}>
      <div className={styles.vline}></div>
      <div className={styles.hline}></div>
      <div className={styles.cardMeterSty_wrapper}>
        <div className={styles.cardMeterSty}>
          <div
            className={styles.content}
            ref={d => {
              if (d) {
                refBox.current = d;
              }
            }}
          >
            <img className={styles.img_sty} style={{ width: 40, height: 40 }} src={icon} alt="" />
            <CustomTooltip
              title={`${data.value.name}(${data?.element ? NodeTypeDisplay[data?.element as NodeType] : '--'})`}
            >
              <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
            </CustomTooltip>
            <div style={{ fontSize: 12 }}>断路器数量:{data.value?.deviceIds?.length}</div>
            {oldDataList && oldDataList.length > 0 && (
              <CustomTooltip title={`数据过于陈旧：${oldDataList}`}>
                <InfoCircleOutlined className={styles.infoIcon} />
              </CustomTooltip>
            )}
          </div>
          <div className={styles.expand_content}>
            {data.isExpand && propertyList.length > 0 ? (
              <>
                <div className={styles.dividerSty} />
                <Select
                  className={styles.select_sty}
                  popupClassName={styles.ele_drapDownSty}
                  placeholder="请选择"
                  notFoundContent={<div className={styles.ele_nodata_sty}>暂无数据</div>}
                  value={deviceSelectValue}
                  getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
                  onChange={deviceId => handleDeviceChange(deviceId)}
                  options={((dataPropertyConfigsList as treeItemProps['dataPropertyConfigs']) ?? []).map(item => ({
                    label: item.deviceName,
                    value: item.deviceId,
                  }))}
                  showSearch
                  optionFilterProp="label"
                />
                <div className={styles.operate}>
                  <div className={styles.status_box}>
                    {switchStatusData?.value === SwitchStatus.OPEN ? (
                      <>
                        <div className={styles.status_circle_open}></div>
                        <div className={styles.status_text}>分闸</div>
                      </>
                    ) : switchStatusData?.value === SwitchStatus.CLOSE ? (
                      <>
                        <div className={styles.status_circle_close}></div>
                        <div className={styles.status_text}>合闸</div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <Dropdown
                      overlayClassName={styles.operate_switch_box}
                      getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
                      trigger={['click']}
                      type="link"
                      menu={{ items: menuItems, onClick: menuOnclick }}
                    >
                      操作
                    </Dropdown>
                  </div>
                </div>
                <div className={styles.device_prop_wrapper}>
                  <RenderCarousel propertyLength={propertyList.length ?? 0}>
                    {(propertyList ?? []).map((item: any) => (
                      <div
                        className={styles.item_sty}
                        key={item.dataPropertyId}
                        onClick={() => {
                          window.open(
                            `${window.IOTPLATFORMORIGIN}/data/history/device?deviceId=${
                              deviceSelectValue ?? ''
                            }&dataPropertyId=${item?.dataPropertyId}&dateMode=${
                              !isNil(item?.timeDimensionEnum) &&
                              TimeDimensionEnmDisplayParams[item.timeDimensionEnum as TimeDimensionEnm]
                            }`,
                            '_blank'
                          );
                        }}
                      >
                        {item?.timeDimensionEnum &&
                          (item.timeDimensionEnum as TimeDimensionEnm) !== TimeDimensionEnm.REAL_TIME_VALUE && (
                            <CustomTooltip
                              title={
                                !isNil(item.timeDimensionEnum)
                                  ? TimeDimensionEnmDisplay[item.timeDimensionEnum as TimeDimensionEnm] + '数据'
                                  : '--'
                              }
                            >
                              <div className={styles.corner}></div>
                            </CustomTooltip>
                          )}
                        <div className={styles.label_sty}>
                          <CustomTooltip title={item?.dataPropertyName ?? '--'}>
                            {item?.dataPropertyName ?? '--'}
                          </CustomTooltip>
                        </div>
                        <div
                          className={
                            item?.timeDimensionEnum !== TimeDimensionEnm.REAL_TIME_VALUE
                              ? styles.value_sty
                              : styles.real_value_sty
                          }
                        >
                          <CustomTooltip title={keepTwoNull(item?.value) + (item?.generalName ?? '')}>
                            {keepTwoNull(item?.value) + (item?.generalName ?? '')}
                          </CustomTooltip>
                        </div>
                      </div>
                    ))}
                  </RenderCarousel>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {propertyList.length > 0 && (
          <div className={styles.expand_box} onClick={e => onExpand(e)}>
            {data.isExpand ? (
              <UpOutlined className={classNames(styles.nodeExpandIcon)} />
            ) : (
              <DownOutlined className={styles.nodeExpandIcon} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UniversalSwitch;
