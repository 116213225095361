import { PlusOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, PopConfirm, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2MicrogridConfigurationCustomisationDataPropertyConfigSavePost,
  apiV2MicrogridConfigurationCustomisationDeviceDeletePost,
  apiV2MicrogridConfigurationCustomisationPointDeviceListPost,
  V2MicrogridConfigurationCustomisationPointDeviceListPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import DeviceSelectModal from './DeviceSelectModal';
import DataPropertyModal, { SaveDataItem } from './LinkPoint/DataPropertyModal';
import { ElementType, elementTypeDisplay, RefProps } from '../const';
import FooterCancelBtn from './FooterCancelBtn';

type ListItem = Exclude<V2MicrogridConfigurationCustomisationPointDeviceListPostResponse['list'], undefined>[number];
const columns = [
  {
    title: '元素名称',
    ellipsis: true,
    dataIndex: 'elementName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '类',
    ellipsis: true,
    dataIndex: 'element',
    render: (v: ElementType) => <EllipsisSpan value={elementTypeDisplay[v]} />,
  },

  {
    title: '自定义设备',
    ellipsis: true,
    dataIndex: 'linkDeviceName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const CustomPoint: ForwardRefRenderFunction<RefProps> = (props, ref) => {
  const [deviceVisible, setDeviceVisible] = useState(false);
  const [propertyVisible, setPropertyVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState<ListItem>();
  const { id, uetStructId } = useParams<{ id: string; uetStructId: string }>();
  const [hasSave] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useImperativeHandle(ref, () => ({
    saveBtn: () => {},
    isEditStatus: !hasSave,
  }));
  const { data, loading, refresh } = useRequest(
    async () => {
      const res = await apiV2MicrogridConfigurationCustomisationPointDeviceListPost({
        id: id,
      });
      return res.list;
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  const deleteBtn = (row: ListItem) => {
    apiV2MicrogridConfigurationCustomisationDeviceDeletePost({
      id: row.id,
    }).then(refresh);
  };
  const getPropertyDataPoint = (data: SaveDataItem[]) => {
    setConfirmLoading(true);
    apiV2MicrogridConfigurationCustomisationDataPropertyConfigSavePost({
      uetStructId,
      microgridConfId: id,
      customisationPointId: currentRow?.id,
      detail: data ?? [],
    })
      .then(() => {
        refresh();
        setPropertyVisible(false);
      })
      .finally(() => setConfirmLoading(false));
  };
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 200,
      render: (v: any, record: ListItem) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setCurrentRow(record);
                setPropertyVisible(true);
              }}
            >
              选择数据点展示
            </Button>

            <PopConfirm title="是否删除该数据点？" onConfirm={() => deleteBtn(record)}>
              <Button type="link" danger>
                删除
              </Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Space size={8} style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setDeviceVisible(true);
          }}
        >
          新增
        </Button>
      </Space>

      <Table
        rowKey={'id'}
        sticky
        scroll={{ x: 1300, y: 'calc(100vh - 400px)' }}
        loading={loading}
        dataSource={data}
        columns={buildColumns}
        pagination={false}
      />
      <Space size={8} className={styles.tabs_content_sticky_footer}>
        {/* <PopConfirm title="是否保存该元素关联设备数据点的配置?" onConfirm={() => {}}>
          <Button type="primary">保存</Button>
        </PopConfirm> */}
        <FooterCancelBtn />
      </Space>
      {deviceVisible && (
        <DeviceSelectModal
          uetStructId={uetStructId}
          microgridConfId={id}
          onConfirm={() => {
            setDeviceVisible(false);
            refresh();
          }}
          onCancel={() => setDeviceVisible(false)}
        />
      )}
      <DataPropertyModal
        propData={{
          elementId: currentRow?.elementId,
          element: currentRow?.element,
          uetStructId,
          deviceId: currentRow?.linkDeviceId,
          microgridConfId: id,
        }}
        confirmLoading={confirmLoading}
        open={propertyVisible}
        onCancel={() => setPropertyVisible(false)}
        onOk={getPropertyDataPoint}
      />
    </div>
  );
};

export default forwardRef(CustomPoint);
