// 获取随机时间

import { Dayjs } from 'dayjs';
import {
  end10,
  end12,
  end15,
  end16,
  end17,
  end18,
  end19,
  end2,
  end20,
  end8,
  start0,
  start10,
  start12,
  start13,
  start15,
  start16,
  start17,
  start18,
  start2,
  start6,
  start8,
} from './demoData';

export function formatDate() {
  var a = new Date(new Date(new Date().toLocaleDateString()).getTime());
  var b = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
  var c = new Date(a).getTime();
  var d = new Date(b).getTime();
  var e = d - c;
  var f = Math.random() * e;
  const time = c + f;

  if (time != null) {
    var datetime = new Date();
    datetime.setTime(time);
    var year = datetime.getFullYear();
    var month = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
    var date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
    var hour = datetime.getHours() < 10 ? '0' + datetime.getHours() : datetime.getHours();
    var minute = datetime.getMinutes() < 10 ? '0' + datetime.getMinutes() : datetime.getMinutes();
    var second = datetime.getSeconds() < 10 ? '0' + datetime.getSeconds() : datetime.getSeconds();
    // return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
    return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
  } else {
    return '---';
  }
}

// 计算随机数 -x~x
export function randomNumber(number: number) {
  const randomBetween = Math.random() * number * 2 - number;
  return Number((randomBetween / 100).toFixed(4));
}

// 计算储能SOC
export function calcSocPercent(currentTime: Dayjs) {
  if (currentTime.isBetween(start0, end2)) {
    const diffTime = currentTime.diff(start0, 'minutes');
    // 0-100 2小时内匀速增长
    return (diffTime * 100) / 120;
  } else if (currentTime.isBetween(start13, end15)) {
    const diffTime = currentTime.diff(start13, 'minutes');
    // 0-100 2小时内匀速增长
    return (diffTime * 100) / 120;
  } else if (currentTime.isBetween(start8, end10)) {
    const diffTime = currentTime.diff(end8, 'minutes');
    // 0-100 2小时内匀速减少
    return 100 - Math.abs((diffTime * 100) / 120);
  } else if (currentTime.isBetween(start17, end19)) {
    const diffTime = currentTime.diff(end17, 'minutes');
    // 0-100 2小时内匀速减少
    return 100 - Math.abs((diffTime * 100) / 120);
  } else if (currentTime.isBetween(start2, end8) || currentTime.isBetween(start15, end17)) {
    return 100;
  } else {
    return 0;
  }
}

// 计算光伏
export function calcPvData(currentTime: Dayjs) {
  const randomBetween5 = randomNumber(5);
  if (currentTime.isBetween(start6, end8)) {
    const voltage = 750 * (1 + randomBetween5);
    const power = -5 * (1 + randomBetween5);
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start8, end10)) {
    const voltage = 750 * (1 + randomBetween5);
    const power = -15 * (1 + randomBetween5);
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start10, end12)) {
    const voltage = 750 * (1 + randomBetween5);
    const power = -35 * (1 + randomBetween5);
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start12, end16)) {
    const voltage = 750 * (1 + randomBetween5);
    const power = -55 * (1 + randomBetween5);
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start16, end18)) {
    const voltage = 750 * (1 + randomBetween5);
    const power = -28 * (1 + randomBetween5);
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start18, end20)) {
    const voltage = 750 * (1 + randomBetween5);
    const power = -3 * (1 + randomBetween5);
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else {
    const voltage = 0;
    const power = 0;
    const current = 0;
    return { voltage, power, current };
  }
}

export function calcBsaData(currentTime: Dayjs) {
  const randomBetween5 = randomNumber(5);
  if (currentTime.isBetween(start0, end2) || currentTime.isBetween(start13, end15)) {
    const voltage = 500 * (1 + randomBetween5);
    const power = 50;
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start8, end10) || currentTime.isBetween(start17, end19)) {
    const voltage = 500 * (1 + randomBetween5);
    const power = -50;
    const current = (power / voltage) * 1000;
    return { voltage, power, current };
  } else if (currentTime.isBetween(start2, end8) || currentTime.isBetween(start15, end17)) {
    const voltage = 550;
    const power = 0;
    const current = 0;
    return { voltage, power, current };
  } else {
    const voltage = 450;
    const power = 0;
    const current = 0;
    return { voltage, power, current };
  }
}
