import React, { FC, useEffect, useMemo, useState } from 'react';
import { Graph, Node } from '@antv/x6';
import { FcsList, GateWayList, nodeElementLinkTree, NodeType, TreeNode } from '../../type';
import styles from './index.module.scss';
import { VoltageLevel, VoltageLevelLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { Cascader, Dropdown, Popconfirm } from 'antd';
import { findDrawIndexByCurrentNode } from '../../utils/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import { CloseOutlined } from '@ant-design/icons';
import { limitByNodes } from '../../utils/nodelimit';

export interface GridSideProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  gatewayListRef?: GateWayList[];
  fcsListRef?: FcsList[];
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void;
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  nodeElementLink?: nodeElementLinkTree[];
}

const GridSide: FC<GridSideProps> = props => {
  const { node, graphRef, allNodeDataRef, gatewayListRef, handleCreateNode, confirm, nodeElementLink, fcsListRef } =
    props;
  const [cascaderValue, setCascaderValue] = useState<string[]>();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const handleBack = () => {
    setDropDownOpen(false);
  };
  useEffect(() => {
    if (window.history) {
      window.addEventListener('popstate', handleBack, false);
    }
    return () => {
      window.removeEventListener('popstate', handleBack, false);
    };
  }, []);

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  const isShowBottomBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowBottomAddBtn;
  }, [data]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div className={styles.customReactNode} style={{ height: 60, opacity: data.isShowNetWork ? 0.5 : 1 }}>
      {data?.isDelete && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data?.value?.summaryPointName ?? '电网侧'}</span>
              及该设备下级所有关联对象吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              NodeType.GRID_SIDE,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
      <div>
        <span className={styles.labelSty}>{data?.value?.summaryPointName ?? '电网侧'}</span>
        <span className={styles.valueSty}>
          {data?.value?.summaryPointName === '电网侧'
            ? VoltageLevelLabel[data.value?.voltageLevel as VoltageLevel] ?? ''
            : ''}
        </span>
      </div>
      <div className={styles.hline}></div>
      <div className={styles.vline}></div>
      {isShowBottomBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={dropDownOpen}
          onOpenChange={open => {
            setDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              expandTrigger={'hover'}
              open={dropDownOpen}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                setDropDownOpen(false);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'down',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircle}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default GridSide;
