import { apiV2DispatchUetStructUniversalJoinOpenPublishDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import { isNil } from 'lodash-es';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import { NodeType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';

export interface AntiBackflowDeviceDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const AntiBackflowDeviceDrawer: FC<AntiBackflowDeviceDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const { loading } = useRequest(
    async () => {
      const detail = await apiV2DispatchUetStructUniversalJoinOpenPublishDetailPost({ id: id!, version });

      form.setFieldsValue({
        ...detail,
        deviceId: detail?.id,
        deviceName: detail?.name,
        NodeTypeName: '通用开关',

        deviceNameList: detail?.deviceList?.map(item => item.name)?.join(', '),
      });

      setTitle(detail?.name);
    },
    {
      ready: !isNil(id) && !isNil(version) && open,
      refreshDeps: [id, open, version],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '通用开关'} nodeType={NodeType.SWITCH} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>

              <Descriptions.Item label="通用开关名称">
                <Item name="deviceName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>

              <Descriptions.Item label="断路器">
                <Item name="deviceNameList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['deviceName']}>
                    {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('deviceName')}</div>}
                  </Item>
                  <Item noStyle dependencies={['deviceList']}>
                    {({ getFieldValue }) => {
                      return (
                        <div style={{ fontSize: 12 }} className={styles.iconText}>
                          断路器数量&nbsp;:&nbsp;{getFieldValue('deviceList')?.length ?? 0}
                        </div>
                      );
                    }}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default AntiBackflowDeviceDrawer;
