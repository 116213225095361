import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  usePaging,
} from '@maxtropy/components';
import React, { FC, Key, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2DispatchUetStructDevicePagePost,
  apiV2DispatchUetStructMcidByUetIdsPost,
  apiV2DispatchUetStructOuByUetIdsPost,
  apiV2UetListUetTopologyStructPost,
  V2DispatchUetStructDevicePagePostRequest,
  V2DispatchUetStructDevicePagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';

const modalColumns = [
  {
    title: '电表编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电表名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouNames',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface AddMetersModalProps {
  topologyId: string | null;
  open: boolean;
  selectedRowKeys: Key[];
  onCancel: () => void;
  setSelectedRowKeys: (val: Key[]) => void;
  setSelectRows: (val: any[]) => void;
  gridAccessPointId?: React.Key;
}

export interface SearchParams extends Omit<V2DispatchUetStructDevicePagePostRequest, 'page' | 'size' | 'timestamp'> {}

// 电表
const DEVICE_TYPE = [5022, 8888, 5002, 5000];

const AddMetersModal: FC<AddMetersModalProps> = ({
  topologyId,
  open,
  selectedRowKeys,
  setSelectedRowKeys,
  onCancel,
  setSelectRows,
  gridAccessPointId,
}) => {
  const [form] = Form.useForm();
  const [uetIdsList, setUetIdsList] = useState<number[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [metersDataSource, setMetersDataSource] = useState<V2DispatchUetStructDevicePagePostResponse['list']>([]); // 所有电表

  useEffect(() => {
    setLoading(true);
    if (!open || !uetIdsList || !gridAccessPointId || !topologyId) return;
    apiV2DispatchUetStructDevicePagePost({
      ...searchParams,
      uetIds: uetIdsList,
      deviceTypeIdList: DEVICE_TYPE,
      page: pageOffset,
      size: pageSize,
      uetStructId: +topologyId!,
      gridAccessPointId,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total ?? 0);
          setMetersDataSource(res.list ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open, pageOffset, pageSize, searchParams, uetIdsList, setTotalCount, gridAccessPointId, topologyId]);

  // uetList
  useEffect(() => {
    if (!open) return;
    if (topologyId) {
      apiV2UetListUetTopologyStructPost({ id: +topologyId! }).then(res => {
        const UETIds = (res?.list?.map(item => item.id).filter(i => i !== undefined) as number[]) ?? [];
        setUetIdsList(UETIds);
      });
    }
  }, [open, topologyId]);

  // ou列表List
  const { data: ouList = [] } = useRequest(
    async () => {
      const res = await apiV2DispatchUetStructOuByUetIdsPost({
        ids: uetIdsList as number[],
      });
      if (res) {
        return res.list?.map(item => ({
          label: item.name,
          value: item.id,
        }));
      }
    },
    {
      ready: !!uetIdsList,
      refreshDeps: [uetIdsList],
    }
  );

  // 组织列表list
  const { data: treeData = [] } = useRequest(
    async () => {
      const res = await apiV2DispatchUetStructMcidByUetIdsPost({
        ids: uetIdsList as number[],
      });
      if (res) {
        return res.list?.map(item => ({
          label: item.name,
          value: item.mcid,
        }));
      }
    },
    {
      ready: !!uetIdsList,
      refreshDeps: [uetIdsList],
    }
  );

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const onOk = () => {
    const selectedRow = (metersDataSource ?? []).filter(item => selectedRowKeys.includes(item.id as Key));
    setSelectRows(selectedRow);
    form.resetFields();
    onCancel();
  };

  const filters = (
    <CustomFilter form={form} colSpan={8} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入编号查询" />
      </Form.Item>

      <Form.Item name="mcid" label="所属组织">
        <Select options={treeData} placeholder="请选择" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="ouId" label="运营单元">
        <Select options={ouList} placeholder="请选择" style={{ width: '100%' }} />
      </Form.Item>
    </CustomFilter>
  );

  const rowSelection = {
    onChange: (rowKeys: Key[], _rows: V2DispatchUetStructDevicePagePostResponse['list']) => {
      setSelectedRowKeys(rowKeys);
    },
    selectedRowKeys,
  };

  return (
    <>
      <Modal size="big" open={open} title="电表选择" onOk={onOk} onCancel={onCancel} destroyOnClose>
        {filters}
        <div style={{ float: 'right' }}>
          点击前往
          <Button
            type="link"
            onClick={() => window.open(`${window.IOTPLATFORMORIGIN}/device/manage/device/create`, '_blank')}
          >
            添加电表
          </Button>
        </div>

        <Table
          loading={loading}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          scroll={{ y: 300 }}
          rowKey="id"
          columns={modalColumns}
          dataSource={metersDataSource}
        />
        <Paging pagingInfo={pagingInfo} />
      </Modal>
    </>
  );
};

export default AddMetersModal;
