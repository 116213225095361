import { Node } from '@antv/x6';
import React, { FC, useMemo } from 'react';
import { NodeType, TreeNode } from '../../type';
import styles from './index.module.scss';
import gateway from '../../imgs/gateway.gif';
import { Popconfirm, Tooltip } from 'antd';
import { findDrawIndexByCurrentNode } from '../../utils/utils';
import { CloseOutlined } from '@ant-design/icons';

export interface GateWayProps {
  node: Node;
  allNodeDataRef?: TreeNode[];
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  showModalDetail?: (id: number, type: NodeType) => void;
}

const GateWay: FC<GateWayProps> = props => {
  const { node, allNodeDataRef, confirm, showModalDetail } = props;

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  return (
    <div
      className={styles.customGatewayReactNode}
      style={{
        opacity: data.isShowNetWork ? 1 : data.isShowAllAddDelBtn ? 1 : 0.5,
      }}
      onClick={() => {
        showModalDetail?.(data.id, NodeType.GATEWAY);
      }}
    >
      <img style={{ width: 24, height: 24, marginLeft: 12 }} src={gateway} alt="" />
      <div className={styles.gatewayNameSty}>
        <Tooltip
          title={
            <>
              <div>网关名称：{data.value.name}</div>
              <div>网关编号：{data.value.serialNumber}</div>
            </>
          }
        >
          <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
        </Tooltip>
      </div>
      {data?.isShowAllAddDelBtn && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data.value.name}</span>
              及该设备下级所有关联对象吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              NodeType.GATEWAY,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
    </div>
  );
};

export default GateWay;
