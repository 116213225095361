import { Node } from '@antv/x6';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import dcac from '../../imgs/DCAC.gif';

export interface AcDcConverterProps {
  node: Node;
}

const AcDcConverter: FC<AcDcConverterProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  return (
    <div className={styles.customAcDcConverterNode}>
      <div className={styles.cardSty}>
        <div className={styles.leftContentSty}>
          <img style={{ width: 40, height: 40 }} src={dcac} alt="" />
          <div className={styles.nameSty}>交直流变换器</div>
        </div>
        <div className={styles.dividerSty} />
        <div className={styles.rightContentSty}>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>电压</div>
            <div className={styles.itemPropValue}>{data?.voltage.toFixed(2)}V</div>
          </div>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>电流</div>
            <div className={styles.itemPropValue}>{data?.current.toFixed(2)}A</div>
          </div>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>功率</div>
            <div className={styles.itemPropValue}>{((data?.voltage * data?.current) / 1000).toFixed(2)}kW</div>
          </div>
        </div>
      </div>
      <div className={styles.vlineBottom}></div>
    </div>
  );
};

export default AcDcConverter;
