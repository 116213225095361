import { Form, Input, Modal, MultiSelect, Select } from '@maxtropy/components';
import { ListItem } from '..';
import { useRequest } from 'ahooks';
import {
  apiV2MicrogridConfigurationCheckConfNamePost,
  apiV2MicrogridConfigurationConfStatusCreatePost,
  apiV2MicrogridConfigurationConfStatusUpdatePost,
  apiV2MicrogridConfigurationDispatchUetStructListPost,
  apiV2OuListMcidPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useEffect, useState } from 'react';
interface Iprops {
  opType?: string;
  row?: ListItem;
  onConfirm?: () => void;
  onCancel?: () => void;
}
const MicroConfigModal = (props: Iprops) => {
  const isEdit = props.opType === 'edit';
  const { data: dispatchUetStructIdList } = useRequest(
    async () => {
      const res = await apiV2MicrogridConfigurationDispatchUetStructListPost({});
      return res.list?.map(item => ({
        label: `${item.dispatchGroupName}-${item.dispatchUetStructName}`,
        value: item.dispatchUetStructId,
      }));
    },
    {
      refreshDeps: [],
    }
  );
  const { data: ouList } = useRequest(
    () => {
      return apiV2OuListMcidPost({}).then(res => {
        if (props.opType === 'add') {
          return res.list?.filter(item => !item.disable);
        }
        return res.list;
      });
    },
    {
      refreshDeps: [props.opType],
    }
  );
  const [form] = Form.useForm();
  const groupName = Form.useWatch('name', form);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        ouIds: props.row?.ous?.map(item => item.id),
        name: props.row?.configurationName,
        dispatchUetStructId: props.row?.dispatchUetStructId,
      });
    }
  }, [isEdit]);
  const onOk = async () => {
    setConfirmLoading(true);
    let validRes = await form
      .validateFields()
      .then(res => res)
      .catch(_ => null);
    if (!validRes) {
      setConfirmLoading(false);
      return;
    }
    // 组态名称重复校验
    let isUnique = await apiV2MicrogridConfigurationCheckConfNamePost({
      name: groupName,
      id: isEdit ? props.row?.id : undefined,
    }).then(res => !res.flag);
    if (!isUnique) {
      form.setFields([{ name: 'name', errors: ['组态名称重复, 请重新输入'] }]);
      setConfirmLoading(false);
      return;
    }

    const api = isEdit
      ? apiV2MicrogridConfigurationConfStatusUpdatePost
      : apiV2MicrogridConfigurationConfStatusCreatePost;
    api({
      id: props.row?.id,
      ...validRes,
    })
      .then(() => props.onConfirm?.())
      .finally(() => setConfirmLoading(false));
  };
  return (
    <>
      <Modal
        open
        title={isEdit ? '编辑' : '新增'}
        contentClassName="modal-form-content"
        onOk={onOk}
        confirmLoading={confirmLoading}
        onCancel={() => props.onCancel?.()}
      >
        <Form form={form}>
          <Form.Item label="组态名称" name="name" rules={[{ required: true }]}>
            <Input maxLength={30} />
          </Form.Item>
          <Form.Item label="所属运营单元" name="ouIds" rules={[{ required: true }]}>
            <MultiSelect
              optionFilterProp="label"
              showSearch
              options={ouList?.map(item => ({ label: item.name, value: item.id, disabled: item.disable }))}
            />
          </Form.Item>
          <Form.Item label="关联调度组-拓扑" name="dispatchUetStructId" rules={[{ required: true }]}>
            <Select disabled={isEdit} options={dispatchUetStructIdList} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MicroConfigModal;
