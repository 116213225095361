import { FormContent, useBreadcrumbRoutes, Wrapper, Button, Tag } from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiV2DispatchUetFcsDispatchStructFcsDetailPost,
  apiV2DispatchUetStructFcsDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import bsaPng from './imgs/bsa.png';
import antiBackflowDevicePng from './imgs/antiBackflowDevice.png';
import transformerPng from './imgs/transformer.png';
import electricityMeterPng from './imgs/electricityMeter.png';
import { isNil } from 'lodash-es';

const ControllerMonitorNode: FC = () => {
  const routesContext = useBreadcrumbRoutes();

  const routes = [{ name: '监测节点' }];

  const navigate = useNavigate();

  const { id, dispatchGroupId, dispatchUetStructId } = useParams<{
    id?: string;
    dispatchGroupId?: string;
    dispatchUetStructId?: string;
  }>();

  const { data: fcsInfo } = useRequest(
    async () => {
      const res = await apiV2DispatchUetStructFcsDetailPost({ id: Number(id) });
      return res.fcsInfo;
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  const { data: fcsDetailInfo, loading } = useRequest(
    async () => {
      const res = await apiV2DispatchUetFcsDispatchStructFcsDetailPost({ id: Number(id) });
      return res;
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  const fcsName = useMemo(() => {
    return fcsInfo?.name ?? '';
  }, [fcsInfo]);

  return (
    <Wrapper routes={[...(routesContext?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormContent
        style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}
        title={`${fcsName}监测节点`}
        extraContent={
          <Space size={8}>
            <Button
              type="primary"
              onClick={() =>
                navigate(
                  `/energy/scheduling/topology?dispatchingGroup=${dispatchGroupId}&topologyId=${dispatchUetStructId}`
                )
              }
            >
              前往调度拓扑进行编辑
            </Button>
            <Button onClick={() => navigate(-1)}>返回</Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <div className={styles.container}>
            {/* bsa */}
            {(fcsDetailInfo?.fcsBsa ?? []).map(item => (
              <div className={styles.eachItemWrapper} key={Math.random()}>
                <div className={styles.leftCol}>
                  <img className={styles.imgSty} src={bsaPng} alt="" />
                  <div
                    className={styles.nameSty}
                    onClick={() => {
                      window.open(`${window.DMEPLATFORMSORIGIN}/energy-storage/basic/bsaNew`, '_blank');
                    }}
                  >
                    储能站
                  </div>
                </div>
                <div className={styles.rightCol}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>关联储能单元：</div>
                      <div className={styles.rightCol_value_unit}>
                        {item.bsaInfo?.bsaSimpleVo?.name ?? '--'} |{' '}
                        {item.bsaInfo?.bindPcsList
                          ? item.bsaInfo?.bindPcsList?.map(i => i.device?.name).join('、')
                          : '--'}
                      </div>
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>节点描述：</div>
                      <span>就地控制器投用的储能站</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>数据属性：</div>
                      {item?.deviceWithDataProperty ? (
                        <div>
                          {(item.deviceWithDataProperty ?? []).map(i => (
                            <div className={styles.dataPropertyContent} key={i.deviceId}>
                              <div
                                className={styles.dataPropertyContent_name}
                                onClick={() => {
                                  window.open(
                                    `${window.IOTPLATFORMORIGIN}/device/manage/device/${i.deviceId}/dataMining/edit`,
                                    '_blank'
                                  );
                                }}
                              >
                                {i.deviceName ?? '--'}
                              </div>
                              {(i.dataProperty ?? []).map(c => (
                                <Tag key={c.id} border="solid" color={c.isConfig ? '#52C41A' : '#FAAD14'}>
                                  {c.name}
                                </Tag>
                              ))}
                            </div>
                          ))}
                        </div>
                      ) : (
                        '--'
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            ))}

            {/* 防逆流 */}
            {(fcsDetailInfo?.fcsAntiBackflow ?? []).map(item => (
              <div className={styles.eachItemWrapper} key={Math.random()}>
                <div className={styles.leftCol}>
                  <img className={styles.imgSty} src={antiBackflowDevicePng} alt="" />
                  <div>防逆流装置</div>
                </div>
                <div className={styles.rightCol}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>关联设备：</div>
                      {item.deviceInfo?.deviceId ? (
                        <div
                          className={styles.rightCol_value}
                          onClick={() => {
                            window.open(
                              `${window.IOTPLATFORMORIGIN}/device/manage/device/${item.deviceInfo?.deviceId}/dataMining/edit`,
                              '_blank'
                            );
                          }}
                        >
                          <span className={styles.deviceName_Link}>{item.deviceInfo?.deviceName}</span>
                        </div>
                      ) : (
                        '--'
                      )}
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>节点描述：</div>
                      <span>储能站上游回路关联的防逆流装置</span>
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>防逆流阈值：</div>
                      <span style={{ color: '#52E7FF' }}>
                        {!isNil(item.backflowThreshold) ? item.backflowThreshold?.toFixed(2) + 'kW' : '--'}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>数据属性：</div>
                      {item?.deviceInfo?.dataProperty ? (
                        <div>
                          {(item.deviceInfo?.dataProperty ?? []).map(i => (
                            <div className={styles.dataPropertyContent} key={i.id}>
                              <Tag border="solid" color={i.isConfig ? '#52C41A' : '#FAAD14'}>
                                {i.name}
                              </Tag>
                            </div>
                          ))}
                        </div>
                      ) : (
                        '--'
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            ))}

            {/* 变压器 */}
            {(fcsDetailInfo?.fcsTransformer ?? []).map(item => (
              <div className={styles.eachItemWrapper} key={Math.random()}>
                <div className={styles.leftCol}>
                  <img className={styles.imgSty} src={transformerPng} alt="" />
                  <div>变压器</div>
                </div>
                <div className={styles.rightCol}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>关联设备：</div>
                      {item.deviceInfo?.deviceId ? (
                        <div
                          className={styles.rightCol_value}
                          onClick={() => {
                            window.open(
                              `${window.IOTPLATFORMORIGIN}/device/manage/device/${item.deviceInfo?.deviceId}/dataMining/edit`,
                              '_blank'
                            );
                          }}
                        >
                          <span className={styles.deviceName_Link}>{item.deviceInfo?.deviceName}</span>
                        </div>
                      ) : (
                        '--'
                      )}
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>节点描述：</div>
                      <span>储能站关联的变压器</span>
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>关联储能单元：</div>
                      <div className={styles.rightCol_value_unit}>
                        储能站 |{' '}
                        {item.bsaInfo?.bindPcsList
                          ? item.bsaInfo?.bindPcsList?.map(i => i.device?.name).join('、')
                          : '--'}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>防超容阈值：</div>
                      <span style={{ color: '#52E7FF' }}>
                        {!isNil(item.overcapacityThreshold) ? item.overcapacityThreshold?.toFixed(2) + 'kW' : '--'}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}

            {/* 防超容点 */}
            {(fcsDetailInfo?.fcsOverCapacity ?? []).map(item => (
              <div className={styles.eachItemWrapper} key={Math.random()}>
                <div className={styles.leftCol}>
                  <img className={styles.imgSty} src={electricityMeterPng} alt="" />
                  <div>防超容点</div>
                </div>
                <div className={styles.rightCol}>
                  <Row style={{ marginBottom: 8 }}>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>关联设备：</div>
                      {item.deviceInfo?.deviceId ? (
                        <div
                          className={styles.rightCol_value}
                          onClick={() => {
                            window.open(
                              `${window.IOTPLATFORMORIGIN}/device/manage/device/${item.deviceInfo?.deviceId}/dataMining/edit`,
                              '_blank'
                            );
                          }}
                        >
                          <span className={styles.deviceName_Link}>{item.deviceInfo?.deviceName ?? '--'}</span>
                        </div>
                      ) : (
                        '--'
                      )}
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>节点描述：</div>
                      <span>储能站关联的防超容电表</span>
                    </Col>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>关联变压器：</div>
                      <div className={styles.rightCol_value_unit}>{item.relatedTransformerName ?? '--'}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} style={{ display: 'flex' }}>
                      <div className={styles.labelSty}>数据属性：</div>
                      {item?.deviceInfo?.dataProperty ? (
                        <div>
                          {(item.deviceInfo?.dataProperty ?? []).map(i => (
                            <div className={styles.dataPropertyContent} key={i.id}>
                              <Tag border="solid" color={i.isConfig ? '#52C41A' : '#FAAD14'}>
                                {i.name}
                              </Tag>
                            </div>
                          ))}
                        </div>
                      ) : (
                        '--'
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        </Spin>
      </FormContent>
    </Wrapper>
  );
};

export default ControllerMonitorNode;
