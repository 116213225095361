import React, { useEffect, useRef, useState } from 'react';
import { Button, FormContent, Modal, Tooltip, useBreadcrumbRoutes, useUpdate, Wrapper } from '@maxtropy/components';
import { Descriptions, Divider, Drawer, message, Space } from 'antd';
import { DoubleRightOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  apiV2DispatchUetStructContinuousEditingPost,
  apiV2DispatchUetStructGatewaySavePost,
  apiV2DispatchUetStructGetTreePost,
  apiV2DispatchUetStructNodeElementLinkPost,
  apiV2DispatchUetStructStructInfoPost,
  apiV2DispatchUetStructUpdateTreePost,
  V2DispatchUetStructStructInfoPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import styles from './index.module.scss';
import {
  allInfoProps,
  BusBarCircleNumber,
  busbarNeedExtendsMapArr,
  FcsList,
  GateWayList,
  nodeElementLinkTree,
  NodeType,
  TreeNode,
} from './type';
import PowerGridModal from '../EnergyDispatchManagement/ElementCreationModal/PowerGrid';
import TransformerModal from '../EnergyDispatchManagement/ElementCreationModal/Transformer';
import PhotovoltaicStationModal from '../EnergyDispatchManagement/ElementCreationModal/PhotovoltaicStation';
import MeteringMeterModal from '../EnergyDispatchManagement/ElementCreationModal/MeteringMeter';
import LoadModal from '../EnergyDispatchManagement/ElementCreationModal/Load';
import GeneratrixModal from '../EnergyDispatchManagement/ElementCreationModal/Generatrix';
import GatewayModal from '../EnergyDispatchManagement/ElementCreationModal/Gateway';
import EnergyStorageStationModal from '../EnergyDispatchManagement/ElementCreationModal/EnergyStorageStation';
import {
  addPortwithGateWay,
  deleteEleFetch,
  getNodePositionAndSize,
  initScTopoGraph,
  isMeters,
  moveNodesAffterAdd,
  moveNodesAffterRemove,
  portGroups,
} from './utils/graph';
import { DataUri, Edge, Graph, Node } from '@antv/x6';
import registerCustomerNode from './utils/registerCustomerNode';
import ChargingStationModal from '../EnergyDispatchManagement/ElementCreationModal/ChargingStation';
import BackupPowerModal from '../EnergyDispatchManagement/ElementCreationModal/BackupPower';
import AntiBackflowDeviceModal from '../EnergyDispatchManagement/ElementCreationModal/AntiBackflowDevice';
import { formatSubmitValue, formatNodeLinkData, updateNodeDataAndTreeData } from './utils/utils';
import { viewTypeEnum } from './PreviewTopo';
import FcsController from '../EnergyDispatchManagement/ElementCreationModal/FcsController';
import { useRequest } from 'ahooks';
import {
  addGridSide,
  changePreviewNodeData,
  firstRenderSide,
  formatResSingleData,
  formatResTreeData,
  formatResTreeDataSideNode,
  handleFetchData,
  removeAllEdgeAndPort,
} from './utils/dataUtil';
import { createNodeAndDrawCanvas, graphAddEdgeByNodeLink, graphAllNodesAddChild } from './utils/draw';
import dayjs from 'dayjs';
import CreatingModal from './PublishLoading/CreatingModal';
import { isNil } from 'lodash-es';
import * as htmlToImage from 'html-to-image';
import UniversalSwitch from '../EnergyDispatchManagement/ElementCreationModal/UniversalSwitch';
import OtherVertical from '../EnergyDispatchManagement/ElementCreationModal/OtherVertical';
import OtherTransverse from '../EnergyDispatchManagement/ElementCreationModal/OtherTransverse';

const pollingInterval = 1000 * 5; // 5秒轮询一次

const SchedulingTopologyX6 = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_dispatchingGroupId = urlSearchParams.get('dispatchingGroup') || undefined;
  let url_topologyIdId = urlSearchParams.get('topologyId') || undefined;
  const [messageApi, messageContextHolder] = message.useMessage();

  // ref
  const refContainer = useRef<HTMLDivElement>(); // 画布container
  const graphRef = useRef<Graph>(); // 画布实例
  const edgesRef = useRef<Edge<Edge.Properties>[]>([]);
  const allNodeData = useRef<TreeNode[]>([]); // 所有节点
  const gatewayList = useRef<GateWayList[]>([]); // 网关节点
  const fcsList = useRef<FcsList[]>([]); // Fcs节点
  const maxXRef = useRef<number>(0);

  // state
  const [type, setType] = useState<NodeType>(); // 节点类型
  const [dispatchInfo, setDispatchInfo] = useState<V2DispatchUetStructStructInfoPostResponse>(); // 基本信息
  const [allInfo, setAllInfo] = useState<allInfoProps>(); // 保存画图以及数据
  const [currentNodeId, setCurrentNodeId] = useState<number>(); // 当前点击的节点id
  const [viewType, setViewType] = useState<viewTypeEnum>(); // 预览
  const [modalOpen, setModalOpen] = useState<boolean>(false); // 弹窗的打开与收起
  const [nodeElementLink, setNodeElementLink] = useState<nodeElementLinkTree[]>([]); // 节点类型下拉

  const [openBottomMemu, setOpenBottomMemu] = useState<boolean>(false); // 下方菜单抽屉
  const [loading, setLoading] = useState<boolean>(false); // loading
  const [canvasLoading, setCanvasLoading] = useState<boolean>(false);
  const [_, updateFn] = useUpdate();
  const randomTime = useRef<number>(dayjs().valueOf());

  // 防止两个人同时编辑的时候出问题
  const { cancel } = useRequest(
    () => {
      return apiV2DispatchUetStructContinuousEditingPost({
        id: Number(url_topologyIdId),
        random: randomTime.current,
      }).then(res => {
        if (res.isContinuousEditing) {
          cancel();
          navigate(
            `/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/edit/${url_dispatchingGroupId}/schedulingTopology`
          );
        }
      });
    },
    {
      pollingInterval,
      ready: !!url_topologyIdId && !!randomTime.current,
    }
  );

  // 创建画布
  useEffect(() => {
    const graph = initScTopoGraph(refContainer.current!);
    graphRef.current = graph;
  }, []);

  // 下拉选择节点类型
  useEffect(() => {
    apiV2DispatchUetStructNodeElementLinkPost().then(res => {
      const list = formatNodeLinkData(res.list);
      setNodeElementLink(list);
    });
  }, []);

  // 弹窗关闭，清空数据
  useEffect(() => {
    if (!modalOpen) {
      setCurrentNodeId(undefined);
      setType(undefined);
    }
  }, [modalOpen]);

  // 查询数据
  useEffect(() => {
    if (url_topologyIdId && randomTime.current) {
      setCanvasLoading(true);
      apiV2DispatchUetStructGetTreePost({ id: Number(url_topologyIdId), random: randomTime.current }).then(res => {
        setCanvasLoading(false);
        if (!res) {
          // 画布添加默认的一个电网侧
          const addNodeData = firstRenderSide(allNodeData.current);
          graphRef.current!.addNode(addNodeData);
          updateFn();
        } else {
          // 渲染数据
          const allgraphNodes = handleFetchData(res);
          graphRef.current!.addNodes(allgraphNodes);
          // 维护网关与fcs（单独的）
          gatewayList.current = formatResSingleData(NodeType.GATEWAY, res?.gatewayList);
          fcsList.current = formatResSingleData(NodeType.FCS_CONTROLLER, res?.fcsList);
          // 维护树结构（电网侧不是实体）
          allNodeData.current = formatResTreeData(res?.trees ?? []);
          // 维护电网侧元素Node（电网侧不是实体，后端数据不返回，因此前端新加）
          const sideNodes = formatResTreeDataSideNode(res?.trees ?? [], res.diagrams);
          graphRef.current!.addNodes(sideNodes);
          // 添加节点与母线的边
          graphAllNodesAddChild(graphRef.current!, allNodeData.current);
          // 添加自定义节点与母线或节点关联的边
          graphAddEdgeByNodeLink(graphRef.current!);
          updateFn();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_topologyIdId]);

  useEffect(() => {
    // 预览
    if (graphRef.current) {
      const addedAllNodes = graphRef.current!.getNodes();
      const gridSideNodesExceptFirst = allNodeData.current.filter((_, index) => index !== 0);
      if (viewType === viewTypeEnum.ENERGYFLOWTOPO) {
        // 后面的电网侧删除按钮去除，隐藏删除和添加按钮，以及isShowNetWork=false（透明度）
        changePreviewNodeData(addedAllNodes, gridSideNodesExceptFirst, false, false, false);
        const egdes = graphRef.current.getEdges();
        egdes.forEach(item => {
          item.attr('line/stroke', 'rgba(74, 144, 226, 1)'); // 修改边颜色，等价于 edge.prop('attrs/line/stroke', '#ccc')
        });
      } else if (viewType === viewTypeEnum.NETWORK) {
        // 后面的电网侧删除按钮去除
        changePreviewNodeData(addedAllNodes, gridSideNodesExceptFirst, false, false, true);
        const egdes = graphRef.current.getEdges();
        egdes.forEach(item => {
          item.attr('line/stroke', 'rgba(74, 144, 226, 0.5)'); // 修改边颜色，等价于 edge.prop('attrs/line/stroke', '#ccc')
        });
        // 添加链接装,如果有关联（fcs的networkId与gateway的networkId对应上的话）
        addPortwithGateWay(addedAllNodes, graphRef.current, edgesRef.current);
      } else {
        const egdes = graphRef.current.getEdges();
        egdes.forEach(item => {
          item.attr('line/stroke', 'rgba(74, 144, 226, 1)'); // 修改边颜色，等价于 edge.prop('attrs/line/stroke', '#ccc')
        });
        // 后面的电网侧删除按钮去除
        changePreviewNodeData(addedAllNodes, gridSideNodesExceptFirst, true, true, false);
        // 删除所有的边和链接桩子
        removeAllEdgeAndPort(addedAllNodes, edgesRef.current, graphRef.current);
      }
    }
  }, [viewType]);

  // 下拉创建节点
  const handleCreateNode = (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    circleIndex?: number,
    circleArr?: BusBarCircleNumber[]
  ) => {
    setAllInfo({
      upperOrDown,
      allNodeData,
      node,
      graph,
      addNodeType,
      gatewayList,
      fcsList,
      circleIndex,
      circleArr,
    });
    setType(addNodeType);
    setCurrentNodeId(undefined);
    setModalOpen(true);
  };

  // 查询拓扑结构基本信息
  useEffect(() => {
    if (url_topologyIdId) {
      apiV2DispatchUetStructStructInfoPost({
        id: Number(url_topologyIdId),
      }).then(res => {
        setDispatchInfo(res);
      });
    }
  }, [url_topologyIdId]);

  // 获取编辑的完成后的数据
  const getFinishedValues = (values: any) => {
    if (values.isEdit) {
      // 编辑节点（改变元素数据以及树形结构数据）
      updateNodeDataAndTreeData(
        graphRef.current!,
        type!,
        values,
        allNodeData.current,
        gatewayList.current,
        fcsList.current
      );
    } else {
      if (allInfo) {
        if (allInfo.addNodeType === NodeType.FCS_CONTROLLER && values.gatewayList && values.gatewayList.length > 0) {
          const { x, y } = getNodePositionAndSize(allInfo.node);
          const busbarNeedExtendsMapTemp: busbarNeedExtendsMapArr = allInfo.node.getData().busbarNeedExtendsMap;
          const findIndex = busbarNeedExtendsMapTemp?.circleArr?.findIndex(
            i => i.circleNumber === allInfo?.circleIndex
          );

          values.gatewayList.forEach((item: any, index: number) => {
            apiV2DispatchUetStructGatewaySavePost({
              dispatchGroupId: Number(url_dispatchingGroupId),
              dispatchUetStructId: Number(url_topologyIdId),
              edgeGatewayId: item?.gatewayId,
            }).then(res => {
              gatewayList.current.push({
                id: res.id!,
                element: NodeType.GATEWAY,
              });
              graphRef.current!.addNode({
                shape: NodeType.GATEWAY, // 指定使用何种图形，默认值为 'rect'
                x: !isNil(findIndex) ? x + 20 + findIndex * 280 + (index + 1) * 26 : x + 120 + (index + 1) * 26,
                y: y + (index + 1) * 60,
                ports: portGroups,
                data: {
                  isShowAllAddDelBtn: true,
                  disableMove: true,
                  id: res?.id,
                  element: NodeType.GATEWAY,
                  value: {
                    id: res?.id,
                    edgeGatewayId: res?.edgeGatewayId,
                    netWorkId: res?.networkId,
                    name: res?.name,
                    serialNumber: res?.serialNumber,
                  },
                },
              });
            });
          });
        }
        // 创建节点（需要维护数组与画布）
        const { allNodeDataTemp } = createNodeAndDrawCanvas(
          allInfo.upperOrDown,
          allInfo.allNodeData,
          allInfo.node,
          allInfo.graph,
          allInfo.addNodeType,
          allInfo.gatewayList,
          allInfo.fcsList,
          values,
          allInfo.circleIndex,
          allInfo.circleArr
        );
        const currentGroupId = allInfo!.allNodeData[0].id;
        // 添加节点之后，右边的往右边移动
        const maxXRefTemp = moveNodesAffterAdd(
          allNodeData.current,
          allNodeDataTemp,
          currentGroupId,
          maxXRef.current,
          type,
          graphRef.current
        );
        maxXRef.current = maxXRefTemp;
      }
    }
  };

  // 渲染每个modal框
  const renderEachModal = () => {
    const propParams = {
      isShowModal: (open: boolean) => {
        setModalOpen(open);
      },
      open: modalOpen,
      getFinishedValues: getFinishedValues,
      id: currentNodeId,
      dispatchGroupId: url_dispatchingGroupId ? Number(url_dispatchingGroupId) : undefined,
      dispatchUetStructId: url_topologyIdId ? Number(url_topologyIdId) : undefined,
      isPreview: !!viewType,
      type,
      allInfo, // 用于创建储能站时，查找本电网侧电网接入点id
      graphRef: graphRef.current, // 用于校验创建储能站时，画布中是否有此储能站
      allNodeData: allNodeData.current,
    };
    if (type === NodeType.GRID_ACCESS_POINT) {
      return <PowerGridModal {...propParams} />;
    } else if (type === NodeType.TRANSFORMER) {
      return <TransformerModal {...propParams} />;
    } else if (type === NodeType.PVA) {
      return <PhotovoltaicStationModal {...propParams} />;
    } else if (type && isMeters(type)) {
      return <MeteringMeterModal {...propParams} />;
    } else if (type === NodeType.LOAD) {
      return <LoadModal {...propParams} />;
    } else if (type === NodeType.BUS_BAR) {
      return <GeneratrixModal {...propParams} />;
    } else if (type === NodeType.GATEWAY) {
      return <GatewayModal {...propParams} />;
    } else if (type === NodeType.BSA) {
      return <EnergyStorageStationModal {...propParams} />;
    } else if (type === NodeType.CHARGING_STATION) {
      return <ChargingStationModal {...propParams} />;
    } else if (type === NodeType.BACKUP_POWER) {
      return <BackupPowerModal {...propParams} />;
    } else if (type === NodeType.ANTI_BACKFLOW_DEVICE) {
      return <AntiBackflowDeviceModal {...propParams} />;
    } else if (type === NodeType.FCS_CONTROLLER) {
      return <FcsController {...propParams} />;
    } else if (type === NodeType.UNIVERSAL_JOINT_OPENING) {
      return <UniversalSwitch {...propParams} />;
    } else if (type === NodeType.OTHER_VERTICAL) {
      return <OtherVertical {...propParams} />;
    } else if (type === NodeType.OTHER_TRANSVERSE) {
      return <OtherTransverse {...propParams} />;
    }
  };

  // 保存树结构
  const onSaveTree = () => {
    const { diagrams, trees } = formatSubmitValue(graphRef.current, allNodeData.current);
    if (diagrams?.length === 0) {
      Modal.error({
        title: '保存失败，请为画布添加节点元素',
      });
    } else {
      const params = {
        dispatchGroupId: Number(url_dispatchingGroupId),
        dispatchUetStructId: Number(url_topologyIdId),
        trees,
        diagrams: diagrams ?? [],
        gatewayIds: gatewayList.current.map(i => i.id),
        fcsIds: fcsList.current.map(i => i.id),
        publish: false,
        random: randomTime.current,
      };
      apiV2DispatchUetStructUpdateTreePost(params).then(_ => {
        navigate(
          `/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/edit/${url_dispatchingGroupId}/schedulingTopology`
        );
      });
    }
  };

  // 确认删除
  const confirm = (type: NodeType, id?: number, drawIndex?: number) => {
    // 删除电网侧元素，index得变化
    deleteEleFetch(
      graphRef.current!,
      allNodeData.current,
      gatewayList.current,
      fcsList.current,
      type,
      messageApi,
      id,
      drawIndex,
      url_topologyIdId
    ).then(res => {
      // 其余电网侧（右边的）向左移动
      const maxXRefTemp = moveNodesAffterRemove(
        res,
        allNodeData.current,
        graphRef.current!,
        maxXRef.current,
        drawIndex,
        type
      );

      maxXRef.current = maxXRefTemp;
      allNodeData.current = res;
    });
  };

  // 弹窗展示
  const showModalDetail = (id: number, type: NodeType) => {
    setModalOpen(true);
    setType(type);
    setCurrentNodeId(id);
  };

  // 发布
  const onPublishTree = () => {
    const { diagrams, trees } = formatSubmitValue(graphRef.current, allNodeData.current);
    if (diagrams?.length === 0) {
      Modal.error({
        title: '发布失败，请为画布添加节点元素',
      });
    } else {
      const params = {
        dispatchGroupId: Number(url_dispatchingGroupId),
        dispatchUetStructId: Number(url_topologyIdId),
        trees,
        diagrams: diagrams ?? [],
        gatewayIds: gatewayList.current.map(i => i.id),
        fcsIds: fcsList.current.map(i => i.id),
        publish: true,
        random: randomTime.current,
      };
      setLoading(true);
      apiV2DispatchUetStructUpdateTreePost(params).then(res => {
        if (res.success) {
          setLoading(false);
          cancel();
          Modal.success({
            title: '拓扑关系发布成功',
            onOk: () => {
              navigate(
                `/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/edit/${url_dispatchingGroupId}/schedulingTopology`
              );
            },
          });
        } else {
          setLoading(false);
          if (res.errorMessage) {
            Modal.error({
              title: '请确认以下异常信息',
              content: (
                <>
                  {(res.errorMessage ?? []).map(item => (
                    <div>{item};</div>
                  ))}
                </>
              ),
            });
          } else {
            Modal.confirm({
              title: '拓扑关系发布失败，是否重新发布？',
              okText: '是',
              cancelText: '否',
              onOk: () => {
                onPublishTree();
              },
              onCancel: () => {},
            });
          }
        }
      });
    }
  };

  const exportPng = () => {
    if (graphRef.current) {
      const container = document.getElementsByClassName('x6-graph-svg')[0];
      if (container) {
        htmlToImage
          .toPng(container as HTMLElement, {
            backgroundColor: 'black',
          })
          .then(dataUrl => {
            DataUri.downloadDataUri(dataUrl, `${dispatchInfo?.name}.png`);
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
      }
    }
  };

  // 注册节点
  registerCustomerNode(
    graphRef.current,
    allNodeData.current,
    gatewayList.current,
    fcsList.current,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.page_box}>
      <FormContent
        style={{
          minHeight: 'calc(100vh - 128px)',
          overflowY: 'hidden',
          padding: '20px 20px 0px 20px',
          position: 'relative',
        }}
        title={
          <div>
            <span className={styles.topTitle}>{dispatchInfo?.name ?? '--'}</span>
            <Tooltip title="编辑状态下点击加号进行拓扑图创建，如有多个电网接入点，请点击下方“添加电网侧”进行创建">
              <InfoCircleOutlined className={styles.infoIconSty} />
            </Tooltip>

            <Space split={<Divider type="vertical" />} className={styles.topTags}>
              <Tooltip title={`所属调度组:${dispatchInfo?.dispatchGroupName ?? '--'}`}>
                <p className={styles.hint}>所属调度组:{dispatchInfo?.dispatchGroupName ?? '--'}</p>
              </Tooltip>
              <Tooltip title={`关联UET:${dispatchInfo?.uetVos?.map(i => i.name).join() ?? '--'}`}>
                <p className={styles.hint}>关联UET:{dispatchInfo?.uetVos?.map(i => i.name).join() ?? '--'}</p>
              </Tooltip>
              <Tooltip title={`关联拓扑:${dispatchInfo?.linkVo?.name ?? '--'}`}>
                <p className={styles.hint}>关联拓扑:{dispatchInfo?.linkVo?.name ?? '--'}</p>
              </Tooltip>
            </Space>
          </div>
        }
        extraContent={
          <Space size={8}>
            <Button type="primary" onClick={() => exportPng()}>
              导出
            </Button>
            <Button type="primary" onClick={() => onPublishTree()}>
              发布
            </Button>
            <Button type="primary" onClick={() => onSaveTree()}>
              存草稿
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: null,
                  content: <div>是否放弃所有未保存信息并返回列表？</div>,
                  onOk: () =>
                    navigate(
                      `/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/edit/${url_dispatchingGroupId}/schedulingTopology`
                    ),
                });
              }}
            >
              取消
            </Button>
          </Space>
        }
      >
        <div className={styles.btnAndCanvasBgSty}>
          {viewType ? (
            <div className={styles.topBtnSty}>
              <Space size={10}>
                <Button onClick={() => setViewType(undefined)}>退出预览</Button>
              </Space>
            </div>
          ) : (
            <div className={styles.topBtnSty}>
              <Space size={8}>
                <Button type="primary" onClick={() => setViewType(viewTypeEnum.ENERGYFLOWTOPO)}>
                  能量拓扑预览
                </Button>
                <Button type="primary" onClick={() => setViewType(viewTypeEnum.NETWORK)}>
                  组网预览
                </Button>
              </Space>
            </div>
          )}
          <div className={styles.canvasWapper}>
            <div className={styles.helloworldApp}>
              <div
                className={styles.appContent}
                ref={d => {
                  if (d) {
                    refContainer.current = d;
                  }
                }}
              />
            </div>
          </div>
        </div>
        {canvasLoading && <CreatingModal loading={canvasLoading} content={'画布加载中'} />}
        {loading && <CreatingModal loading content={'拓扑关系发布中'} />}
        {!openBottomMemu && (
          <div
            className={styles.openBottomMemuSty}
            // style={{ bottom: openBottomMemu ? 300 : 0 }}
            onClick={() => setOpenBottomMemu(!openBottomMemu)}
          >
            <div>
              展开常用配置菜单
              <DoubleRightOutlined rotate={270} />
            </div>
          </div>
        )}
        <Drawer
          placement="bottom"
          mask={false}
          closable={false}
          height={250}
          onClose={() => setOpenBottomMemu(false)}
          open={openBottomMemu}
          getContainer={false}
          className={styles.drawSty}
        >
          <div className={styles.drawShowBtnSty} onClick={() => setOpenBottomMemu(!openBottomMemu)}>
            <span>
              收起常用配置菜单
              <DoubleRightOutlined rotate={90} />
            </span>
          </div>
          <div className={styles.memuTipSty}>
            <InfoCircleOutlined style={{ color: '#FAAD14', marginRight: 4 }} />
            请点击
            <div className={styles.purpleBtnSty}>紫色按钮</div>
            创建画布元素， 点击
            <div className={styles.blueBtnSty}>蓝色按钮</div>
            打开平台相关页面创建编辑实体对象
          </div>
          <Descriptions layout="horizontal" column={1}>
            <Descriptions.Item label="拓扑管理" labelStyle={{ color: 'rgba(255,255,255,0.65)', alignItems: 'center' }}>
              <div
                className={styles.linkNodeBtn}
                onClick={() => {
                  addGridSide(allNodeData.current, graphRef.current);
                  setOpenBottomMemu(false);
                }}
              >
                <PlusCircleOutlined style={{ marginRight: 4 }} />
                添加电网侧
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/energy/basic/uet`, '_blank');
                }}
              >
                创建能源拓扑单元
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/dc/circuit/circuitManage`, '_blank');
                }}
              >
                创建回路
                <DoubleRightOutlined />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="资产管理" labelStyle={{ color: 'rgba(255,255,255,0.65)', alignItems: 'center' }}>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.DMEPLATFORMSORIGIN}/energy-storage/basic/bsa`, '_blank');
                }}
              >
                储能站建模
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.DMEPLATFORMSORIGIN}/pv-storage/basic/manage`, '_blank');
                }}
              >
                光伏站建模
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.DMEPLATFORMSORIGIN}/device/chargingStation/management`, '_blank');
                }}
              >
                充电站建模
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/device/manage/device`, '_blank');
                }}
              >
                设备建模
                <DoubleRightOutlined />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="网络管理" labelStyle={{ color: 'rgba(255,255,255,0.65)', alignItems: 'center' }}>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/device/config/gateway`, '_blank');
                }}
              >
                创建网关
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/device/fcs/list`, '_blank');
                }}
              >
                创建就地控制器
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/device/networking/networkingList`, '_blank');
                }}
              >
                创建组网
                <DoubleRightOutlined />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="组织管理" labelStyle={{ color: 'rgba(255,255,255,0.65)', alignItems: 'center' }}>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.SELECTSTAFFORIGIN}/system/customer/info`, '_blank');
                }}
              >
                组织管理
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.IOTPLATFORMORIGIN}/device/ou/production-unit`, '_blank');
                }}
              >
                运营单元管理
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.SELECTSTAFFORIGIN}/system/rolePer/role`, '_blank');
                }}
              >
                角色管理
                <DoubleRightOutlined />
              </div>
              <div
                className={styles.quickJumpBtn}
                onClick={() => {
                  window.open(`${window.SELECTSTAFFORIGIN}/system/customer/staff`, '_blank');
                }}
              >
                员工管理
                <DoubleRightOutlined />
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Drawer>
      </FormContent>
      {renderEachModal()}
      {messageContextHolder}
    </Wrapper>
  );
};

export default SchedulingTopologyX6;
