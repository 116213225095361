import React, { FC, useEffect, useState } from 'react';
import {
  Form,
  useBreadcrumbRoutes,
  Wrapper,
  usePaging,
  Table,
  Paging,
  Input,
  Select,
  EllipsisSpan,
  Tag,
  Modal,
  Dropdown,
  CustomFilter,
  useUpdate,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from 'ahooks';
import { getOrganization } from '@/api/customer-user';
import { tiledArray } from '@/pages/EnergyDispatchManagement/List/utils';
import {
  apiV2BsaPageBsaV2Post,
  apiV2BsaSoftVersionListPost,
  apiV2BsaStrategyVersionListPost,
  apiV2BsaUpdateControlConfigPost,
  apiV2BsaUpdateEPricePost,
  V2BsaPageBsaV2PostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { DeliveryButton, TextColor } from './components/DeliveryButton';
import { DropdownButtonColor, DropdownButtonCommon } from './components/DropdownButton';

interface SearchParams {
  mcid?: string;
  name?: string;
  strategyVersion?: string;
  softwareVersion?: string;
}

type DataListItem = Exclude<V2BsaPageBsaV2PostResponse['list'], undefined>[number];

enum NetStateEnum {
  OFFLINE = 0,
  ONLINE = 1,
}

const formateUnitForPower = (num?: number) => {
  if (!num) return '--';
  return num < 0.5 ? `${1000 * num}kW` : `${num}MW`;
};
const formateUnitForCapacity = (num?: number) => {
  if (!num) return '--';
  return num < 1 ? `${1000 * num}kWh` : `${num}MWh`;
};

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const BsaList: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataListItem[]>([]);

  const hasControlConfigEdit = useHasPermission(PermissionsType.B_CONTROL_CONFIGURATION_EDIT);
  const hasStrategy = useHasPermission(PermissionsType.B_CHARGING_AND_DISCHARGING_STRATEGY);
  const hasRuleConfig = useHasPermission(PermissionsType.B_RULE_CONFIG);
  const hasRemoteReset = useHasPermission(PermissionsType.B_BSA_REMOTE_CONTROL_SET);
  const [update, setUpdate] = useUpdate();
  // 组织列表
  const { data: organization } = useRequest(async () => {
    const res = await getOrganization();
    return res ? tiledArray([res]) : [];
  });

  // 软件版本
  const { data: softList } = useRequest(async () => {
    const res = await apiV2BsaSoftVersionListPost();
    return res ? res.list : [];
  });

  // 策略版本
  const { data: strategyList } = useRequest(async () => {
    const res = await apiV2BsaStrategyVersionListPost();
    return res ? res.list : [];
  });

  useEffect(() => {
    setLoading(true);
    apiV2BsaPageBsaV2Post({
      page: pageOffset,
      size: pageSize,
      name: searchParams?.name,
      mcid: searchParams?.mcid,
      strategyVersion: searchParams?.strategyVersion,
      softwareVersion: searchParams?.softwareVersion,
    })
      .then(res => {
        setTotalCount(res.total ?? 0);
        setData(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, searchParams, pageSize, update]);

  const onUpdateEPrice = (id: number) => {
    const modal = Modal.info({
      title: '正在更新电价...',
      footer: null,
      keyboard: false,
    });
    apiV2BsaUpdateEPricePost({ id })
      .then(() => {
        Modal.success({
          title: '电价更新成功！',
        });
        setUpdate();
      })
      .finally(() => {
        modal.destroy();
      });
  };

  const onUpdateControlConfig = (id: number) => {
    const modal = Modal.info({
      title: '正在下发配置...',
      footer: null,
      keyboard: false,
    });
    apiV2BsaUpdateControlConfigPost({ id })
      .then(() => {
        Modal.success({
          title: '配置下发成功！',
        });
        setUpdate();
      })
      .finally(() => {
        modal.destroy();
      });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={(v: SearchParams) => {
        setSearchParams(v);
        setPageOffset(1);
      }}
      onReset={() => {
        form.resetFields();
        setSearchParams(undefined);
        setPageOffset(1);
      }}
    >
      <Form.Item name="mcid" label="所属组织">
        <Select placeholder="请选择" options={organization} />
      </Form.Item>

      <Form.Item name="name" label="储能站名称">
        <Input placeholder="请输入储能站名称" />
      </Form.Item>
      <Form.Item name="softwareVersion" label="软件版本">
        <Select
          placeholder="请选择"
          options={softList?.map(i => ({
            label: i,
            value: i,
          }))}
        />
      </Form.Item>
      <Form.Item name="strategyVersion" label="策略版本">
        <Select
          placeholder="请选择"
          options={strategyList?.map(i => ({
            label: i,
            value: i,
          }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  const dataColumns: ColumnsType<DataListItem> = [
    {
      title: '储能站编号',
      dataIndex: 'serialNumber',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '储能站名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '装机容量',
      dataIndex: 'installedCapacity',
      ellipsis: { showTitle: true },
      render: (v: number) => formateUnitForCapacity(v),
    },
    {
      title: '额定功率',
      dataIndex: 'ratedPower',
      ellipsis: { showTitle: true },
      render: (v: number) => formateUnitForPower(v),
    },
    {
      title: 'EMS连接状态',
      dataIndex: 'netState',
      ellipsis: { showTitle: true },
      render: (v: NetStateEnum) => {
        return v === NetStateEnum.ONLINE ? (
          <Tag color="success" border="solid">
            在线
          </Tag>
        ) : (
          <Tag color="invalid" border="solid">
            离线
          </Tag>
        );
      },
    },
    {
      title: '软件版本',
      dataIndex: 'softwareVersion',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '策略版本',
      dataIndex: 'strategyVersion',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(DATE_FORMAT) : '--'} />,
    },
    {
      title: '最后操作人',
      dataIndex: 'lastUpdateUser',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const actionColumns: ColumnsType<DataListItem> = [
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 300,
      render: (_, record) => (
        <Space size={16}>
          <DeliveryButton
            colorType={record.watchControlConfigColor}
            onClick={() => {
              navigate(`/energy-storage/basic/bsa/templateList?bsaId=${record.id}`);
            }}
          >
            策略模板
          </DeliveryButton>

          {hasStrategy && (
            <DeliveryButton
              colorType={record.strategyColor}
              onClick={() => {
                navigate(`/energy-storage/basic/bsa/strategy/${record.id}`);
              }}
            >
              充放策略
            </DeliveryButton>
          )}
          <Dropdown
            type="link"
            menu={{
              items: [
                {
                  key: 'viewControlConfig',
                  label: (
                    <DropdownButtonColor
                      colorType={record.watchControlConfigColor}
                      onClick={() => {
                        navigate(`/energy-storage/basic/bsa/viewControlConfig/${record.id}`);
                      }}
                    >
                      查看控制配置
                    </DropdownButtonColor>
                  ),
                },
                {
                  key: 'editControlConfig',
                  show: hasControlConfigEdit,
                  label: (
                    <DropdownButtonCommon
                      disabled={!record.hasPublish}
                      onClick={() => navigate(`/energy-storage/basic/bsa/editControlConfig/${record.id}`)}
                    >
                      编辑控制配置
                    </DropdownButtonCommon>
                  ),
                },
              ].filter(item => item.show !== false),
            }}
          >
            控制配置
          </Dropdown>
          <Dropdown
            type="link"
            menu={{
              items: [
                {
                  key: '1',
                  show: true,
                  label: (
                    <DropdownButtonColor
                      onClick={() => onUpdateControlConfig(record.id!)}
                      colorType={record.configUpdateColor}
                    >
                      配置下发
                    </DropdownButtonColor>
                  ),
                },
                {
                  key: '2',
                  show: true,
                  label: (
                    <DropdownButtonColor
                      onClick={() => onUpdateEPrice(record.id!)}
                      colorType={record.updateEPriceColor}
                    >
                      更新电价
                    </DropdownButtonColor>
                  ),
                },
                {
                  key: '3',
                  show: hasRuleConfig,
                  label: (
                    <DropdownButtonColor
                      onClick={() => navigate(`/energy-storage/basic/bsa/rulesConfig/${record.id}`)}
                      colorType={record.ruleConfigColor}
                    >
                      规则配置
                    </DropdownButtonColor>
                  ),
                },
                {
                  key: '4',
                  show: hasRemoteReset,
                  label: (
                    <DropdownButtonColor
                      onClick={() => navigate(`/energy-storage/basic/bsa/remoteReset/${record.id}`)}
                      colorType={record.ruleConfigColor}
                    >
                      远控复位
                    </DropdownButtonColor>
                  ),
                },
              ].filter(item => item.show !== false),
            }}
          >
            {}
            <span
              className={record.configUpdateColor === TextColor.RED ? styles.text_error_color : styles.text_error_none}
            >
              远控更新
            </span>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const columns = [...dataColumns, ...actionColumns];

  return (
    <Wrapper className={styles.wrap} routes={breadcrumbRoutes?.routes} filters={filters}>
      <div className={styles.tipWrap}>
        <InfoCircleOutlined className={styles.tipIcon} />
        <span>
          如果uedt没有发布过版本，则不允许操作储能站策略任何配置。如果控制配置未配置完整，则不允许编辑策略模板、进行策略投放
        </span>
      </div>
      <Table
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={data}
        loading={loading}
      ></Table>
      <Paging pagingInfo={pagingInfo}></Paging>
    </Wrapper>
  );
};

export default BsaList;
