import { Graph, Node } from '@antv/x6';
import { Cascader, Dropdown, Popconfirm, Tag, Tooltip } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { FcsList, GateWayList, nodeElementLinkTree, NodeType, TreeNode } from '../../type';
import styles from './index.module.scss';
import energyStorage from '../../imgs/energyStorage.gif';
import { findDrawIndexByCurrentNode } from '../../utils/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import { CloseOutlined } from '@ant-design/icons';
import { limitByNodes } from '../../utils/nodelimit';

export interface BsaProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  gatewayListRef?: GateWayList[];
  fcsListRef?: FcsList[];
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void;
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  showModalDetail?: (id: number, type: NodeType) => void;
  nodeElementLink?: nodeElementLinkTree[];
}

const Bsa: FC<BsaProps> = props => {
  const {
    node,
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink,
  } = props;
  const [cascaderValue, setCascaderValue] = useState<string[]>();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  const isShowTopBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowTopAddBtn;
  }, [data]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div className={styles.customBsaReactNode} style={{ opacity: data.isShowNetWork ? 0.5 : 1 }}>
      {data?.isShowAllAddDelBtn && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data.value.name}</span>
              及该设备下级所有关联对象吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              NodeType.BSA,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
      {isShowTopBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={dropDownOpen}
          onOpenChange={open => {
            setDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              expandTrigger={'hover'}
              open={dropDownOpen}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'upper',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
                setDropDownOpen(false);
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircle}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}
      <div className={styles.vline}></div>
      <div
        className={styles.cardSty}
        style={{ minHeight: data.isShowNetWork ? 37 : undefined }}
        onClick={() => {
          showModalDetail?.(data.id, NodeType.BSA);
        }}
      >
        {data.isShowNetWork ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              marginLeft: 14,
            }}
          >
            <img style={{ width: 20, height: 20, marginRight: 10 }} src={energyStorage} alt="" />
            <Tooltip title={data.value.name}>
              <div className={styles.itemControlobjContentSty} style={{ textAlign: 'unset' }}>
                {data.value.name}
              </div>
            </Tooltip>
          </div>
        ) : (
          <>
            {data.value.isPart && (
              <div className={styles.tagSty}>
                <Tag color="green">部分</Tag>
              </div>
            )}
            <img style={{ width: 40, height: 40, marginTop: 6 }} src={energyStorage} alt="" />
            <Tooltip title={data.value.name}>
              <div className={styles.itemControlobjContentSty}>{data.value.name}</div>
            </Tooltip>
            <div className={styles.FcsPcsInfo}>
              <div className={styles.pcsInfoSty}>PCS{data.value.pcsRate}</div>
            </div>
          </>
        )}

        {/* {(data.value.controlList ?? []).length !== 0 && <div className={styles.dividerSty} />}

        <div className={styles.controlObjSty} style={{ height: (data.value.controlList ?? []).length * 30 }}>
          {(data.value.controlList ?? []).map((item: any) => (
            <div key={item.id} className={styles.itemControlobjSty}>
              <Tooltip title={item.name}>
                <div className={styles.itemControlobjContentSty} style={{ textAlign: 'unset', paddingLeft: 8 }}>
                  {item.name}
                </div>
              </Tooltip>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Bsa;
