import React, { Key, useEffect, useState } from 'react';

import styles from './index.module.scss';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import {
  apiV2DeviceAgcListPost,
  apiV2DispatchUetStructPublishPvDetailPost,
  apiV2DispatchUetStructPvDeletePost,
  apiV2DispatchUetStructPvDetailPost,
  apiV2DispatchUetStructPvEditPost,
  apiV2DispatchUetStructPvGridConnectionPointPost,
  apiV2DispatchUetStructPvPvInverterPost,
  apiV2DispatchUetStructPvPvStationPost,
  apiV2DispatchUetStructPvSavePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import {
  GridConnectionLevel,
  GridConnectionLevelLabel,
  NodeType as NodeTypeClass,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import { NodeType, TreeNode, allInfoProps } from '@/pages/SchedulingTopology/type';
import { findTopGridAccessPointData } from '../EnergyStorageStation/utils';
import { Button, Checkbox, Form, Modal, Select } from '@maxtropy/components';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  allNodeData?: TreeNode[];
}
const PhotovoltaicStationModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  allNodeData,
}) => {
  const [form] = Form.useForm();
  const [gridAccessPointId, setGridAccessPointId] = useState<Key>(); // 电网接入点id
  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const dispatchingGroup = urlSearch.get('dispatchingGroup'); // 调度组id

  // 光伏站
  const pvStationId = Form.useWatch('pvStationId', form);

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  // 光伏站list
  const { data: pvList } = useRequest(
    async () => {
      if (isNil(topologyId) || !open) return Promise.resolve([]);
      const uetList = await apiV2DispatchUetStructPvPvStationPost({ uetStructId: +topologyId, gridAccessPointId });
      if (uetList) {
        return uetList.list;
      }
    },
    { ready: !!gridAccessPointId && !!topologyId, refreshDeps: [gridAccessPointId, topologyId] }
  );

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return;
      setIsDetail(true);
      let res;
      if (!isNil(version)) {
        res = await apiV2DispatchUetStructPublishPvDetailPost({ id, version });
      } else {
        res = await apiV2DispatchUetStructPvDetailPost({ id });
      }

      if (res) {
        setIconVisible(true);
        setTitle(res.pvStationDto?.name);
        form.setFieldsValue({
          ...res,
          pvStationId: res.pvStationDto?.id,
          hasControlItem: res.hasControlItem ?? false,
          gridConnectionPointIdList:
            res.gridConnectionPointSimpleVoList?.filter(item => item.hasChoose)?.map(item => item.id) ?? [],
          pvInverterIds: res.pvInverterDtos?.map(item => item.id),
          agcDeviceIds: res.agcDeviceVo?.map(item => item.id),
        });
        return res;
      }
    },
    { refreshDeps: [id, open, version] }
  );

  // 并网点list
  const { data: gridConnectionPointOptions } = useRequest(
    async () => {
      if (isNil(pvStationId) || !open) return Promise.resolve([]);

      const res = await apiV2DispatchUetStructPvGridConnectionPointPost({
        id: +pvStationId,
        dispatchGroupId: +dispatchingGroup!,
        dispatchUetStructId: +topologyId!,
      });
      if (res && res.list) {
        // 当只有一个的时候默认选择一个且禁用
        if (res.list?.length === 1) {
          if (!res?.list[0].disable) {
            form.setFieldsValue({
              gridConnectionPointIdList: [res.list?.[0].id],
            });
          }
        }

        return res.list.map(item => {
          // 如果详情里面返回里面有hasChoose,则表示已经选择 不做禁用。
          // 如果详情里面返回里面没有hasChoose,则表示未选择 根据list里面的状态去判断禁用
          const hasChoose = detail?.gridConnectionPointSimpleVoList?.find(ele => ele.id === item.id)?.hasChoose;
          const isDisabled = hasChoose ? false : item.disable;

          return {
            label: item.name
              ? item.name + ' ' + '电压等级' + GridConnectionLevelLabel[item.level as GridConnectionLevel]
              : '',
            value: item.id ?? '',
            disabled: isDisabled,
          };
        });
      }
    },
    {
      refreshDeps: [pvStationId, open],
    }
  );

  // 通过当前id与type查找电网接入点id
  useEffect(() => {
    if (detail) {
      if (allNodeData) {
        const gridAccessPointData = findTopGridAccessPointData(allNodeData, detail.id, NodeType.PVA);
        setGridAccessPointId(gridAccessPointData?.id);
      }
    } else {
      if (allInfo) {
        const gridAccessPointData = findTopGridAccessPointData(
          allInfo.allNodeData,
          allInfo.node.getData().id,
          allInfo.node.getData().element
        );
        setGridAccessPointId(gridAccessPointData?.id);
      }
    }
  }, [allInfo, detail, allNodeData]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '光伏站'}
            delMethod={apiV2DispatchUetStructPvDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.SOURCE}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructPvSavePost}
            updateMethod={apiV2DispatchUetStructPvEditPost}
            onCancel={onCancel}
            getFinishedValues={val => getFinishedValues?.(val)}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="pvStationId" label="光伏站" rules={[{ required: true, message: '请选择光伏站' }]}>
              <Select
                placeholder="请选择"
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
                disabled={isDetail}
                options={pvList}
                allowClear
              />
            </Item>

            <Item
              name="gridConnectionPointIdList"
              label="关联并网点"
              rules={[{ required: true, message: '请选择关联并网点' }]}
            >
              <Checkbox.Group
                className={styles.checkBox}
                options={gridConnectionPointOptions}
                disabled={isDetail || gridConnectionPointOptions?.length === 1}
              />
            </Item>

            {/* <Item name="hasControlItem" initialValue={false} noStyle />

            <Checkbox
              style={{
                marginBottom: 10,
              }}
              checked={hasControlItem}
              disabled={isDetail}
              onChange={e => {
                form.setFieldsValue({
                  hasControlItem: e.target.checked,
                });
                form.resetFields(['pvInverterIds', 'agcDeviceIds']);
              }}
            >
              添加控制对象
            </Checkbox>

            {hasControlItem && (
              <>
                <Item name="pvInverterIds" label="逆变器">
                  <Select
                    placeholder="请选择"
                    options={inverterOptions}
                    mode="multiple"
                    disabled={isDetail}
                    allowClear
                  />
                </Item>

                <Item name="agcDeviceIds" label="AGC">
                  <Select placeholder="请选择" options={agcOptions} mode="multiple" disabled={isDetail} allowClear />
                </Item>
              </>
            )} */}

            <Item name="id" hidden />

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['gridConnectionPointIdList']}>
                      {({ getFieldValue }) => {
                        const gridConnectionPointIdList = getFieldValue('gridConnectionPointIdList');

                        if (gridConnectionPointIdList?.length < gridConnectionPointOptions?.length!) {
                          return <div className={styles.part}>部分</div>;
                        }
                      }}
                    </Item>

                    <Item noStyle dependencies={['pvStationId']}>
                      {({ getFieldValue }) => {
                        const pvStationId = getFieldValue('pvStationId');
                        const pvName = pvList?.find(item => item.id === pvStationId)?.name;

                        return <div>{pvName}</div>;
                      }}
                    </Item>

                    <Item noStyle dependencies={['gridConnectionPointIdList']}>
                      {({ getFieldValue }) => {
                        const gridConnectionPointIdList = getFieldValue('gridConnectionPointIdList');

                        return (
                          <div className={styles.parallelBranch}>{`并网点${gridConnectionPointIdList?.length ?? 0}/${
                            gridConnectionPointOptions?.length ?? 0
                          }`}</div>
                        );
                      }}
                    </Item>

                    {/* {hasControlItem && (
                      <>
                        <div className={styles.dashed} />

                        <div className={styles.controlObject}>
                          <Item noStyle dependencies={['pvInverterIds', 'agcDeviceIds']}>
                            {({ getFieldValue }) => {
                              const pvInverterIds = getFieldValue('pvInverterIds');
                              const agcDeviceIds = getFieldValue('agcDeviceIds');

                              const pvInverterNames = pvInverterIds?.map((id: number) => {
                                return inverterOptions?.find(item => item.value === id)?.label;
                              });
                              const agcDeviceNames = agcDeviceIds?.map((id: number) => {
                                return agcOptions?.find(item => item.value === id)?.label;
                              });

                              return (
                                <>
                                  {pvInverterNames?.map((name: string, index: number) => (
                                    <div className={styles.controlObjectIcon} key={`${name}_${index}`}>
                                      {name}
                                    </div>
                                  ))}
                                  {agcDeviceNames?.map((name: string, index: number) => (
                                    <div className={styles.controlObjectIcon} key={`${name}_${index}`}>
                                      {name}
                                    </div>
                                  ))}
                                </>
                              );
                            }}
                          </Item>
                        </div>
                      </>
                    )} */}
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PhotovoltaicStationModal;
