import {
  BusBarCircleNumber,
  FcsList,
  GateWayList,
  NodeData,
  NodeType,
  TreeNode,
  busbarNeedExtendsMapArr,
} from '../type';
import { Graph, Node } from '@antv/x6';
import { findAllDataSplitByItemBusBar, loopTreeTofindId, updateChildrenPId } from './utils';
import { cloneDeep, isNil } from 'lodash-es';
import {
  ADDCIRCLE_RADIUS,
  addedNodeValue,
  changeXPosition,
  changeYPosition,
  downLeftNodes,
  EACH_BUSBAR_LENGTH,
  findNodeByIdAndElement,
  getChildrenIds,
  getDownAddNodePosition,
  getNeedRemoveData,
  getNodePositionAndSize,
  getSameGradeLevelNeedAddHeight,
  handleAddNodeByLeft,
  handleAddNodeByRight,
  hasTopAddNode,
  isMeters,
  loopTreeTofindParent,
  moveAddedNodeAndChildrenNodes,
  portGroups,
  reduceNodeTopBtn,
  removeAuxDeviceWithPisBusbarOnlyOne,
  removeAuxiliaryDevicePosition,
  removeGridSideAndChildren,
  removeTreeData,
  syncAllBsaNodes,
} from './graph';
import { LinkStatus } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';

// 画图主逻辑
export function createNodeAndDrawCanvas(
  upperOrDown: string,
  allNodeData: TreeNode[],
  node: Node<Node.Properties>,
  graph: Graph,
  addNodeType: NodeType,
  gatewayList: GateWayList[],
  fcsList: FcsList[],
  values: any, // 创建成功后端返回
  circleIndex?: number,
  circleArr?: BusBarCircleNumber[]
): {
  allNodeDataTemp: TreeNode[];
} {
  const currentNodeData: NodeData = node.getData();
  // 分为两种情况，当前节点是母线，当前节点是其他
  if (currentNodeData.element === NodeType.BUS_BAR) {
    // 母线
    const allNodeDataTemp = busBarAddNodeToTree(
      addNodeType,
      allNodeData,
      currentNodeData,
      gatewayList,
      fcsList,
      values,
      circleIndex,
      circleArr
    );
    drawNodeUnderBusBar(node, graph, allNodeDataTemp, addNodeType, values, circleIndex);
    return { allNodeDataTemp: allNodeDataTemp };
  } else {
    if (upperOrDown === 'down') {
      // 其他向下新增
      const allNodeDataTemp = otherDownAddToTree(
        addNodeType,
        allNodeData,
        gatewayList,
        fcsList,
        values,
        currentNodeData
      );
      drawOtherNodeByDownAdd(node, graph, allNodeDataTemp, values, addNodeType);
      return { allNodeDataTemp: allNodeDataTemp };
    } else {
      // 其他向上新增
      const allNodeDataTemp = elecUpperAddToTree(
        addNodeType,
        allNodeData,
        gatewayList,
        fcsList,
        values,
        currentNodeData
      );
      drawCanvasElecUpperAdd(node, graph, allNodeDataTemp, values, addNodeType);
      return { allNodeDataTemp: allNodeDataTemp };
    }
  }
}

// 其他向下新增（树结构）
export function otherDownAddToTree(
  addNodeType: NodeType,
  allNodeData: TreeNode[],
  gatewayList: GateWayList[],
  fcsList: FcsList[],
  values: any,
  currentNodeData: NodeData
): TreeNode[] {
  if (addNodeType === NodeType.GATEWAY) {
    gatewayList.push({
      id: values.id,
      element: NodeType.GATEWAY,
    });
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    fcsList.push({
      id: values.id,
      element: NodeType.FCS_CONTROLLER,
    });
    if (values.gatewayList && values.gatewayList.length > 0) {
      values.gatewayList.forEach((item: any) => {
        gatewayList.push({
          id: item.gatewayId,
          element: NodeType.GATEWAY,
        });
      });
    }
  } else {
    // 其他向下新增其他,本节点下的子节点直接替换
    const findItem = loopTreeTofindId(allNodeData, currentNodeData.id, currentNodeData.element);
    const tempChildren = cloneDeep(findItem?.children ?? []);
    // 修改children的parentId为新节点的parentId
    updateChildrenPId(tempChildren, values.id);
    findItem?.children.splice(0, (tempChildren ?? []).length, {
      id: values.id,
      element: addNodeType,
      parentId: findItem.id as number,
      children: tempChildren,
    });
  }

  // 直接修改的原数据
  return allNodeData;
}

// 其他向下新增画图
export function drawOtherNodeByDownAdd(
  node: Node<Node.Properties>,
  graph: Graph,
  allNodeData: TreeNode[],
  values: any,
  addNodeType: NodeType
) {
  let { x, y, height, width } = getNodePositionAndSize(node);
  const currentNodeData: NodeData = node.getData();
  const findParentItem = loopTreeTofindParent(allNodeData, currentNodeData.id, currentNodeData.element);

  // 向下新增的是网关或者fcs
  if (addNodeType === NodeType.GATEWAY) {
    graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: x + 120,
      y: y,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: values.id,
        element: addNodeType,
        value: {
          id: values?.id,
          edgeGatewayId: values?.edgeGatewayId,
          netWorkId: values?.networkId,
          name: values?.name,
          serialNumber: values?.serialNumber,
        },
      },
    });
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: x + 120,
      y: y,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: values.id,
        element: addNodeType,
        value: {
          id: values?.id,
          gatewayId: values?.fcsInfo?.edgeGatewayId,
          netWorkId: values?.fcsInfo?.edgeNetworkId,
          name: values?.fcsInfo?.name,
          serialNumber: values?.fcsInfo?.serialNumber,
        },
      },
    });
  } else {
    // 向下新增其他
    // 新增节点的x,y
    const { x: addNodeX, y: addNodeY } = getDownAddNodePosition(
      x,
      y,
      height,
      width,
      currentNodeData.element,
      addNodeType
    );

    // 如果新增的是电网接入点
    if (addNodeType === NodeType.GRID_ACCESS_POINT) {
      // 在电网侧显示电网接入点KV
      const findNode = findNodeByIdAndElement(graph, currentNodeData.id, currentNodeData.element);
      findNode?.updateData({
        isShowBottomAddBtn: false,
        value: {
          voltageLevel: values.voltageLevel ?? '',
          summaryPointName: values.summaryPointName ?? '电网侧',
        },
      });
    }
    // 数据values
    const nodeDataValues = addedNodeValue(addNodeType, values);

    // 母线的左边还是右边新增元素
    const addedAllNodes = graph.getNodes();
    const findParentItemNode = addedAllNodes.find(
      i => i.getData().id === findParentItem?.id && i.getData().element === findParentItem?.element
    );
    const leftOrRight = findParentItemNode?.getData()?.busbarNeedExtendsMap?.leftOrRight;

    // 创建节点
    const addedNode = graph.addNode({
      shape: addNodeType,
      x: addNodeX,
      y: addNodeType === NodeType.BUS_BAR ? addNodeY + 10 : addNodeY,
      ports: portGroups,
      data: {
        disableMove: addNodeType === NodeType.BUS_BAR ? true : false,
        id: values.id,
        parentId: currentNodeData.id,
        element: addNodeType,
        value: nodeDataValues,
        // 向左还是向右
        busbarNeedExtendsMap: {
          leftOrRight: leftOrRight ?? undefined,
        },
        isShowAllAddDelBtn: true, // 展示加号与删除
        isShowTopAddBtn: hasTopAddNode(addNodeType) ? false : true,
        isShowBottomAddBtn: true,
      },
    });
    // case：
    // 1.如果创建的是bsa，那么同步更新其他bsa
    if (addNodeType === NodeType.BSA) {
      syncAllBsaNodes(graph, nodeDataValues);
    }
    // 因为需要children所以找到该节点在树中是哪一个
    const findItem = loopTreeTofindId(allNodeData, values.id, addNodeType);

    if (findItem?.children && findItem?.children.length > 0 && findItem?.children[0].element === NodeType.BUS_BAR) {
      const findNode = findNodeByIdAndElement(graph, findItem?.children[0].id, findItem?.children[0].element);
      const edges = graph.getConnectedEdges(node, { outgoing: true }); // 返回输出边

      const busbarNeedExtendsMapTemp: busbarNeedExtendsMapArr = findNode?.getData().busbarNeedExtendsMap;
      const number = busbarNeedExtendsMapTemp.circleArr.filter(i => i.circleNumber < 0).length;

      graph.removeEdge(edges[0]);
      findNode &&
        addLinkEdge(
          false,
          graph,
          addedNode,
          findNode,
          isMeters(addNodeType) ||
            addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
            addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
            addNodeType === NodeType.OTHER_TRANSVERSE
            ? -91
            : 1,
          undefined,
          1 + number * EACH_BUSBAR_LENGTH,
          undefined
        );
    }
    // 2.如果添加的是母线
    if (addNodeType === NodeType.BUS_BAR) {
      getSameGradeLevelNeedAddHeight(addedNode);
      addLinkEdge(
        false,
        graph,
        node,
        addedNode,
        isMeters(currentNodeData.element) ||
          currentNodeData.element === NodeType.ANTI_BACKFLOW_DEVICE ||
          currentNodeData.element === NodeType.UNIVERSAL_JOINT_OPENING ||
          currentNodeData.element === NodeType.OTHER_TRANSVERSE
          ? -91
          : 1,
        undefined,
        1,
        undefined
      );
    }
    // 其余节点向下偏移新增节点的高度
    downLeftNodes(graph, addedNode, findItem, values.id, addNodeType);
    // 防止重叠
    node.addChild(addedNode);

    // 如果是自定义节点，那么如果有关联节点,则有连线
    if (addNodeType === NodeType.OTHER_TRANSVERSE || addNodeType === NodeType.OTHER_VERTICAL) {
      handleConnectLinkNode(graph, addedNode, values, addNodeType);
    }
  }
}

// 母线左右新增节点（树结构）
export function busBarAddNodeToTree(
  addNodeType: NodeType,
  allNodeData: TreeNode[],
  currentNodeData: NodeData,
  gatewayList: GateWayList[],
  fcsList: FcsList[],
  values: any,
  circleIndex?: number,
  circleArr?: BusBarCircleNumber[]
) {
  if (addNodeType === NodeType.GATEWAY) {
    gatewayList.push({
      id: values.id,
      element: NodeType.GATEWAY,
    });
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    fcsList.push({
      id: values.id,
      element: NodeType.FCS_CONTROLLER,
    });
  } else {
    // 母线左右新增其他
    const findItem = loopTreeTofindId(allNodeData, currentNodeData.id, currentNodeData.element);
    const findIndex = circleArr?.findIndex(i => i.circleNumber === circleIndex);

    if (!isNil(findIndex) && !isNil(circleIndex)) {
      if (circleIndex > 0) {
        findItem?.children.splice(findIndex - 1, 0, {
          id: values.id,
          element: addNodeType,
          parentId: findItem.id as number,
          children: [],
        });
      } else {
        findItem?.children.splice(findIndex, 0, {
          id: values.id,
          element: addNodeType,
          parentId: findItem.id as number,
          children: [],
        });
      }
    }
  }
  return allNodeData;
}

// 母线左右新增节点（画布Node）
export function drawNodeUnderBusBar(
  node: Node<Node.Properties>,
  graph: Graph,
  allNodeData: TreeNode[],
  addNodeType: NodeType,
  values: any,
  addCircleIndex?: number
) {
  const { x, y, width } = getNodePositionAndSize(node);
  const currentNodeData: NodeData = node.getData();
  const busbarNeedExtendsMapTemp: busbarNeedExtendsMapArr = node.getData().busbarNeedExtendsMap;
  const findIndex = busbarNeedExtendsMapTemp.circleArr.findIndex(i => i.circleNumber === addCircleIndex);
  if (addNodeType === NodeType.GATEWAY) {
    graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: x + 20 + findIndex * 280,
      y: y,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: values.id,
        element: addNodeType,
        value: {
          id: values?.id,
          edgeGatewayId: values?.edgeGatewayId,
          netWorkId: values?.networkId,
          name: values?.name,
          serialNumber: values?.serialNumber,
        },
      },
    });
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: x + 20 + findIndex * 280,
      y: y,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: values.id,
        element: addNodeType,
        value: {
          id: values?.id,
          gatewayId: values?.fcsInfo?.edgeGatewayId,
          netWorkId: values?.fcsInfo?.edgeNetworkId,
          name: values?.fcsInfo?.name,
          serialNumber: values?.fcsInfo?.serialNumber,
        },
      },
    });
  } else {
    // 根据此值计算母线的长度
    const findItem = loopTreeTofindId(allNodeData, currentNodeData.id, currentNodeData.element);

    // 位置与数值
    const addNodeX = x;
    const addNodeY = y;
    const nodeDataValues = addedNodeValue(addNodeType, values);

    const addedNode = graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: addNodeX,
      y: addNodeY, // 加8是add圆的半径
      data: {
        id: values.id,
        parentId: currentNodeData?.id,
        name: values.name,
        element: addNodeType,
        value: nodeDataValues,
        // 向左还是向右
        busbarNeedExtendsMap: {
          leftOrRight: addCircleIndex! > 0 ? 'right' : 'left',
        },
        isShowTopAddBtn: true,
        isShowBottomAddBtn: true,
        isShowAllAddDelBtn: true,
      },
    });
    // 如果创建的是bsa，那么同步更新其他bsa
    if (addNodeType === NodeType.BSA) {
      syncAllBsaNodes(graph, nodeDataValues);
    }

    // 通过addCircleIndex判断是向左还是向右新增
    const originBusbarNeedExtendsMap = node.getData().busbarNeedExtendsMap;
    const originCircleArr: BusBarCircleNumber[] = originBusbarNeedExtendsMap?.circleArr;
    let originBusbarLength: number = originBusbarNeedExtendsMap?.busbarLength;
    const findIndex = originCircleArr.findIndex(i => i.circleNumber === addCircleIndex);
    if (addCircleIndex! > 0) {
      // 向右新增
      // 处理向右新增节点，母线的变化
      handleAddNodeByRight(node, originCircleArr, originBusbarLength, findIndex, addCircleIndex);
      // 如果有节点关联该母线，则关联的左侧还是右侧需要移动
      const originBusbarNeedExtendsMapTemp = node.getData().busbarNeedExtendsMap;
      const originCircleArrTemp: BusBarCircleNumber[] = originBusbarNeedExtendsMapTemp?.circleArr;

      const numberLess0 = originCircleArrTemp?.filter((i: any) => i.circleNumber < 0).length;
      const numberMore0 = originCircleArrTemp?.filter((i: any) => i.circleNumber > 1).length;
      graph.getNodes().forEach(i => {
        if (
          i.getData()?.value?.associationType === LinkStatus.BusBar &&
          i.getData()?.value?.associationId === node.getData().id
        ) {
          const edges = graph.getConnectedEdges(i, { outgoing: true }); // 返回输出边，修改终点
          // 判断是连接左边还是右边
          let dxs = 0;
          // 连接左侧
          if (i.getData()?.value?.associationNode === 0) {
            // 如果左侧有，那么在左侧加过元素，则起始点为最左侧
            if (numberLess0 > 0) {
              dxs = -EACH_BUSBAR_LENGTH / 2 + ADDCIRCLE_RADIUS;
            } else {
              dxs = -EACH_BUSBAR_LENGTH / 2 - numberLess0 * EACH_BUSBAR_LENGTH + ADDCIRCLE_RADIUS;
            }
          } else {
            // 右侧
            if (numberLess0 > 0) {
              dxs =
                EACH_BUSBAR_LENGTH / 2 +
                numberLess0 * EACH_BUSBAR_LENGTH +
                numberMore0 * EACH_BUSBAR_LENGTH -
                ADDCIRCLE_RADIUS;
            } else {
              dxs = EACH_BUSBAR_LENGTH / 2 + numberMore0 * EACH_BUSBAR_LENGTH - ADDCIRCLE_RADIUS;
            }
          }

          if (edges.length) {
            edges[0].prop('target', {
              cell: node,
              anchor: {
                name: 'bottom',
                args: {
                  dx: dxs,
                },
              },
              connectionPoint: 'anchor',
            }); // 修改终点
          }
        }
      });
    } else {
      // 向左新增
      // 处理向左新增节点，母线的变化
      handleAddNodeByLeft(
        node,
        originCircleArr,
        originBusbarLength,
        allNodeData,
        currentNodeData,
        graph,
        findIndex,
        addCircleIndex
      );
      // 上级母线的变化
      // 只要母线向右新增，上级所有母线整体往右变挪动
      // 步骤，向上查找，找到上面的所有母线，然后使得宽度增长
      // handleTopBusBarByLeft(graph, allNodeData, currentNodeData);

      // 只要是向左新增，上面的节点都得变化(拖动新)
      // 只有第一层母线需要向左移动
      const allData: TreeNode[] = findAllDataSplitByItemBusBar(currentNodeData, allNodeData);
      const ids = (allData ?? []).map(i => {
        return {
          id: i.id,
          element: i.element,
        };
      });
      changeXPosition(graph, ids, EACH_BUSBAR_LENGTH);

      const originBusbarNeedExtendsMapTemp = node.getData().busbarNeedExtendsMap;
      const originCircleArrTemp: BusBarCircleNumber[] = originBusbarNeedExtendsMapTemp?.circleArr;
      const number = originCircleArrTemp.filter(i => i.circleNumber < 0).length;

      const edges = graph.getConnectedEdges(node, { incoming: true }); // 返回输入边

      edges
        .filter(i => i.router?.name === 'orth')[0]
        .prop('target', {
          cell: node,
          anchor: {
            name: 'top',
            args: {
              dx: 1 + number * EACH_BUSBAR_LENGTH,
            },
          },
          connectionPoint: 'anchor',
        }); // 修改终点

      // 如果有节点关联该母线，则关联的左侧还是右侧需要移动
      const numberLess0 = originCircleArrTemp?.filter((i: any) => i.circleNumber < 0).length;
      const numberMore0 = originCircleArrTemp?.filter((i: any) => i.circleNumber > 1).length;
      graph.getNodes().forEach(i => {
        if (
          i.getData()?.value?.associationType === LinkStatus.BusBar &&
          i.getData()?.value?.associationId === node.getData().id
        ) {
          const edges = graph.getConnectedEdges(i, { outgoing: true }); // 返回输出边，修改终点
          // 判断是连接左边还是右边
          let dxs = 0;
          // 连接左侧
          if (i.getData()?.value?.associationNode === 0) {
            // 如果左侧有，那么在左侧加过元素，则起始点为最左侧
            if (numberLess0 > 0) {
              dxs = -EACH_BUSBAR_LENGTH / 2 + ADDCIRCLE_RADIUS;
            } else {
              dxs = -EACH_BUSBAR_LENGTH / 2 - numberLess0 * EACH_BUSBAR_LENGTH + ADDCIRCLE_RADIUS;
            }
          } else {
            // 右侧
            if (numberLess0 > 0) {
              dxs =
                EACH_BUSBAR_LENGTH / 2 +
                numberLess0 * EACH_BUSBAR_LENGTH +
                numberMore0 * EACH_BUSBAR_LENGTH -
                ADDCIRCLE_RADIUS;
            } else {
              dxs = EACH_BUSBAR_LENGTH / 2 + numberMore0 * EACH_BUSBAR_LENGTH - ADDCIRCLE_RADIUS;
            }
          }
          if (edges.length) {
            edges[0].prop('target', {
              cell: node,
              anchor: {
                name: 'bottom',
                args: {
                  dx: dxs,
                },
              },
              connectionPoint: 'anchor',
            }); // 修改终点
          }
        }
      });
    }

    // 本节点与子节点位置移动
    moveAddedNodeAndChildrenNodes(
      addedNode,
      graph,
      addNodeType,
      addNodeX,
      addNodeY,
      width,
      findIndex,
      findItem,
      addCircleIndex
    );
    // 防止重叠
    node.addChild(addedNode);

    // 如果是自定义节点，那么如果有关联节点,则有连线
    if (addNodeType === NodeType.OTHER_TRANSVERSE || addNodeType === NodeType.OTHER_VERTICAL) {
      handleConnectLinkNode(graph, addedNode, values, addNodeType);
    }
  }
}

// 其他向上新增向上新增
export function elecUpperAddToTree(
  addNodeType: NodeType,
  allNodeData: TreeNode[],
  gatewayList: GateWayList[],
  fcsList: FcsList[],
  values: any,
  currentNodeData: NodeData
): TreeNode[] {
  if (addNodeType === NodeType.GATEWAY) {
    gatewayList.push({
      id: values.id,
      element: NodeType.GATEWAY,
    });
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    fcsList.push({
      id: values.id,
      element: NodeType.FCS_CONTROLLER,
    });
    if (values.gatewayList && values.gatewayList.length > 0) {
      values.gatewayList.forEach((item: any) => {
        gatewayList.push({
          id: item.gatewayId,
          element: NodeType.GATEWAY,
        });
      });
    }
  } else {
    // 其他向上新增其他
    const findItem = loopTreeTofindId(allNodeData, currentNodeData.id, currentNodeData.element);
    const findParentItem = loopTreeTofindParent(allNodeData, currentNodeData.id, currentNodeData.element);
    const findOriginBusIndex = findParentItem?.children.findIndex(i => i.id === currentNodeData.id);
    const tempChildren = cloneDeep(findItem ? [findItem!] : []);
    // 修改children的parentId为新节点的parentId
    updateChildrenPId(tempChildren, values.id);
    findParentItem?.children.splice(findOriginBusIndex!, 1, {
      id: values.id,
      element: addNodeType,
      parentId: findParentItem.id as number,
      children: tempChildren,
    });
  }
  return allNodeData;
}

// 其他向上新增画图
export function drawCanvasElecUpperAdd(
  node: Node<Node.Properties>,
  graph: Graph,
  allNodeData: TreeNode[],
  values: any,
  addNodeType: NodeType
) {
  let { x, y } = getNodePositionAndSize(node);
  if (addNodeType === NodeType.GATEWAY) {
    graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: x + 120,
      y: y,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: values.id,
        element: addNodeType,
        value: {
          id: values?.id,
          gatewayId: values?.edgeGatewayId,
          netWorkId: values?.netWorkId,
          name: values?.name,
          serialNumber: values?.serialNumber,
        },
      },
    });
  } else if (addNodeType === NodeType.FCS_CONTROLLER) {
    graph.addNode({
      shape: addNodeType, // 指定使用何种图形，默认值为 'rect'
      x: x + 120,
      y: y,
      ports: portGroups,
      data: {
        isShowAllAddDelBtn: true,
        disableMove: true,
        id: values.id,
        element: addNodeType,
        value: {
          id: values?.id,
          gatewayId: values?.fcsInfo?.edgeGatewayId,
          netWorkId: values?.fcsInfo?.edgeNetworkId,
          name: values?.fcsInfo?.name,
          serialNumber: values?.fcsInfo?.serialNumber,
        },
      },
    });
  } else {
    // 其他向上新增其他
    const addedAllNodes = graph.getNodes();
    const currentNodeData: NodeData = node.getData();
    // 当前节点的上一层节点
    const findCurrentItem = loopTreeTofindParent(allNodeData, currentNodeData.id, currentNodeData.element);
    const findCurrentItemNode = addedAllNodes.find(
      i => i.getData().id === findCurrentItem?.id && i.getData().element === findCurrentItem?.element
    );
    const leftOrRight = findCurrentItemNode?.getData()?.busbarNeedExtendsMap?.leftOrRight;

    // 新增节点的上一层节点
    const findParentItem = loopTreeTofindParent(allNodeData, values.id, addNodeType);
    const findChildNode = addedAllNodes.find(i => i.getData().id === values.id && i.getData().element === addNodeType);
    const findParentNode = addedAllNodes.find(
      i => i.getData().id === findParentItem?.id && i.getData().element === findParentItem?.element
    );

    let positionX: number = 0;
    if (
      isMeters(currentNodeData.element) ||
      currentNodeData.element === NodeType.ANTI_BACKFLOW_DEVICE ||
      currentNodeData.element === NodeType.UNIVERSAL_JOINT_OPENING ||
      currentNodeData.element === NodeType.OTHER_TRANSVERSE
    ) {
      if (
        isMeters(addNodeType) ||
        addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
        addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
        addNodeType === NodeType.OTHER_TRANSVERSE
      ) {
        positionX = x!;
      } else {
        positionX = x - 80 + 8; // 圆圈的宽度
      }
    } else {
      if (
        isMeters(addNodeType) ||
        addNodeType === NodeType.ANTI_BACKFLOW_DEVICE ||
        addNodeType === NodeType.UNIVERSAL_JOINT_OPENING ||
        addNodeType === NodeType.OTHER_TRANSVERSE
      ) {
        positionX = x! + 80 - 8;
      } else {
        positionX = x;
      }
    }

    // 数据层
    const value = addedNodeValue(addNodeType, values);

    const addedNode = graph.addNode({
      shape: addNodeType,
      x: positionX,
      y,
      data: {
        id: values.id,
        element: addNodeType,
        isShowAllAddDelBtn: true, // 展示加号与删除
        value,
        // 向左还是向右
        busbarNeedExtendsMap: {
          leftOrRight: leftOrRight ?? undefined,
        },
        isShowTopAddBtn: true,
        isShowBottomAddBtn: true,
      },
    });

    // 如果创建的是bsa，那么同步更新其他bsa
    if (addNodeType === NodeType.BSA) {
      syncAllBsaNodes(graph, value);
    }

    // 如果向上新增，上节点有下加号，则隐藏本节点的上加号
    // 找到新增的那个节点实体
    if (hasTopAddNode(currentNodeData.element)) {
      node.updateData({
        isShowTopAddBtn: false,
      });
    }

    // 向上新增addChild
    if (findParentItem?.element === NodeType.BUS_BAR) {
      findParentNode?.addChild(addedNode);
    }
    if (findChildNode) {
      findParentNode?.addChild(findChildNode);
    }

    const addedNodeSize = addedNode.getSize();
    const ids = getChildrenIds(findCurrentItem?.children ?? []);
    changeYPosition(graph, ids, addedNodeSize.height);

    // 如果是自定义节点，那么如果有关联节点,则有连线
    if (addNodeType === NodeType.OTHER_TRANSVERSE || addNodeType === NodeType.OTHER_VERTICAL) {
      handleConnectLinkNode(graph, addedNode, values, addNodeType);
    }
  }
}

// 删除元素
export function removeNode(
  graph: Graph,
  allNodeData: TreeNode[],
  type: NodeType,
  id?: number,
  drawIndex?: number,
  gateWayList?: GateWayList[],
  fcsList?: FcsList[]
) {
  // 先删除画图，再删除数据
  removeNodeCanvas(graph, cloneDeep(allNodeData), type, id, drawIndex);
  // 删除数据
  return removeTreeData(allNodeData, type, id, gateWayList, fcsList, drawIndex);
}

// 删除元素画图
export function removeNodeCanvas(
  graph: Graph,
  allNodeData: TreeNode[],
  type: NodeType,
  id?: number,
  drawIndex?: number
) {
  // 封装需要用到的数据方法
  const { findItem, findParentItem, tempChildren, addedAllNodes, findCurrentNode } = getNeedRemoveData(
    graph,
    allNodeData,
    type,
    id
  );
  if (type === NodeType.GRID_SIDE) {
    // 删除电网侧以及它剩下的所有
    removeGridSideAndChildren(graph, allNodeData, drawIndex);
  } else if (
    isMeters(type) ||
    type === NodeType.ANTI_BACKFLOW_DEVICE ||
    type === NodeType.UNIVERSAL_JOINT_OPENING ||
    type === NodeType.OTHER_TRANSVERSE
  ) {
    // 先删除自己的子，不然会连带删除
    findCurrentNode?.setChildren([]);

    // 改变位置与节点数据更新
    removeAuxiliaryDevicePosition(graph, tempChildren, addedAllNodes, findParentItem, findItem, findCurrentNode);

    // 如果删除的节点的子节点是母线，那么母线的锚点需要移动

    // 如果父节点是母线，那母线得缩短（当节点中只有一个电表的时候）,
    if (findParentItem?.element === NodeType.BUS_BAR && findItem?.children.length === 0) {
      removeAuxDeviceWithPisBusbarOnlyOne(graph, addedAllNodes, allNodeData, findParentItem, type, findCurrentNode, id);
    }

    // 删除元素之后，且下面节点的加号回来（下方元素要有才会存在）
    if (findItem && findItem?.children.length > 0) {
      reduceNodeTopBtn(graph, allNodeData, findItem, findParentItem);
    }
    // 删除元素
    findCurrentNode && graph.removeNode(findCurrentNode);
  } else {
    // 删除其他元素
    // 删除电网接入点，电网侧加号回来
    if (type === NodeType.GRID_ACCESS_POINT) {
      const addedAllNodes = graph.getNodes();
      // 寻找父节点（因为是电网侧，所以父节点就是本身）
      const findItem = allNodeData[drawIndex!];
      const findGridSideEditNode = addedAllNodes.find(
        item => item.getData().id === findItem.id && item.getData().element === NodeType.GRID_SIDE
      );
      findGridSideEditNode &&
        findGridSideEditNode.updateData({
          isShowBottomAddBtn: true,
          value: {
            voltageLevel: undefined,
            summaryPointName: '电网侧',
          },
        });
    }
    // 如果父节点是母线，那母线得缩短
    if (findParentItem?.element === NodeType.BUS_BAR) {
      removeAuxDeviceWithPisBusbarOnlyOne(graph, addedAllNodes, allNodeData, findParentItem, type, findCurrentNode, id);
    }

    // 删除剩下的子元素（不是辅助设备）
    const ids = getChildrenIds(findItem?.children ?? []);
    ids.forEach(item => {
      const itemNode = addedAllNodes.find(i => i.getData().id === item.id && i.getData().element === item.element);
      if (itemNode) {
        graph.removeNode(itemNode);
      }
    });

    // 自己也要删除
    findCurrentNode && graph.removeNode(findCurrentNode);
  }

  // 如果删除的父节点是母线，则往上所有的母线得缩短
  // if (findParentItem?.element === NodeType.BUS_BAR && !isMeters(type) && type !== NodeType.ANTI_BACKFLOW_DEVICE) {
  // const allTopBusBar = findAllTopbusBar(allNodeData, findParentItem.id, findParentItem.element);
  // }
  if (findItem && findItem.children.length > 0) {
    if (
      isMeters(findItem.element) ||
      findItem.element === NodeType.ANTI_BACKFLOW_DEVICE ||
      findItem.element === NodeType.UNIVERSAL_JOINT_OPENING ||
      findItem.element === NodeType.OTHER_TRANSVERSE
    ) {
      const findChildNode = addedAllNodes.find(
        i => i.getData().id === findItem.children[0].id && i.getData().element === findItem.children[0].element
      );
      const findParentNode = addedAllNodes.find(
        i => i.getData().id === findParentItem?.id && i.getData().element === findParentItem?.element
      );

      if (findChildNode) {
        findParentNode?.addChild(findChildNode);
      }

      // 连线回来
      if (findItem && findItem.children.length > 0 && findItem.children[0].element === NodeType.BUS_BAR) {
        const findChildBusbarNode = addedAllNodes.find(
          item =>
            item.getData().id === findItem.children[0].id && item.getData().element === findItem.children[0].element
        );

        const busbarNeedExtendsMapTemp: busbarNeedExtendsMapArr = findChildBusbarNode?.getData().busbarNeedExtendsMap;
        const leftNodesNumber = busbarNeedExtendsMapTemp.circleArr.filter(i => i.circleNumber < 0).length;
        // 连线
        addLinkEdge(
          false,
          graph,
          findParentNode!,
          findChildNode!,
          isMeters(findParentNode?.shape as NodeType) ||
            findParentNode?.shape === NodeType.ANTI_BACKFLOW_DEVICE ||
            findParentNode?.shape === NodeType.UNIVERSAL_JOINT_OPENING ||
            findParentNode?.shape === NodeType.OTHER_TRANSVERSE
            ? -91
            : 1,
          undefined,
          leftNodesNumber * EACH_BUSBAR_LENGTH + 1,
          undefined
        );
      }
    }
  }

  // 如果删除的是自定义节点，那么所关联的元素或母线的连线得删除
  if ((type === NodeType.OTHER_TRANSVERSE || type === NodeType.OTHER_VERTICAL) && findCurrentNode) {
    const edges = graph.getConnectedEdges(findCurrentNode, { outgoing: true }); // 返回输出边
    graph.removeEdge(edges[0]);
  }
}

// 添加child与边
export function graphAllNodesAddChild(graphRef: Graph, allNodeData: TreeNode[]) {
  const addedAllNodes = graphRef.getNodes();
  function loop(array: TreeNode[], addedAllNodes: Node<Node.Properties>[], graphRef: Graph) {
    array.forEach(item => {
      const findItemNode = addedAllNodes.find(i => i.getData().id === item.id && i.getData().element === item.element);
      if (findItemNode && item.children[0]?.element === NodeType.BUS_BAR) {
        const findItemChldrenNode = addedAllNodes.find(
          i => i.getData().id === item.children[0].id && i.getData().element === item.children[0].element
        );
        const currentNodeTempData = findItemChldrenNode?.getData().busbarNeedExtendsMap;
        const tempCurrentCircleArr: BusBarCircleNumber[] = currentNodeTempData?.circleArr;
        const left = tempCurrentCircleArr.find(i => i.circleNumber === 0)?.left;

        if (findItemChldrenNode && findItemNode) {
          graphRef.addEdge({
            source: {
              cell: findItemNode!,
              anchor: {
                name: 'bottom',
                args: {
                  dx:
                    findItemNode?.getData().element === NodeType.ANTI_BACKFLOW_DEVICE ||
                    isMeters(findItemNode?.getData().element) ||
                    findItemNode?.getData().element === NodeType.UNIVERSAL_JOINT_OPENING ||
                    findItemNode?.getData().element === NodeType.OTHER_TRANSVERSE
                      ? -91
                      : 1,
                },
              },
              connectionPoint: 'anchor',
            },
            target: {
              cell: findItemChldrenNode!,
              anchor: {
                name: 'top',
                args: {
                  dx: left ? left + 1 : 1,
                },
              },
              connectionPoint: 'anchor',
            },
            zIndex: -100,
            // vertices: [
            //   {
            //     x: findItemNode.getPosition().x + findItemNode.getSize().width / 2,
            //     y: findItemNode.getPosition().y + findItemNode.getSize().height + 20,
            //   },
            //   {
            //     x: findItemChldrenNode.getPosition().x + findItemChldrenNode.getSize().width / 2,
            //     y: findItemChldrenNode.getPosition().y,
            //   },
            // ],
            router: {
              // name: 'manhattan',
              // args: {
              //   startDirections: ['bottom'],
              //   endDirections: ['bottom'],
              //   padding: {
              //     left: 10,
              //   },
              // },
              name: 'orth',
              args: {
                padding: {
                  left: 10,
                },
              },
            },
            attrs: {
              line: {
                stroke: 'rgba(74, 144, 226, 1)',
                strokeWidth: 2,
                sourceMarker: null,
                targetMarker: null,
              },
            },
          });
        }
      }
      item.children.forEach(c => {
        const findItemChldrenNode = addedAllNodes.find(
          i => i.getData().id === c.id && i.getData().element === c.element
        );
        findItemChldrenNode && findItemNode?.addChild(findItemChldrenNode);
      });
      if (item.children) loop(item.children, addedAllNodes, graphRef);
    });
  }
  loop(allNodeData, addedAllNodes, graphRef);
}

// 添加自定义节点与母线或节点关联的边
export function graphAddEdgeByNodeLink(graphRef: Graph) {
  const addedAllNodes = graphRef.getNodes();
  const customNodes = addedAllNodes.filter(
    i => i.getData().element === NodeType.OTHER_VERTICAL || i.getData().element === NodeType.OTHER_TRANSVERSE
  );
  customNodes.forEach(item => {
    handleConnectLinkNode(graphRef, item, item.getData()?.value, item.getData()?.element);
  });
}

const handleConnectLinkNode = (graph: Graph, addedNode: Node<Node.Properties>, values: any, addNodeType: NodeType) => {
  if (values?.associationType === LinkStatus.BusBar) {
    // 找到需要连接的节点
    const findLinkNode = graph
      .getNodes()
      .find(i => i.getData().id + i.getData().element === values?.associationId + values?.associationElement);
    const busbarData = findLinkNode?.getData()?.busbarNeedExtendsMap;
    const numberLess0 = busbarData?.circleArr?.filter((i: any) => i.circleNumber < 0).length;
    const numberMore0 = busbarData?.circleArr?.filter((i: any) => i.circleNumber > 1).length;

    let dxs = 0;
    // 连接左侧
    if (values?.associationNode === 0) {
      // 如果左侧有，那么在左侧加过元素，则起始点为最左侧
      if (numberLess0 > 0) {
        dxs = -EACH_BUSBAR_LENGTH / 2 + ADDCIRCLE_RADIUS;
      } else {
        dxs = -EACH_BUSBAR_LENGTH / 2 - numberLess0 * EACH_BUSBAR_LENGTH + ADDCIRCLE_RADIUS;
      }
    } else {
      // 右侧
      if (numberLess0 > 0) {
        dxs =
          EACH_BUSBAR_LENGTH / 2 +
          numberLess0 * EACH_BUSBAR_LENGTH +
          numberMore0 * EACH_BUSBAR_LENGTH -
          ADDCIRCLE_RADIUS;
      } else {
        dxs = EACH_BUSBAR_LENGTH / 2 + numberMore0 * EACH_BUSBAR_LENGTH - ADDCIRCLE_RADIUS;
      }
    }
    findLinkNode &&
      addLinkEdge(
        true,
        graph,
        addedNode,
        findLinkNode,
        addNodeType === NodeType.OTHER_TRANSVERSE ? -91 : 1,
        undefined,
        1 + dxs,
        -7
      );
  } else if (values?.associationType === LinkStatus.Node) {
    const findLinkNode = graph
      .getNodes()
      .find(i => i.getData().id + i.getData().element === values?.associationId + values?.associationElement);
    const findLinkNodeElement = findLinkNode?.getData()?.element;
    findLinkNode &&
      addLinkEdge(
        true,
        graph,
        addedNode,
        findLinkNode,
        addNodeType === NodeType.OTHER_TRANSVERSE ? -91 : 1,
        undefined,
        isMeters(findLinkNodeElement) ||
          findLinkNodeElement === NodeType.ANTI_BACKFLOW_DEVICE ||
          findLinkNodeElement === NodeType.UNIVERSAL_JOINT_OPENING ||
          findLinkNodeElement === NodeType.OTHER_TRANSVERSE
          ? -91
          : 1
      );
  }
};

export const addLinkEdge = (
  isLinkNode: boolean,
  graph: Graph,
  sourceNode: Node<Node.Properties>,
  targetNode: Node<Node.Properties>,
  sourcedx?: number,
  sourcedy?: number,
  targetdx?: number,
  targetdy?: number
) => {
  graph.addEdge({
    source: {
      cell: sourceNode,
      anchor: {
        name: 'bottom',
        args: {
          dx: sourcedx,
          dy: sourcedy,
        },
      },
      connectionPoint: 'anchor',
    },
    target: {
      cell: targetNode,
      anchor: {
        name: isLinkNode ? 'bottom' : 'top',
        args: {
          dx: targetdx,
          dy: targetdy,
        },
      },
      connectionPoint: 'anchor',
    },
    zIndex: -100,
    router: isLinkNode
      ? {
          name: 'manhattan',
          args: {
            startDirections: ['bottom'],
            endDirections: ['bottom'],
            padding: {
              left: 10,
            },
          },
        }
      : {
          name: 'orth',
          args: {
            padding: {
              left: 10,
            },
          },
        },
    attrs: {
      line: {
        stroke: 'rgba(74, 144, 226, 1)',
        strokeWidth: 2,
        sourceMarker: null,
        targetMarker: null,
      },
    },
  });
};
