import { apiV2DispatchUetStructPublishAntiBackflowDetailPost } from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Descriptions, Drawer, Form, Spin } from 'antd';
import { isNil } from 'lodash-es';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import { DeviceType, DeviceTypeLabels, NodeType } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';

export interface AntiBackflowDeviceDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const AntiBackflowDeviceDrawer: FC<AntiBackflowDeviceDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;

  const [form] = Form.useForm();
  const [title, setTitle] = useState<string>();

  const { loading } = useRequest(
    async () => {
      const detail = await apiV2DispatchUetStructPublishAntiBackflowDetailPost({ id: id!, version });

      form.setFieldsValue({
        ...detail,
        deviceId: detail.deviceVo?.id,
        deviceName: detail.deviceVo?.name,
        // @ts-ignore
        deviceType: DeviceTypeLabels[detail.deviceType as DeviceType],
        NodeTypeName: '防逆流装置',
        sw: detail.sw ? '开' : '关',
        relationDeviceName: detail.deviceVo?.name,
        thresholdValue: detail.thresholdValue ? detail.thresholdValue + 'kW' : '--',
        durationOfBackflow: detail.durationOfBackflow ? detail.durationOfBackflow + 's' : '--',
      });

      setTitle(detail.deviceVo?.name);
    },
    {
      ready: !isNil(id) && !isNil(version) && open,
      refreshDeps: [id, open, version],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '防逆流装置'} nodeType={NodeType.SWITCH} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="设备类型">
                <Item name="deviceType" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              {/* <Descriptions.Item label="设备名称">
                <Item name="deviceName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="开关">
                <Item name="sw" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item> */}
              <Descriptions.Item label="设备名称">
                <Item name="relationDeviceName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="防逆流阈值">
                <Item name="thresholdValue" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              {/* <Descriptions.Item label="逆流持续时间">
                <Item name="durationOfBackflow" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item> */}
            </Descriptions>

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />

                  <Item noStyle dependencies={['deviceName']}>
                    {({ getFieldValue }) => <div className={styles.iconText}>防逆流-{getFieldValue('deviceName')}</div>}
                  </Item>
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default AntiBackflowDeviceDrawer;
