import React, { useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';
import { Col, Row, Spin } from 'antd';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import { Button, Form, Modal, Select } from '@maxtropy/components';
import {
  apiV2DispatchUetStructAntiBackflowDeletePost,
  apiV2DispatchUetStructFcsDetailPost,
  apiV2DispatchUetStructFcsEditPost,
  apiV2DispatchUetStructFcsSavePost,
  apiV2DispatchUetStructMonitorNodeBsaPost,
  apiV2DispatchUetStructMonitorNodeGridConnectionPointPost,
  apiV2DispatchUetStructMonitorNodePvPost,
  apiV2DispatchUetStructPublishFcsDetailPost,
  apiV2DispatchUetStructPublishMonitorNodeBsaPost,
  apiV2DispatchUetStructPublishMonitorNodeGridConnectionPointPost,
  apiV2DispatchUetStructPublishMonitorNodePvPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import {
  FcsType,
  FcsTypeLabel,
  NodeType as NodeTypeClass,
} from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import ShowInput from '@/components/ShowInput';
import FcsSelectModal, { RowInfo } from './FcsSelectModal';
import classNames from 'classnames/bind';
import { NodeType, allInfoProps } from '@/pages/SchedulingTopology/type';
import { Graph } from '@antv/x6';
const cx = classNames.bind(styles);
const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
  allInfo?: allInfoProps;
  graphRef?: Graph;
}
const FcsController: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
  allInfo,
  graphRef,
}) => {
  const [form] = Form.useForm();

  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const dispatchingGroup = urlSearch.get('dispatchingGroup');

  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [fcsVisible, setFcsVisible] = useState<boolean>(false);

  const fcsId = Form.useWatch('fcsId', form);

  // 选择的fcs信息
  const [currentRow, setCurrentRow] = useState<RowInfo>();
  // fcs列表中选择fcs信息后回显
  useEffect(() => {
    if (currentRow) {
      form.setFieldsValue({
        name: currentRow?.name,
        softwareVersion: currentRow?.softwareVersion,
        edgeGatewayName: currentRow?.edgeGatewayName,
        fcsId: currentRow.id,
        edgeNetworkName: currentRow?.edgeNetworkName,
        // gatewayList: currentRow?.gatewayList,
        type: FcsTypeLabel[currentRow?.type as FcsType],
      });
    }
  }, [currentRow, form]);

  useEffect(() => {
    if (isNil(id) || !open) return;
    setIsDetail(true);
  }, [id, open]);

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  const { data: allSelectOptions, loading } = useRequest(
    () => {
      let params = {
        dispatchGroupId: Number(dispatchingGroup),
        dispatchUetStructId: Number(topologyId),
      };

      if (!isNil(version)) {
        return Promise.all([
          apiV2DispatchUetStructPublishMonitorNodeGridConnectionPointPost(params).then(res => res.list),
          apiV2DispatchUetStructPublishMonitorNodeBsaPost(params).then(res => res.list),
          apiV2DispatchUetStructPublishMonitorNodePvPost(params).then(res => res.list),
        ]).then(res => {
          console.log('res', res);
          return res.map(item => {
            return item?.map(i => ({
              label: i.name,
              value: i.originalId,
            }));
          });
        });
      } else {
        return Promise.all([
          apiV2DispatchUetStructMonitorNodeGridConnectionPointPost(params).then(res => res.list),
          apiV2DispatchUetStructMonitorNodeBsaPost({ id: fcsId }).then(res => [res].filter(i => !isNil(i))),
          apiV2DispatchUetStructMonitorNodePvPost(params).then(res => res.list),
        ]).then(res => {
          return res.map(item => {
            return item?.map(i => ({
              label: i.name,
              value: i.originalId,
            }));
          });
        });
      }
    },
    {
      ready: !!topologyId && !!dispatchingGroup && !!fcsId,
      refreshDeps: [topologyId, dispatchingGroup, version, fcsId],
    }
  );
  const [netPointOptions, storageOptions, pvOptions] = useMemo(() => {
    if (!allSelectOptions) return [];
    return allSelectOptions;
  }, [allSelectOptions]);

  useEffect(() => {
    if (allSelectOptions) {
      const [_, storageOptions, _pvOptions] = allSelectOptions;
      form.setFieldsValue({
        bsaItemIdList: storageOptions?.map(item => item.value),
      });
    }
  }, [allSelectOptions]);

  const { data: detail } = useRequest(
    () => {
      if (!isNil(version)) {
        return apiV2DispatchUetStructPublishFcsDetailPost({ id: id!, version });
      } else {
        return apiV2DispatchUetStructFcsDetailPost({ id: id! });
      }
    },
    {
      ready: !!id && !!open,
      refreshDeps: [id, open, version],
    }
  );
  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail.fcsInfo,
        type: FcsTypeLabel[detail.fcsInfo?.type as FcsType],
        id: detail.id,
        fcsId: detail?.fcsInfo?.id,
        accessPointItemId: detail.accessPointItem?.originalId,
        bsaItemIdList: detail.bsaItemList?.map(item => item.originalId),
        pvItemIdList: detail.pvItemList?.map(item => item.originalId),
      });
      setIconVisible(true);
      setTitle(detail.fcsInfo?.name);
    }
  }, [detail, form]);

  return (
    <>
      <Modal
        maskClosable={false}
        className="modal-form-content"
        open={open}
        size="large"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '就地控制器'}
            delMethod={apiV2DispatchUetStructAntiBackflowDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeTypeClass.NETWORK}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            getFinishedValues={val => getFinishedValues?.(val)}
            graphRef={graphRef}
            meterType={NodeType.FCS_CONTROLLER}
            createMethod={apiV2DispatchUetStructFcsSavePost}
            updateMethod={apiV2DispatchUetStructFcsEditPost}
            onCancel={onCancel}
          />
        }
        destroyOnClose
        onCancel={onCancel}
      >
        <Spin spinning={loading}>
          <div className={styles.form_box}>
            <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
              <Item label="FCS编号" name="fcsId" rules={[{ required: true }]} style={{ marginBottom: 10 }}>
                <span
                  className={cx('select_btn', isDetail ? 'disable_btn' : '')}
                  onClick={() => {
                    if (isDetail) return;
                    setFcsVisible(true);
                  }}
                >
                  {currentRow?.serialNumber ?? detail?.fcsInfo?.serialNumber ?? 'FCS选择'}
                </span>
              </Item>
              <div className={styles.fcs_info_box}>
                <Row>
                  <Col span={12}>
                    <Item labelCol={{ span: 10 }} label="FCS名称" name="name" style={{ marginBottom: 0 }}>
                      <ShowInput />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item labelCol={{ span: 10 }} label="FCS类型" name="type" style={{ marginBottom: 0 }}>
                      <ShowInput />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item
                      labelCol={{ span: 10 }}
                      label="FCS软件版本"
                      name="softwareVersion"
                      style={{ marginBottom: 0 }}
                    >
                      <ShowInput />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item labelCol={{ span: 10 }} label="绑定网关" name="edgeGatewayName" style={{ marginBottom: 0 }}>
                      <ShowInput />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item labelCol={{ span: 10 }} label="所在组网" name="edgeNetworkName" style={{ marginBottom: 0 }}>
                      <ShowInput />
                    </Item>
                  </Col>
                </Row>
              </div>
              <Item name="id" hidden />
              {fcsId && (
                <>
                  <div className={styles.head_title}>监控节点</div>
                  <Item label="电网接入点" name="accessPointItemId" rules={[{ required: true }]}>
                    <Select options={netPointOptions} placeholder="请选择" disabled={isDetail} allowClear />
                  </Item>
                  <Item label="储能站" name="bsaItemIdList" rules={[{ required: true }]}>
                    <Select options={storageOptions} mode="multiple" placeholder="请选择" disabled={true} allowClear />
                  </Item>
                  <Item label="光伏站" name="pvItemIdList">
                    <Select options={pvOptions} mode="multiple" placeholder="请选择" disabled={isDetail} allowClear />
                  </Item>

                  <div className={styles.preview}>
                    <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                      查看图标
                    </Button>

                    <div className={styles.iconWrapper}>
                      {iconVisible && (
                        <div className={styles.iconBox}>
                          <div className={styles.icon} />

                          <Item noStyle dependencies={['name']}>
                            {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                          </Item>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
          </div>
        </Spin>
      </Modal>
      {fcsVisible && (
        <FcsSelectModal
          currentFcsId={currentRow?.id ?? detail?.fcsInfo?.id}
          detailFcsId={detail?.fcsInfo?.id}
          onCancel={() => setFcsVisible(false)}
          onConfirm={row => {
            setFcsVisible(false);
            setCurrentRow(row);
          }}
        />
      )}
    </>
  );
};

export default FcsController;
