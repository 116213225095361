import { FC, useEffect } from 'react';
import { TenantLayout as LayoutComponents, Themes, useThemeContext } from '@maxtropy/components';
import TenantLayout from '@/components/Layout';
import { Outlet } from 'react-router-dom';

// 全屏路由出口
export const FullScreenOutlet: FC = () => {
  const { setCustomTheme } = useThemeContext();
  useEffect(() => {
    setCustomTheme?.(Themes.DARK);
    return () => {
      setCustomTheme?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

// 主业务页面路由出口
export const MainOutlet: FC = () => {
  const { setCustomTheme } = useThemeContext();
  useEffect(() => {
    setCustomTheme?.(Themes.DARK);
    return () => {
      setCustomTheme?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TenantLayout>
      <LayoutComponents>
        <Outlet />
      </LayoutComponents>
    </TenantLayout>
  );
};
