import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Title from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Title';
import Footer from '@/pages/EnergyDispatchManagement/ElementCreationModal/components/Footer';
import { NodeType, VoltageLevelLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';

import { isNil } from 'lodash-es';
import {
  V2DispatchUetStructGridAccessPointUetCircuitListPostResponse,
  V2UetListUetTopologyStructPostResponse,
  apiV2DispatchUetStructGridAccessPointCreatePost,
  apiV2DispatchUetStructGridAccessPointDeletePost,
  apiV2DispatchUetStructGridAccessPointGetPost,
  apiV2DispatchUetStructGridAccessPointUetCircuitListPost,
  apiV2DispatchUetStructGridAccessPointUpdatePost,
  apiV2DispatchUetStructPublishGridAccessPointGetPost,
  apiV2UetListUetTopologyStructPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Modal, Select } from '@maxtropy/components';
import { DoubleRightOutlined } from '@ant-design/icons';

const { Item } = Form;

interface Props {
  id?: number;
  open?: boolean;
  dispatchGroupId?: number;
  isPreview?: boolean;
  isShowModal?: (open: boolean) => void;
  getFinishedValues?: (values: any) => void;
  shouldDeleteElement?: (state: boolean) => void;
  version?: number;
}
const PowerGridModal: React.FC<Props> = ({
  id,
  dispatchGroupId,
  open,
  isPreview,
  isShowModal,
  getFinishedValues,
  shouldDeleteElement,
  version,
}) => {
  const [form] = Form.useForm();
  const urlParams = new URLSearchParams(window.location.search);
  const topologyId = urlParams.get('topologyId');
  const [iconVisible, setIconVisible] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [uetList, setUetList] = useState<V2UetListUetTopologyStructPostResponse['list']>();
  const uetId = Form.useWatch('uetId', form);
  const summaryPointName = Form.useWatch('summaryPointName', form);
  const [circuitList, setCircuitList] = useState<V2DispatchUetStructGridAccessPointUetCircuitListPostResponse>();

  useEffect(() => {
    if (summaryPointName === '') {
      form.setFieldValue('summaryPointName', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryPointName]);

  const onShowIcon = () => {
    form.validateFields().then(() => {
      setIconVisible(true);
    });
  };

  const onCancel = () => {
    form.resetFields();
    setTitle(undefined);
    isShowModal?.(false);
  };

  const { data: detail } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      setIsDetail(true);
      let detail;
      if (!isNil(version)) {
        detail = await apiV2DispatchUetStructPublishGridAccessPointGetPost({ id, version });
      } else {
        detail = await apiV2DispatchUetStructGridAccessPointGetPost({ id });
      }
      if (detail) {
        form.setFieldsValue({
          ...detail,
          uetId: detail.uet?.id,
        });
        setIconVisible(true);
        setTitle(detail.name);
      }
      return detail;
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // uetList
  useEffect(() => {
    if (!open) return;
    if (topologyId) {
      apiV2UetListUetTopologyStructPost({ id: +topologyId! }).then(res => {
        setUetList(res.list);
      });
    }
  }, [open, topologyId]);

  // 回路列表
  useEffect(() => {
    if (uetId) {
      apiV2DispatchUetStructGridAccessPointUetCircuitListPost({ id: uetId }).then(res => {
        setCircuitList(res);
      });
    }
  }, [uetId]);

  return (
    <>
      <Modal
        open={open}
        contentClassName="modal-form-content"
        title={
          <Title
            id={id}
            styles={styles}
            isDetail={isDetail}
            isPreview={isPreview}
            title={title ?? '电网接入点'}
            delMethod={apiV2DispatchUetStructGridAccessPointDeletePost}
            onEdit={() => setIsDetail(false)}
            onCancel={onCancel}
            shouldDeleteElement={state => shouldDeleteElement?.(state)}
            nodeType={NodeType.SOURCE}
          />
        }
        footer={
          <Footer
            isDetail={isDetail}
            styles={styles}
            form={form}
            dispatchGroupId={dispatchGroupId}
            createMethod={apiV2DispatchUetStructGridAccessPointCreatePost}
            updateMethod={apiV2DispatchUetStructGridAccessPointUpdatePost}
            getFinishedValues={val => getFinishedValues?.(val)}
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        destroyOnClose
      >
        <div className={styles.modalWrapper}>
          <Form form={form} labelCol={{ span: 6 }} labelAlign="left">
            <Item name="name" label="接入点名称" rules={[{ required: true, min: 2, max: 30 }]}>
              <Input placeholder="请输入" disabled={isDetail} allowClear />
            </Item>

            <Item name="voltageLevel" label="电压等级" rules={[{ required: true, message: '请选择电压等级' }]}>
              <Select
                placeholder="请选择"
                options={Object.entries(VoltageLevelLabel)
                  .map(([key, value]) => ({
                    label: value,
                    value: +key,
                  }))
                  .sort((a, b) => {
                    return (a.label.slice(0, -2) as unknown as number) - (b.label.slice(0, -2) as unknown as number);
                  })}
                disabled={isDetail}
                allowClear
              />
            </Item>

            <Item name="uetId" label="所属UET" rules={[{ required: true, message: '请选择所属UET' }]}>
              <Select
                placeholder="请选择"
                options={(uetList ?? []).map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                disabled={!!detail}
                allowClear
              />
            </Item>
            <Item name="summaryPointName" label="总节点名称" rules={[{ min: 1, max: 20 }]}>
              <Input placeholder="请输入" disabled={isDetail} allowClear />
            </Item>

            <Item name="id" hidden />

            {uetId && (
              <div style={{ display: 'flex' }}>
                <span className={styles.circuitTitleSty}>进线回路：</span>
                <div>
                  {!circuitList?.list || circuitList.list.length === 0 ? (
                    <span>--</span>
                  ) : (
                    (circuitList.list ?? []).map(item => (
                      <div key={item.id}>
                        <span className={styles.itemCircuitSty}>{item.name} </span>

                        {item.contractId && (
                          <span
                            className={styles.jumpElecSty}
                            onClick={() => {
                              window.open(
                                `${window.IOTPLATFORMORIGIN}/operation/energy/contract/electric-rate/edit/${item.contractId}`,
                                '_blank'
                              );
                            }}
                          >
                            编辑电价信息
                            <DoubleRightOutlined />
                          </span>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            <div className={styles.preview}>
              <Button type="link" className={styles.previewBtn} onClick={onShowIcon}>
                查看图标
              </Button>

              <div className={styles.iconWrapper}>
                {iconVisible && (
                  <div className={styles.iconBox}>
                    <div className={styles.icon} />

                    <Item noStyle dependencies={['name']}>
                      {({ getFieldValue }) => <div className={styles.iconText}>{getFieldValue('name')}</div>}
                    </Item>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default PowerGridModal;
