import { CustomFilter, EllipsisSpan, Input, Modal, Paging, Select, usePaging, useUpdate } from '@maxtropy/components';
import { Form, Table } from 'antd';
import { useRequest } from 'ahooks';
import {
  apiV2DispatchUetStructFcsControlPagePost,
  apiV2DispatchUetStructSoftwareVersionListPost,
  apiV2UetListOuUetTopologyStructPost,
  V2DispatchUetStructFcsControlPagePostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import { FcsType, FcsTypeLabel } from '../types';
interface SearchParams {
  codeOrName?: string;
  ouId?: number;
  softwareVersion?: number;
}
export type RowInfo = Exclude<V2DispatchUetStructFcsControlPagePostResponse['list'], undefined>[number];

interface Iprops {
  onConfirm: (row?: RowInfo) => void;
  onCancel: () => void;
  currentFcsId?: number;
  detailFcsId?: number;
}
const FcsSelectModal = (props: Iprops) => {
  const pagingInfo = usePaging(50);
  const [form] = Form.useForm<SearchParams>();
  const [update, setUpdate] = useUpdate();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const [currentRow, setCurrentRow] = useState<RowInfo>();
  const [currentRowKey, setCurrentRowKey] = useState<number>();

  useEffect(() => {
    if (props.currentFcsId) {
      setCurrentRowKey(props.currentFcsId);
    }
  }, [props.currentFcsId]);
  // ou下拉列表, 先查询uetIds
  // const { data: uetIds } = useRequest(
  //   () => {
  //     return apiV2UetListUetTopologyStructPost({
  //       id: Number(topologyId),
  //     }).then(res => res.list?.map(item => item.id!));
  //   },
  //   {
  //     ready: !!topologyId,
  //     refreshDeps: [Number(topologyId)],
  //   }
  // );

  // 查询ou列表
  const { data: ouList } = useRequest(
    async () => {
      const res = await apiV2UetListOuUetTopologyStructPost({
        id: Number(topologyId),
      });
      return res.list;
    },
    {
      ready: !!topologyId,
      refreshDeps: [topologyId],
    }
  );
  // 查询软件版本
  const { data: softWareList } = useRequest(async () => {
    const res = await apiV2DispatchUetStructSoftwareVersionListPost({});
    return res.list;
  });

  // 表格
  const { data: list, loading } = useRequest(
    async () => {
      const softwareVersion = form.getFieldValue('softwareVersion');
      const res = await apiV2DispatchUetStructFcsControlPagePost({
        ...form.getFieldsValue(),
        softwareVersion: !isNil(softwareVersion) ? [softwareVersion] : undefined,
        dispatchStructId: Number(topologyId),
        size: pageSize,
        page: pageOffset,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      ready: !!topologyId,
      refreshDeps: [update, pageOffset, pageSize, topologyId],
    }
  );
  const searchBtn = () => {
    setUpdate();
    setPageOffset(1);
  };
  const resetBtn = () => {
    form.resetFields();
    setUpdate();
    setPageOffset(1);
  };
  const columns = [
    {
      title: 'FCS编号',
      dataIndex: 'serialNumber',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: 'FCS名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: 'FCS类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={!isNil(v) ? FcsTypeLabel[v as FcsType] : '--'} />,
    },
    {
      title: '软件版本',
      dataIndex: 'softwareVersion',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '运营单元',
      dataIndex: 'ouName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
  return (
    <>
      <Modal
        title="FCS选择"
        // width={1024}
        size="big"
        open={true}
        onOk={() => props.onConfirm?.(currentRow)}
        onCancel={() => props.onCancel?.()}
      >
        <CustomFilter form={form} onFinish={searchBtn} onReset={resetBtn}>
          <Form.Item label="名称/编号" name="codeOrName">
            <Input placeholder="请输入名称/编号查询" />
          </Form.Item>

          <Form.Item label="软件版本" name="softwareVersion">
            <Select placeholder="请选择软件版本" options={softWareList?.map(item => ({ label: item, value: item }))} />
          </Form.Item>

          <Form.Item label="运营单元" name="ouId">
            <Select
              placeholder="请选择运营单元"
              options={ouList?.map(item => ({ label: item.name, value: item.id }))}
            />
          </Form.Item>
        </CustomFilter>
        <div className={styles.action_line}>
          点击前往
          <span
            onClick={() => window.open(`${window.IOTPLATFORMORIGIN ?? ''}/device/fcs/list/create`, '_blank')}
            className={styles.click_text}
          >
            添加FCS
          </span>
        </div>
        <Table
          loading={loading}
          rowKey="id"
          dataSource={list}
          columns={[...columns]}
          scroll={{ y: 300 }}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
              setCurrentRowKey(selectedRowKeys[0] as number);
              setCurrentRow(selectedRows[0] as RowInfo);
            },
            selectedRowKeys: currentRowKey ? [currentRowKey] : [],
            getCheckboxProps: (record: RowInfo) => ({
              // 被绑定的不能选择(除了自己)
              disabled: record.id !== props.detailFcsId && record.disable,
            }),
          }}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Modal>
    </>
  );
};

export default FcsSelectModal;
