import { Descriptions, Drawer, Form, Spin } from 'antd';
import React, { FC, useState } from 'react';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';
import ShowInput from '@/components/ShowInput';
import {
  apiV2DispatchUetStructBsaPcsListPost,
  apiV2DispatchUetStructPublishBsaDetailPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { NodeType, VoltageLevel, VoltageLevelLabel } from '@/pages/EnergyDispatchManagement/ElementCreationModal/types';
import TitleTag from '../TitleTag';

export interface BsaDrawerProps {
  isShowModal: (open: boolean) => void;
  open: boolean;
  id?: number;
  version?: number;
}

const { Item } = Form;

const BsaDrawer: FC<BsaDrawerProps> = props => {
  const { isShowModal, open, id, version } = props;
  const urlSearch = new URLSearchParams(window.location.search);
  const topologyId = urlSearch.get('topologyId'); // 结构id
  const dispatchingGroup = urlSearch.get('dispatchingGroup'); // 调度组id

  const [form] = Form.useForm();
  const bsaId = Form.useWatch('bsaId', form);
  const [title, setTitle] = useState<string>();
  // const hasControlItem = Form.useWatch('hasControlItem', form);

  const { data: detail, loading } = useRequest(
    async () => {
      if (isNil(id) || !open) return Promise.resolve(undefined);
      if (!isNil(version)) {
        const detail = await apiV2DispatchUetStructPublishBsaDetailPost({ id, version });
        setTitle(detail.bsaSimpleVo?.name);
        form.setFieldsValue({
          ...detail,
          bsaId: detail.bsaSimpleVo?.id,
          NodeTypeName: '储能站',
          name: detail.bsaSimpleVo?.name,
          voltageLevel: VoltageLevelLabel[detail.voltageLevel as VoltageLevel],
          // 控制对象信息
          pcsList: detail.pcsList?.map(i => i.device?.name).join(),
          airConditionerDevice: detail.airConditionerDevice?.map(i => i.name).join(),
          fireControlDevice: detail.fireControlDevice?.map(i => i.name).join(),
          hygrographDevice: detail.hygrographDevice?.map(i => i.name).join(),
          smokeSensorDevice: detail.smokeSensorDevice?.map(i => i.name).join(),
          bindPcsList: detail.bindPcsList
            ?.filter(item => item.hasChoose)
            .map(item => item.sequence! + 1 + ' | ' + item.device?.name)
            .join(),

          bindPcsIdList: detail.bindPcsList?.filter(item => item.hasChoose).map(item => item.id),
          hasControlItem: detail.hasControlItem ?? false,
        });
        return detail;
      }
    },
    {
      refreshDeps: [id, open, version],
    }
  );

  // pcs list
  const { data: pcsList } = useRequest(
    async () => {
      const res = await apiV2DispatchUetStructBsaPcsListPost({
        dispatchGroupId: +dispatchingGroup!,
        dispatchUetStructId: +topologyId!,
        id: +bsaId,
      });
      if (res) {
        return res.list;
      }
    },
    {
      ready: !!bsaId && open && !!detail,
      refreshDeps: [bsaId, open, detail],
    }
  );

  return (
    <div className={styles.drawerStyle}>
      <Drawer
        title={<TitleTag title={title ?? '储能站'} nodeType={NodeType.STORAGE} />}
        placement="right"
        width={400}
        mask={false}
        onClose={() => {
          isShowModal?.(false);
        }}
        open={open}
        getContainer={false}
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <Descriptions labelStyle={{ width: '40%' }} title={null} bordered column={1} size="small">
              <Descriptions.Item label="元素类型">
                <Item name="NodeTypeName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="储能站名称">
                <Item name="name" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="所在总进线回路">
                <Item name="circuitName" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="并网电压等级">
                <Item name="voltageLevel" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
              <Descriptions.Item label="关联PCS">
                <Item name="bindPcsList" style={{ marginBottom: 0 }}>
                  <ShowInput />
                </Item>
              </Descriptions.Item>
            </Descriptions>

            {/* <div style={{ marginTop: 16, marginBottom: 8 }}>控制对象信息</div>
            <Descriptions labelStyle={{ width: '40%' }} title={''} bordered column={1} size="small">
              {(detail?.pcsList ?? []).length > 0 && (
                <Descriptions.Item label="PCS">
                  <Item name="pcsList" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}

              {(detail?.airConditionerDevice ?? []).length > 0 && (
                <Descriptions.Item label="空调">
                  <Item name="airConditionerDevice" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}

              {(detail?.fireControlDevice ?? []).length > 0 && (
                <Descriptions.Item label="消防">
                  <Item name="fireControlDevice" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}

              {(detail?.hygrographDevice ?? []).length > 0 && (
                <Descriptions.Item label="温湿度计">
                  <Item name="hygrographDevice" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}

              {(detail?.smokeSensorDevice ?? []).length > 0 && (
                <Descriptions.Item label="烟感">
                  <Item name="smokeSensorDevice" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Item>
                </Descriptions.Item>
              )}
            </Descriptions> */}

            <Item name="hasControlItem" hidden />
            <Item name="bsaId" hidden />
            <Item name="bindPcsIdList" hidden />

            <div className={styles.preview}>
              <div className={styles.previewBtn} style={{ marginTop: 16, marginBottom: 8 }}>
                查看图标
              </div>

              <div className={styles.iconWrapper}>
                <div className={styles.iconBox}>
                  <div className={styles.icon} />
                  <div className={styles.iconText}>{detail?.bsaSimpleVo?.name}</div>
                  <Item noStyle dependencies={['bindPcsIdList']}>
                    {({ getFieldValue }) => {
                      const pcsIds = getFieldValue('bindPcsIdList') as number[];
                      console.log('pcsIds', pcsIds);
                      console.log('pcsList', pcsList);
                      if (pcsIds?.length < pcsList?.length! && pcsIds?.length > 0) {
                        return <div className={styles.part}>部分</div>;
                      }
                    }}
                  </Item>
                  <div className={styles.fcsAndpcs}>
                    <div className={styles.text}>
                      <Item noStyle dependencies={['bindPcsIdList']}>
                        {({ getFieldValue }) => {
                          const bindPcsIdList = getFieldValue('bindPcsIdList') as number[];
                          console.log('bindPcsIdList', bindPcsIdList);
                          return (
                            <div className={styles.pcs}>{`PCS ${(bindPcsIdList ?? []).length}/${
                              (pcsList ?? []).length
                            }`}</div>
                          );
                        }}
                      </Item>
                    </div>
                  </div>
                  {/* {hasControlItem && (
                    <>
                      <div className={styles.dashed} />

                      <div className={styles.controlObject}>
                        {[
                          ...(detail?.pcsList ?? []).map(i => i.device?.name),
                          ...(detail?.airConditionerDevice ?? []).map(i => i.name),
                          ...(detail?.fireControlDevice ?? []).map(i => i.name),
                          ...(detail?.hygrographDevice ?? []).map(i => i.name),
                          ...(detail?.smokeSensorDevice ?? []).map(i => i.name),
                        ].map((item, index) => (
                          <div key={index} className={styles.controlObjectIcon}>
                            {item}
                          </div>
                        ))}
                      </div>
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default BsaDrawer;
