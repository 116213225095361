import { TreeSelect } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';
import {
  apiV2DispatchUetStructCreatePost,
  apiV2DispatchUetStructUpdatePost,
  apiV2UetListUetDispatchGroupPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { TopologyList } from '@/pages/EnergyDispatchManagement/Edit/Topology/types';
import { Form, Input, Modal, Select } from '@maxtropy/components';

const { Item } = Form;

interface Props {
  customerMcids: string[];
  visible: boolean;
  currentListInfo?: TopologyList;
  topologyList: {
    label?: string;
    value?: number;
  }[];
  setCurrentTopoInfo: (val: undefined) => void;
  onCancel: () => void;
  forceUpdate: () => void;
}

const AddTopologyModal: React.FC<Props> = ({
  topologyList,
  visible,
  currentListInfo,
  setCurrentTopoInfo,
  onCancel,
  forceUpdate,
}) => {
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();
  const [uetIds] = React.useState<(number | undefined)[]>(currentListInfo?.uetVos?.map(item => item.id) ?? []);

  const handleCancel = () => {
    currentListInfo && setCurrentTopoInfo(undefined);
    form.resetFields();
    onCancel();
  };

  const onOk = () => {
    form
      .validateFields()
      .then(values => {
        const apiMethod = currentListInfo ? apiV2DispatchUetStructUpdatePost : apiV2DispatchUetStructCreatePost;
        const addUetIds = values?.uetIds?.filter((item: number) => !uetIds.includes(+item));
        const params = {
          ...values,
          ...(currentListInfo ? { addUetIds } : {}),
          ...(currentListInfo ? {} : { dispatchGroupId: +id! }),
        };
        if (currentListInfo) {
          delete params.uetIds;
        }

        apiMethod(params).then(() => {
          handleCancel();
          forceUpdate();
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  // uet list
  const { data: uetOptions } = useRequest(async () => {
    if (isNil(id)) return;
    const res = await apiV2UetListUetDispatchGroupPost({
      id: +id,
    });

    if (res) {
      return res.list?.map?.(item => ({
        value: item.id,
        title: item.name,
        disabled: uetIds?.includes(item.id),
      }));
    }
  });

  useEffect(() => {
    if (currentListInfo) {
      form.setFieldsValue({
        ...currentListInfo,
        uetIds,
        preTopologyId: currentListInfo?.linkVo?.id,
      });
    }
  }, [currentListInfo, form, uetIds]);

  const modalTitle = useMemo(() => {
    return currentListInfo ? '编辑拓扑结构' : '新建拓扑结构';
  }, [currentListInfo]);

  return (
    <>
      <Modal
        contentClassName="modal-form-content"
        title={modalTitle}
        open={visible}
        onCancel={handleCancel}
        onOk={onOk}
        destroyOnClose
      >
        <Form form={form} layout="horizontal" labelAlign="left">
          <Item label="调度拓扑结构名称" name="name" rules={[{ required: true, min: 2, max: 30 }]}>
            <Input placeholder="请输入" allowClear />
          </Item>

          <Item label="关联UET" name="uetIds" rules={[{ required: true, message: '请选择关联UET' }]}>
            <TreeSelect placeholder="请选择" treeCheckable={true} multiple treeData={uetOptions} />
          </Item>

          <Item label="关联上级拓扑" name="preTopologyId">
            <Select placeholder="请选择" options={topologyList} disabled={topologyList?.length === 0} allowClear />
          </Item>

          <Item noStyle name="id" />
        </Form>
      </Modal>
    </>
  );
};

export default AddTopologyModal;
