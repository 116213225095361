import { Graph, Node } from '@antv/x6';
import { Cascader, Dropdown, Popconfirm, Tooltip } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { FcsList, GateWayList, nodeElementLinkTree, NodeType, TreeNode } from '../../type';
import styles from './index.module.scss';
import antiBackflowDevice from '../../imgs/antiBackflowDevice.gif';
import { findDrawIndexByCurrentNode } from '../../utils/utils';
import { SingleValueType } from 'rc-cascader/lib/Cascader';
import { DefaultOptionType } from 'antd/es/cascader';
import { CloseOutlined } from '@ant-design/icons';
import { limitByNodes } from '../../utils/nodelimit';

export interface AntiBackflowDeviceProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  gatewayListRef?: GateWayList[];
  fcsListRef?: FcsList[];
  handleCreateNode?: (
    upperOrDown: string,
    allNodeData: TreeNode[],
    node: Node<Node.Properties>,
    graph: Graph,
    addNodeType: NodeType,
    gatewayList: GateWayList[],
    fcsList: FcsList[],
    index?: number
  ) => void;
  confirm?: (type: NodeType, id?: number, drawIndex?: number) => void;
  showModalDetail?: (id: number, type: NodeType) => void;
  nodeElementLink?: nodeElementLinkTree[];
}

const AntiBackflowDevice: FC<AntiBackflowDeviceProps> = props => {
  const {
    node,
    graphRef,
    allNodeDataRef,
    gatewayListRef,
    fcsListRef,
    handleCreateNode,
    confirm,
    showModalDetail,
    nodeElementLink,
  } = props;
  const [cascaderValue, setCascaderValue] = useState<string[]>();
  const [topDropDownOpen, setTopDropDownOpen] = useState<boolean>(false);
  const [bottomDropDownOpen, setBottomDropDownOpen] = useState<boolean>(false);

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  const isShowTopBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowTopAddBtn;
  }, [data]);

  const isShowBottomBtn = useMemo(() => {
    return data?.isShowAllAddDelBtn && data?.isShowBottomAddBtn;
  }, [data]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div
      className={styles.customAntiBackFlowReactNode}
      style={{
        opacity: data.isShowNetWork ? 0.5 : 1,
      }}
    >
      {data?.isShowAllAddDelBtn && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={
            <p style={{ color: '#fff' }}>
              确定要删除
              <span style={{ color: '#E64242' }}> {data.value.name}</span>设备吗？
            </p>
          }
          onConfirm={e => {
            e?.stopPropagation();
            confirm?.(
              NodeType.ANTI_BACKFLOW_DEVICE,
              data.id,
              findDrawIndexByCurrentNode({ id: data?.id, element: data?.element }, allNodeDataRef)
            );
          }}
          okText="确定"
          cancelText="取消"
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={styles.deleteIcon}
          >
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
      {isShowTopBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={topDropDownOpen}
          onOpenChange={open => {
            setTopDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              open={topDropDownOpen}
              expandTrigger={'hover'}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'upper',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
                setTopDropDownOpen(false);
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircleTop}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}

      <div className={styles.vline}></div>

      {isShowBottomBtn && (
        <Dropdown
          destroyPopupOnHide
          overlayClassName={styles.drapDownStyle}
          open={bottomDropDownOpen}
          onOpenChange={open => {
            setBottomDropDownOpen(open);
          }}
          dropdownRender={() => (
            <Cascader
              popupClassName={styles.drapDownCascaderSty}
              options={nodeElementLink}
              allowClear={false}
              fieldNames={{ value: 'key' }}
              showSearch={{ filter }}
              notFoundContent={'暂无数据'}
              placeholder={'请输入元素名称'}
              expandTrigger={'hover'}
              open={bottomDropDownOpen}
              value={cascaderValue}
              onChange={value => {
                setCascaderValue(value);
                limitByNodes(
                  value[value.length - 1] as NodeType,
                  node,
                  'down',
                  undefined,
                  graphRef,
                  allNodeDataRef,
                  gatewayListRef,
                  fcsListRef,
                  handleCreateNode
                );
                setBottomDropDownOpen(false);
              }}
            />
          )}
          trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              setCascaderValue(undefined);
            }}
            className={styles.addCircleBottom}
          >
            <div className={styles.addIcon}>+</div>
          </div>
        </Dropdown>
      )}

      <div className={styles.hline}></div>
      <div
        className={styles.cardMeterSty}
        onClick={() => {
          showModalDetail?.(data.id, NodeType.ANTI_BACKFLOW_DEVICE);
        }}
      >
        <img style={{ width: 24, height: 24, marginLeft: 12 }} src={antiBackflowDevice} alt="" />

        <Tooltip title={`防逆流-${data.value.name}`}>
          <div style={{ marginLeft: 12 }} className={styles.itemControlobjContentSty}>
            防逆流-{data.value.name}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default AntiBackflowDevice;
