import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Paging, Select, Table, usePaging } from '@maxtropy/components';
import { Space } from 'antd';
import { useRequest, useSize } from 'ahooks';
import styles from './index.module.scss';
import {
  apiV2GreenMicrowebShowcaseDeviceListPost,
  apiV2GreenMicrowebShowcaseInstructionConfigPagePost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { Key, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ShareDataContext } from '../..';

import { useNavigate } from 'react-router-dom';
import { RemoteControlEnum, SwitchStatus } from '../TopoBox/type';
import { RowItem, useShowSendConfirmModal } from './hooks';
import CreatingModal from '@/pages/SchedulingTopology/PublishLoading/CreatingModal';
interface Iprops {
  isShow?: boolean;
  initialH?: string;
}
const DispatchPageList = (props: Iprops) => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const { configId } = useContext(ShareDataContext);
  const [deviceId, setDeviceId] = useState<Key>();

  useEffect(() => {
    setDeviceId(undefined);
  }, [configId]);

  const { data: pageList, loading } = useRequest(
    async () => {
      const res = await apiV2GreenMicrowebShowcaseInstructionConfigPagePost({
        page: pageOffset,
        size: pageSize,
        deviceId: deviceId,
        confId: configId,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      ready: !!configId,
      refreshDeps: [pageOffset, pageSize, deviceId, configId],
    }
  );
  const { data: deviceList } = useRequest(
    () => {
      return apiV2GreenMicrowebShowcaseDeviceListPost({
        id: configId,
      });
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );
  const { loading: sendLoading, func: showSendConfirmModal } = useShowSendConfirmModal({ configId: configId! });
  const buildColumns = [
    {
      title: '目标设备',
      ellipsis: true,
      dataIndex: 'deviceName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '关联元素名称',
      ellipsis: true,
      dataIndex: 'elementName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },

    {
      title: '目标数据属性',
      ellipsis: true,
      dataIndex: 'dataPropertyName',
      render: (v: string, record: any) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 100,
      render: (v: any, record: RowItem) => {
        return (
          <Button
            disabled={record.dataPropertyId === RemoteControlEnum && record.targetValue === SwitchStatus.CLOSE}
            type="link"
            onClick={() => showSendConfirmModal(record)}
          >
            {record.buttonName}
          </Button>
        );
      },
    },
  ];
  const navigate = useNavigate();
  const refTableBox = useRef<HTMLDivElement>();

  return (
    <div className={styles.dispath_box} style={{ maxHeight: props.initialH }}>
      <div className={styles.dispath_header}>
        <div className={styles.header_left}>目标设备调度</div>
        <div className={styles.header_right}>
          <Space>
            <Button
              style={{ paddingRight: 0 }}
              type="link"
              onClick={() => navigate(`/microgrid/conf/overview/reLog/${configId}?deviceId=${deviceId ?? ''}`)}
            >
              调度日志
              <DoubleRightOutlined />
            </Button>
          </Space>
        </div>
      </div>
      <div className={styles.dispath_search}>
        <Select
          style={{ width: 240 }}
          value={deviceId}
          onChange={setDeviceId}
          options={deviceList?.list?.map(item => ({ label: item.name, value: item.id }))}
          getPopupContainer={() => document.getElementById('full_screen_box') ?? document.body}
          placeholder="请输入目标设备"
          showSearch
          allowClear
          optionFilterProp="label"
        />
      </div>
      <div className={styles.dispath_table_outer}>
        <div
          className={styles.dispath_table}
          ref={d => {
            if (d) {
              refTableBox.current = d;
            }
          }}
        >
          <Table
            rowKey={'id'}
            scroll={{ x: pageList && pageList.length ? 480 : undefined }}
            loading={loading}
            dataSource={pageList}
            columns={buildColumns}
          />
        </div>
      </div>
      <div className={styles.page_box}>
        <Paging className={styles.page_box_inner} size="small" pagingInfo={pagingInfo} />
      </div>
      {sendLoading && <CreatingModal loading={sendLoading} content={'指令下发中'} />}
    </div>
  );
};

export default DispatchPageList;
