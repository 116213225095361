import { Button, Checkbox, Form, Input, Modal, Radio, Select, Table, Tooltip, useUpdate } from '@maxtropy/components';
import { Col, Space } from 'antd';
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import {
  FileSearchOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  RightCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  apiV2BsaControlConfigPost,
  apiV2BsaStrategyTemplateEpDetailPost,
  V2BsaStrategyTemplateGetPostResponse,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { isNil } from 'lodash-es';
import { ChargeList, formatEndtime, formatSubmitTime, getPriceTimeIndex, getTimeString } from '../utils';
import {
  ActionType,
  ActionTypeTime,
  ChargingAndDischargingPower,
  ChargingAndDischargingPowerplay,
  PVPeriods,
  PVPeriodsDisplay,
  PeaksAndValleysType,
  PeaksAndValleysTypePowerplay,
  PricedisplayType,
  PricedisplayTypeDisplay,
  StrategyTemplateType,
  StrategyTemplateTypeDisplay,
} from '@/pages/StrategyTemplate/type';
import ChargeDisChangeTime from '@/pages/StrategyTemplate/StrategyTemplateEdit/components/ChargeDisChangeTime';
import {
  PowerListItem,
  checekTime24Hour,
  columnsWithCustomCustom,
  columnsWithCustomFollowMax,
  columnsWithCustomTimeFllowPrice,
  columnsWithCustomTimeFollowMax,
  columnsWithFollowPrice,
} from '@/pages/StrategyTemplate/common';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

interface Props {
  id?: number;
  bsaId?: string;
  data?: V2BsaStrategyTemplateGetPostResponse;
  showTemplateName?: boolean;
  size?: 'small' | 'middle';
}

export interface StrategyTemplateEditRefProps {
  getFormData: any;
}

const StrategyTemplateEdit: ForwardRefRenderFunction<StrategyTemplateEditRefProps, Props> = (props, ref) => {
  const { id, bsaId, data, showTemplateName = true } = props;
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    getFormData: async () => onFinish(await form.validateFields()),
  }));

  const periodTypes: PVPeriods | undefined = Form.useWatch('periodTypes', form);
  const chargingAndDischargingPower: ChargingAndDischargingPower | undefined = Form.useWatch(
    'chargingAndDischargingPower',
    form
  );
  const plainAndValleyTypeList: number[] | undefined = Form.useWatch('plainAndValleyTypeList', form);
  const peakAndSharpTypeList: number[] | undefined = Form.useWatch('peakAndSharpTypeList', form);
  const price: ActionTypeTime[] | undefined = Form.useWatch('price', form);
  const timePeriodGranularity: PricedisplayType | undefined = Form.useWatch('timePeriodGranularity', form);
  const epTemplateId: number | undefined = Form.useWatch('epTemplateId', form);

  const [displayType, setDisplayType] = useState<PricedisplayType>();

  const [updateState, updateFn] = useUpdate();

  // 查询上下线
  const { data: bsaConfigData } = useRequest(
    () =>
      apiV2BsaControlConfigPost({ id: Number(bsaId) }).then(res => {
        return res;
      }),
    {
      ready: !!bsaId,
      refreshDeps: [bsaId],
    }
  );

  useEffect(() => {
    if (isNil(periodTypes) || isNil(chargingAndDischargingPower)) return;
    if (periodTypes === PVPeriods.FollowEpriceTemplate) {
      if (chargingAndDischargingPower === ChargingAndDischargingPower.Custom) {
        const allList = [plainAndValleyTypeList, peakAndSharpTypeList].flat().filter(i => !isNil(i));

        form.setFieldsValue({
          chargingAndDischargingPeriodDetail: allList.map(item => {
            return {
              key: uuidv4(),
              type: ChargeList.includes(item!) ? ActionType.Charge : ActionType.DisCharge,
              peakValleyType: item,
              time: PeaksAndValleysTypePowerplay[item as PeaksAndValleysType],
              startTime:
                data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(i => i.peakValleyType === item)
                  ?.startTime ?? null,
              endTime:
                formatEndtime(
                  data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(i => i.peakValleyType === item)
                    ?.endTime
                ) ?? null,
              power:
                data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(i => i.peakValleyType === item)
                  ?.power ?? null,
            };
          }),
        });
      }
    } else {
      if (isNil(timePeriodGranularity)) return;
      if (chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
        if (timePeriodGranularity !== PricedisplayType.CUSTOM) {
          const formatPrice = getTimeString({ price: price ?? [], timePeriodGranularity });
          form.setFieldsValue({
            chargingAndDischargingPeriodDetail: (formatPrice ?? []).map(item => {
              return {
                key: uuidv4(),
                type: item.type,
                startTime: item.startTime,
                endTime: formatEndtime(item.endTime),
              };
            }),
          });
        }
      } else {
        if (timePeriodGranularity !== PricedisplayType.CUSTOM) {
          const formatPrice = getTimeString({ price: price ?? [], timePeriodGranularity });
          const chargingAndDischargingPeriodDetailFormTemp = form.getFieldValue('chargingAndDischargingPeriodDetail');

          form.setFieldsValue({
            chargingAndDischargingPeriodDetail: (formatPrice ?? []).map(item => {
              const findPower = data?.additionalFields?.chargingAndDischargingPeriodDetail?.find(
                i => i.startTime?.slice(0, 5)! + i.endTime?.slice(0, 5) === item.startTime + item.endTime
              );
              const findOriginPower = chargingAndDischargingPeriodDetailFormTemp?.find(
                (i: any) => i.startTime?.slice(0, 5)! + i.endTime?.slice(0, 5) === item.startTime + item.endTime
              );
              return {
                key: uuidv4(),
                type: item.type,
                startTime: item.startTime,
                endTime: formatEndtime(item.endTime),
                power: item.type === ActionType.StandBy ? 0 : findOriginPower?.power ?? findPower?.power,
              };
            }),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    periodTypes,
    chargingAndDischargingPower,
    plainAndValleyTypeList,
    peakAndSharpTypeList,
    price,
    timePeriodGranularity,
  ]);

  useEffect(() => {
    if (data && form) {
      form.setFieldsValue({
        templateName: data.templateName,
        templateType: data.templateType,
        periodTypes: data.additionalFields?.periodTypes,
        epTemplateId: data.additionalFields?.epTemplateId,
        epTemplateName: data.additionalFields?.epTemplateName,
        timePeriodGranularity: data.additionalFields?.timePeriodGranularity,
        chargingAndDischargingPower: data.additionalFields?.chargingAndDischargingPower,
        peakAndSharpTypeList: data.additionalFields?.peakAndSharpTypeList,
        plainAndValleyTypeList: data.additionalFields?.plainAndValleyTypeList,
        chargingAndDischargingPeriodDetail: data.additionalFields?.chargingAndDischargingPeriodDetail?.map(item => {
          return {
            key: uuidv4(),
            ...item,
            type: String(item.type),
            startTime:
              data.additionalFields?.timePeriodGranularity === PricedisplayType.CUSTOM
                ? dayjs(item.startTime, 'HH:mm:ss')
                : item.startTime,
            endTime:
              data.additionalFields?.timePeriodGranularity === PricedisplayType.CUSTOM
                ? dayjs(item.endTime, 'HH:mm:ss')
                : formatEndtime(item.endTime),
          };
        }),
        price:
          data.additionalFields?.timePeriodGranularity === PricedisplayType.CUSTOM
            ? undefined
            : getPriceTimeIndex(data),
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (displayType !== undefined) {
      form.setFieldsValue({
        price: [],
      });
    }
  }, [displayType, form]);

  const onFinish = (values: any) => {
    if (values.timePeriodGranularity === PricedisplayType.CUSTOM) {
      const timeList = form.getFieldValue('chargingAndDischargingPeriodDetail');
      if (checekTime24Hour(timeList)) {
        Modal.warning({
          title: '自定义时间段没有充满24小时',
        });
        return;
      }
    }

    const newChargingAndDischargingPeriodDetail =
      values.timePeriodGranularity === PricedisplayType.CUSTOM
        ? (values.chargingAndDischargingPeriodDetail ?? []).map((i: any) => {
            return {
              type: i.type,
              startTime: dayjs(i.startTime).format('HH:mm'),
              endTime: dayjs(i.endTime).format('HH:mm'),
              power: i.power,
            };
          })
        : (values.chargingAndDischargingPeriodDetail ?? []).map((i: any) => ({
            ...i,
            endTime: formatSubmitTime(i?.endTime),
          }));

    if (id) {
      const editValue = {
        id: Number(id),
        templateName: values.templateName,
        templateType: values.templateType,
        neoBsaId: bsaId ? Number(bsaId) : undefined,
        additionalFields: {
          periodTypes: values.periodTypes,
          epTemplateId: values.epTemplateId,
          peakAndSharpTypeList: values?.peakAndSharpTypeList,
          plainAndValleyTypeList: values?.plainAndValleyTypeList,
          timePeriodGranularity: !isNil(values.timePeriodGranularity)
            ? values.timePeriodGranularity
            : values.epTemplateId
            ? elecTempList?.find(i => i.epTemplateId === values.epTemplateId)?.timePeriodGranularity
            : undefined,
          chargingAndDischargingPower: values.chargingAndDischargingPower,
          chargingAndDischargingPeriodDetail: newChargingAndDischargingPeriodDetail,
        },
      };
      return editValue;
    } else {
      const createValue = {
        templateName: values.templateName,
        templateType: values.templateType,
        neoBsaId: bsaId ? Number(bsaId) : undefined,
        additionalFields: {
          periodTypes: values.periodTypes,
          epTemplateId: values.epTemplateId,
          peakAndSharpTypeList: values?.peakAndSharpTypeList,
          plainAndValleyTypeList: values?.plainAndValleyTypeList,
          timePeriodGranularity: !isNil(values.timePeriodGranularity)
            ? values.timePeriodGranularity
            : values.epTemplateId
            ? elecTempList?.find(i => i.epTemplateId === values.epTemplateId)?.timePeriodGranularity
            : undefined,
          chargingAndDischargingPower: values.chargingAndDischargingPower,
          chargingAndDischargingPeriodDetail: newChargingAndDischargingPeriodDetail,
        },
      };
      return createValue;
    }
  };

  const { data: elecTempList } = useRequest(
    async () => {
      const res = await apiV2BsaStrategyTemplateEpDetailPost({ id: Number(bsaId) });
      return res.list;
    },
    {
      ready: !!bsaId,
      refreshDeps: [bsaId, updateState],
    }
  );

  // 默认选中第一个bsa
  useEffect(() => {
    if (elecTempList && elecTempList.length !== 0 && !id) {
      form.setFieldValue('epTemplateId', elecTempList[0].epTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elecTempList, id]);

  const elecTempOptions = useMemo(() => {
    if (elecTempList && elecTempList.length !== 0) {
      return elecTempList.map(i => ({ label: i.epTemplateName, value: i.epTemplateId }));
    }
  }, [elecTempList]);

  const operateColumn: ColumnsType<PowerListItem> = [
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 100,
      render(value, _, index) {
        return (
          <Button
            type="link"
            onClick={() => {
              const list = form.getFieldValue('chargingAndDischargingPeriodDetail');
              const newList = list.filter((_: any, listIndex: number) => index !== listIndex);
              form.setFieldValue('chargingAndDischargingPeriodDetail', newList);
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  const columns = useMemo(() => {
    if (isNil(periodTypes)) return [];
    if (periodTypes === PVPeriods.FollowEpriceTemplate) {
      if (chargingAndDischargingPower === ChargingAndDischargingPower.Custom) {
        // 跟随电价模板 +  充放电功率自定义
        return columnsWithFollowPrice(bsaConfigData);
      }
    } else {
      if (isNil(timePeriodGranularity)) return [];
      if (timePeriodGranularity === PricedisplayType.CUSTOM) {
        if (chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
          // 自定义设置 + 时间段颗粒度自定义 + 充放电功率跟随最大功率
          return [...columnsWithCustomTimeFollowMax, ...operateColumn];
        } else {
          // 自定义设置 + 时间段颗粒度自定义 + 充放电功率自定义
          return [...columnsWithCustomTimeFllowPrice(bsaConfigData), ...operateColumn];
        }
      } else {
        if (chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower) {
          // 自定义设置 + 时间段颗粒度整点/半点 + 充放电功率跟随最大功率
          return columnsWithCustomFollowMax;
        } else {
          // 自定义设置 + 时间段颗粒度整点/半点 + 充放电功率自定义
          return columnsWithCustomCustom(bsaConfigData);
        }
      }
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodTypes, chargingAndDischargingPower, bsaConfigData, timePeriodGranularity]);

  const addRow = () => {
    const tableData = form.getFieldValue('chargingAndDischargingPeriodDetail');
    const newRow = {
      key: uuidv4(),
      type: undefined,
      startTime: undefined,
      endTime: undefined,
      power: undefined,
    };
    form.setFieldsValue({ chargingAndDischargingPeriodDetail: [...(tableData ?? []), newRow] });
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        {showTemplateName && (
          <Form.Item
            name="templateName"
            label="策略模板名称"
            style={{ width: '50%' }}
            rules={[
              { required: true, message: '请输入策略模板名称' },
              { max: 50, message: '最多输入五十个字' },
            ]}
          >
            <Input placeholder="请输入策略模板名称" />
          </Form.Item>
        )}
        <Form.Item
          name="templateType"
          label="策略模板类型"
          initialValue={StrategyTemplateType.PSVF}
          rules={[{ required: true, message: '请选择策略模板类型' }]}
        >
          <Radio.Group>
            <Radio value={StrategyTemplateType.PSVF}>{StrategyTemplateTypeDisplay[StrategyTemplateType.PSVF]}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="periodTypes"
          label="峰谷时段"
          initialValue={PVPeriods.FollowEpriceTemplate}
          rules={[{ required: true, message: '请选择峰谷时段' }]}
        >
          <Radio.Group
            buttonStyle="solid"
            onChange={e => {
              if (e.target.value === PVPeriods.CustomSettings) {
                form.setFieldValue('chargingAndDischargingPeriodDetail', undefined);
              }
            }}
          >
            <Radio.Button value={PVPeriods.FollowEpriceTemplate}>
              {PVPeriodsDisplay[PVPeriods.FollowEpriceTemplate]}
            </Radio.Button>
            <Radio.Button value={PVPeriods.CustomSettings}>{PVPeriodsDisplay[PVPeriods.CustomSettings]}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {periodTypes === PVPeriods.CustomSettings ? (
          <Form.Item
            name="timePeriodGranularity"
            label="时段颗粒度"
            rules={[{ required: true, message: '请选择时段颗粒度' }]}
          >
            <Radio.Group
              onChange={e => {
                setDisplayType(e.target.value);
                form.setFieldValue('chargingAndDischargingPeriodDetail', undefined);
              }}
            >
              <Radio value={PricedisplayType.HALF}>{PricedisplayTypeDisplay[PricedisplayType.HALF]}</Radio>
              <Radio value={PricedisplayType.HOUR}>{PricedisplayTypeDisplay[PricedisplayType.HOUR]}</Radio>
              <Radio value={PricedisplayType.CUSTOM}>{PricedisplayTypeDisplay[PricedisplayType.CUSTOM]}</Radio>
            </Radio.Group>
          </Form.Item>
        ) : (
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="epTemplateId"
              label="电价模板"
              style={{ width: '50%' }}
              rules={[{ required: true, message: '请选择电价模板' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="请选择"
                options={elecTempOptions}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
            {epTemplateId && (
              <Space size={16} style={{ marginBottom: 24 }}>
                <Button
                  icon={<FileSearchOutlined />}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() =>
                    window.open(
                      `${window.IOTPLATFORMORIGIN}/operation/energy/contract/electric-rate/detail/${epTemplateId}`,
                      '_blank'
                    )
                  }
                >
                  查看
                </Button>

                <Button
                  icon={<SyncOutlined />}
                  onClick={() => updateFn()}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                >
                  刷新
                </Button>
                <Button
                  icon={<RightCircleOutlined />}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() =>
                    window.open(
                      `/energy/scheduling/topology?dispatchingGroup=${
                        elecTempList?.find(i => i.epTemplateId === epTemplateId)?.dispatchGroupId
                      }&topologyId=${elecTempList?.find(i => i.epTemplateId === epTemplateId)?.dispatchUetStructId}`,
                      '_blank'
                    )
                  }
                >
                  前往关联电价模板
                </Button>
              </Space>
            )}
          </div>
        )}

        <Form.Item
          name="chargingAndDischargingPower"
          label={
            <>
              充放电功率
              <Tooltip title="跟随电价模板进行充放电，取控制配置中的最大充电功率和最大放电功率。">
                <InfoCircleOutlined style={{ marginLeft: 4, marginRight: 4, color: '#FAAD14' }} />
              </Tooltip>
            </>
          }
          initialValue={ChargingAndDischargingPower.FollowMaxPower}
          rules={[{ required: true, message: '请选择充放电功率' }]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={ChargingAndDischargingPower.FollowMaxPower}>
              {ChargingAndDischargingPowerplay[ChargingAndDischargingPower.FollowMaxPower]}
            </Radio.Button>
            <Radio.Button value={ChargingAndDischargingPower.Custom}>
              {ChargingAndDischargingPowerplay[ChargingAndDischargingPower.Custom]}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {periodTypes === PVPeriods.CustomSettings ? (
          timePeriodGranularity !== PricedisplayType.CUSTOM ? (
            <ChargeDisChangeTime />
          ) : (
            <></>
          )
        ) : (
          <>
            <Form.Item
              name="plainAndValleyTypeList"
              label="充电时段"
              initialValue={[PeaksAndValleysType.Valley]}
              rules={[{ required: true, message: '请选择充电时段' }]}
            >
              <Checkbox.Group>
                <Checkbox value={PeaksAndValleysType.Valley}>
                  {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Valley]}
                </Checkbox>
                <Checkbox value={PeaksAndValleysType.Flat}>
                  {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Flat]}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item
              name="peakAndSharpTypeList"
              label="放电时段"
              initialValue={[PeaksAndValleysType.Peak]}
              rules={[{ required: true, message: '请选择放电时段' }]}
            >
              <Checkbox.Group>
                <Checkbox value={PeaksAndValleysType.Peak}>
                  {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Peak]}
                </Checkbox>
                <Checkbox value={PeaksAndValleysType.Sharp}>
                  {PeaksAndValleysTypePowerplay[PeaksAndValleysType.Sharp]}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </>
        )}

        {!(
          chargingAndDischargingPower === ChargingAndDischargingPower.FollowMaxPower &&
          periodTypes === PVPeriods.FollowEpriceTemplate
        ) && (
          <Col span={24}>
            <Form.Item>
              <Form.Item
                name="chargingAndDischargingPeriodDetail"
                style={{ marginBottom: 0 }}
                valuePropName="dataSource"
              >
                <Table rowKey="key" columns={columns} pagination={false} scroll={{ y: 340 }} />
              </Form.Item>
              {timePeriodGranularity === PricedisplayType.CUSTOM && (
                <Button
                  wrapClassName={styles['add-row-btn']}
                  className={styles['row-btn-inner']}
                  type="dashed"
                  onClick={addRow}
                  ghost
                  icon={<PlusOutlined />}
                >
                  新建
                </Button>
              )}
            </Form.Item>
          </Col>
        )}
      </Form>
    </div>
  );
};

export default forwardRef(StrategyTemplateEdit);
