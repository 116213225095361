import { Node } from '@antv/x6';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import Meter from '../../imgs/electricityMeter1.gif';
import { BillingType } from '../../utils/demoData';
import { isNil } from 'lodash';

export interface MetersProps {
  node: Node;
}

const Meters: FC<MetersProps> = props => {
  const { node } = props;

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  return (
    <div className={styles.customBillingPointNode}>
      <div className={styles.vline} style={{ height: data?.lineLength === 'small' ? 160 : 0 }}></div>
      <div className={styles.hline} style={{ width: data?.lineLength === 'small' ? 24 : 85 }}></div>
      <div
        className={styles.cardMeterSty}
        style={{
          left: data?.lineLength === 'small' ? 24 : 85,
          height: data?.type === BillingType.OfficeLoad ? 128 : 160,
        }}
      >
        <div className={styles.topContentSty}>
          <img style={{ width: 24, height: 24, marginLeft: 12 }} src={Meter} alt="" />
          <div style={{ marginLeft: 12 }} className={styles.itemControlobjContentSty}>
            计费点-1M
          </div>
        </div>
        <div className={styles.dividerSty} />
        <div className={styles.bottomContentSty}>
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>电压</div>
            <div className={styles.itemPropValue}>{data?.voltage.toFixed(2)}V</div>
          </div>
          {data?.type !== BillingType.OfficeLoad && (
            <div className={styles.itemProp}>
              <div className={styles.itemPropName}>电流</div>
              <div className={styles.itemPropValue}>
                {!isNil(data?.current)
                  ? Math.abs(data?.current).toFixed(2)
                  : (Math.abs(data?.power / data?.voltage) * 1000).toFixed(2)}
                A
              </div>
            </div>
          )}
          <div className={styles.itemProp}>
            <div className={styles.itemPropName}>功率</div>
            <div className={styles.itemPropValue}>
              {!isNil(data?.power) ? data?.power.toFixed(2) : ((data?.voltage * data?.current) / 1000).toFixed(2)}kW
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meters;
