import { Graph, Node } from '@antv/x6';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { TimeDimensionEnm, TimeDimensionEnmDisplay, TimeDimensionEnmDisplayParams, TreeNode } from '../../type';
import { treeItemProps } from '../..';
import styles from './index.module.scss';
import { V2GreenMicrowebShowcaseDataPropertyDataPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { isNil } from 'lodash-es';
import { handleCommonExpand, judgeDrageOrClick } from '../../utils';
import { iconArr } from '@/pages/SchedulingTopology/utils/utils';
import { Tooltip } from 'antd';
import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { keepTwoNull } from '@/pages/MicrogridConfOverview/utils';
import classNames from 'classnames';
import { getRealUrl } from '@maxtropy/components';

export interface OtherTransverseProps {
  node: Node;
  graphRef?: Graph;
  allNodeDataRef?: TreeNode[];
  configId?: number;
}

export type dataPropertyConfigItemProps = Exclude<treeItemProps['dataPropertyConfigs'], undefined>[number];
export type dataPropertyItemProps = Exclude<dataPropertyConfigItemProps['list'], undefined>[number];

const OtherTransverse: FC<OtherTransverseProps> = props => {
  const { node, graphRef, allNodeDataRef, configId } = props;
  const refBox = useRef<HTMLDivElement>();

  const data = useMemo(() => {
    return node.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.getData()]);

  // 获取数据点数据信息
  const dataPropertyDataList: V2GreenMicrowebShowcaseDataPropertyDataPostResponse['list'] = useMemo(() => {
    return data?.value?.deviceInfos;
  }, [data]);

  // 获取数据点信息
  const dataPropertyConfigsList: treeItemProps['dataPropertyConfigs'] = useMemo(() => {
    return data?.value?.dataPropertyConfigs ?? [];
  }, [data]);

  // 平铺当前元素的数据点(如果没有数据点数据返回，则用树形结构中的树)
  const allDataPropertyConfigsList = useMemo(() => {
    if (!isNil(dataPropertyDataList)) {
      const dataConfigsList = (dataPropertyConfigsList ?? [])
        .map(item => {
          return item.list?.map(c => ({
            ...c,
            deviceId: item.deviceId,
          }));
        })
        .flat();
      const dataPropertysList = (dataPropertyDataList ?? [])
        .map(item => {
          return item.list;
        })
        .flat();
      return (dataConfigsList ?? []).map((el: any) => ({
        ...el,
        value: dataPropertysList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.value,
        old: dataPropertysList?.find((item: any) => item.dataPropertyId == el.dataPropertyId)?.old,
      }));
    } else {
      return (dataPropertyConfigsList ?? [])
        .map(item => {
          return item.list?.map(c => ({
            ...c,
            deviceId: item.deviceId,
          }));
        })
        .flat();
    }
  }, [dataPropertyConfigsList, dataPropertyDataList]);

  // 数据过于陈旧展示
  const oldDataList = useMemo(() => {
    return allDataPropertyConfigsList
      .filter(item => item.old)
      .map(i => i.dataPropertyName)
      .join();
  }, [allDataPropertyConfigsList]);

  // 区分拖动与点击事件
  useEffect(() => {
    if (refBox.current) {
      judgeDrageOrClick(refBox.current, configId!, data?.value?.id, data?.element);
    }
  }, []);

  // 展开收起
  const onExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    node.updateData({
      isExpand: !data.isExpand,
    });
    const height = !data.isExpand ? 80 + 32 * (allDataPropertyConfigsList ?? []).length : 80;
    handleCommonExpand(
      data?.value?.id,
      data?.element,
      node,
      height,
      !data.isExpand,
      allDataPropertyConfigsList.length,
      graphRef,
      allNodeDataRef
    );
  };

  return (
    <div className={styles.customMetersReactNode}>
      <div className={styles.vline}></div>
      <div className={styles.hline}></div>
      <div
        className={styles.cardMeterSty_wrapper}
        style={{
          borderLeft: `3px solid #8CA9DC`,
        }}
      >
        <div className={styles.cardMeterSty}>
          <div
            className={styles.content}
            ref={d => {
              if (d) {
                refBox.current = d;
              }
            }}
          >
            <img
              className={styles.img_sty}
              src={
                data?.value?.file
                  ? getRealUrl(data?.value?.file)
                  : iconArr.find(i => i.value === data?.value?.icon)?.img
              }
              alt=""
            />

            <Tooltip title={data.value.name}>
              <div style={{ marginLeft: 8 }} className={styles.itemControlobjContentSty}>
                {data.value.name}
              </div>
            </Tooltip>
            {oldDataList && oldDataList.length > 0 && (
              <Tooltip title={`数据过于陈旧：${oldDataList}`}>
                <InfoCircleOutlined className={styles.infoIcon} />
              </Tooltip>
            )}
          </div>
          <div className={styles.expand_content}>
            {data.isExpand && allDataPropertyConfigsList.length > 0 ? (
              <>
                <div className={styles.dividerSty} />
                <div className={styles.device_prop_wrapper}>
                  {(allDataPropertyConfigsList ?? []).map((item: any) => (
                    <div
                      className={styles.item_sty}
                      key={item?.dataPropertyId}
                      onClick={() => {
                        window.open(
                          `${window.IOTPLATFORMORIGIN}/data/history/device?deviceId=${item?.deviceId}&dataPropertyId=${
                            item?.dataPropertyId
                          }&dateMode=${
                            !isNil(item?.timeDimensionEnum) &&
                            TimeDimensionEnmDisplayParams[item.timeDimensionEnum as TimeDimensionEnm]
                          }`,
                          '_blank'
                        );
                      }}
                    >
                      {item?.timeDimensionEnum &&
                        (item.timeDimensionEnum as TimeDimensionEnm) !== TimeDimensionEnm.REAL_TIME_VALUE && (
                          <Tooltip
                            title={
                              !isNil(item.timeDimensionEnum)
                                ? TimeDimensionEnmDisplay[item.timeDimensionEnum as TimeDimensionEnm] + '数据'
                                : '--'
                            }
                          >
                            <div className={styles.corner}></div>
                          </Tooltip>
                        )}
                      <div className={styles.label_sty}>
                        <Tooltip title={item?.dataPropertyName ?? '--'}>{item?.dataPropertyName ?? '--'}</Tooltip>
                      </div>
                      <div
                        className={
                          item?.timeDimensionEnum !== TimeDimensionEnm.REAL_TIME_VALUE
                            ? styles.value_sty
                            : styles.real_value_sty
                        }
                      >
                        <Tooltip title={keepTwoNull(item?.value) + (item?.generalName ?? '')}>
                          {keepTwoNull(item?.value) + (item?.generalName ?? '')}
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {allDataPropertyConfigsList.length > 0 && (
          <div className={styles.expand_box} onClick={e => onExpand(e)}>
            {data.isExpand ? (
              <UpOutlined className={classNames(styles.nodeExpandIcon)} />
            ) : (
              <DownOutlined className={styles.nodeExpandIcon} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherTransverse;
