import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Radio } from '@maxtropy/components';
import { Space } from 'antd';
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';
import {
  apiV2MicrogridConfigurationEquipmentConfInfoPost,
  apiV2MicrogridConfigurationEquipmentConfPost,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { RefProps } from '../const';
import FooterCancelBtn from './FooterCancelBtn';

const TargetDevice: ForwardRefRenderFunction<RefProps> = (_, ref) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [saveLoading, setSaveLoading] = useState(false);
  const [hasSave, setHasSave] = useState(true);
  useImperativeHandle(ref, () => ({
    saveBtn: saveBtn,
    isEditStatus: !hasSave,
  }));
  const { data: detail } = useRequest(
    () => {
      return apiV2MicrogridConfigurationEquipmentConfInfoPost({
        id,
      });
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );
  useEffect(() => {
    form.setFieldsValue(detail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);
  const saveBtn = async (success?: () => void) => {
    let res = await form
      .validateFields()
      .then(res => res)
      .catch(_ => null);
    if (!res) return;
    setSaveLoading(true);
    apiV2MicrogridConfigurationEquipmentConfPost({
      ...detail,
      ...res,
    })
      .then(() => {
        success?.();
        message.success('保存成功');
      })
      .finally(() => {
        setHasSave(true);
        setSaveLoading(false);
      });
  };

  return (
    <>
      <Form
        preserve
        form={form}
        initialValues={{
          display: 1,
        }}
      >
        <Form.Item label="是否展示卡片" name="display" required>
          <Radio.Group onChange={() => hasSave && setHasSave(false)}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="卡片权重"
          name="weight"
          required
          extra={
            <>
              <Space style={{ paddingTop: 8 }} size={5}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                <span style={{ color: 'color: rgba(255,255,255,0.45);' }}>权重越大，展示越靠前</span>
              </Space>
            </>
          }
        >
          <Input placeholder="请输入" onChange={() => hasSave && setHasSave(false)} style={{ width: 460 }} />
        </Form.Item>
      </Form>
      <Space size={8} className={styles.tabs_content_sticky_footer}>
        <Button type="primary" loading={saveLoading} onClick={() => saveBtn()}>
          保存
        </Button>

        <FooterCancelBtn />
      </Space>
    </>
  );
};

export default forwardRef(TargetDevice);
