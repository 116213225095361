import { Node } from '@antv/x6';
import React, { FC } from 'react';
import styles from './index.module.scss';
import DC from '../../imgs/DC.gif';

export interface DcConverterProps {
  node: Node;
}

const DcConverter: FC<DcConverterProps> = props => {
  return (
    <div className={styles.customDcConverterNode}>
      <div className={styles.cardSty}>
        <img style={{ width: 40, height: 40 }} src={DC} alt="" />
        <div className={styles.itemControlobjContentSty}>直流转换器</div>
      </div>
      <div className={styles.vlineBottom}></div>
    </div>
  );
};

export default DcConverter;
